import { Dialog, DialogTitle, LinearProgress } from '@mui/material';

import { ModalDialogProps } from './props';
import { useModalStyles } from './styles';

export const ProgressModal = (props: ModalDialogProps) => {
  const classes = useModalStyles(props);
  const { modalInfo } = props;
  const loadingText = modalInfo.message;

  return (
    <div className={classes.progress}>
      <Dialog open={true}>
        <DialogTitle>{loadingText}</DialogTitle>
        <LinearProgress color="primary" />
      </Dialog>
    </div>
  );
};
