import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { ModalDialogProps } from './props';
import { useModalStyles } from './styles';

export const ApplicationErrorModal = (props: ModalDialogProps) => {
  const classes = useModalStyles(props);
  const { modalInfo, onModalClose } = props;
  const error_title: string | null = modalInfo.title;
  const error_text = modalInfo.message;

  return (
    <div>
      <Dialog
        // open={show_dialogue}
        open={true}
        className={classes.error}
        onClose={(e) => onModalClose && onModalClose(modalInfo.id)}
      >
        {/* Title */}
        <DialogTitle>{error_title === null ? 'Error' : error_title}</DialogTitle>

        {/* Text */}
        <DialogContent>
          <DialogContentText>{error_text}</DialogContentText>
        </DialogContent>

        {/* Action Buttons */}
        <DialogActions>
          <Button onClick={(e) => onModalClose && onModalClose(modalInfo.id)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
