import { ReactNode } from 'react';

import { DialogTitle, Stack } from '@mui/material';

import { Spacer, Text } from '../../styles';
import { useModalStyles } from './styles';

interface ModalTitleProps {
  modalTitle: ReactNode;
  modalSubtitle?: ReactNode;
  modalDetails?: ReactNode;
  adornment?: ReactNode;

  /** Separates title and subtitle via an em dash, "—" */
  useTitleSeparator?: boolean;
}

export const ModalTitle = ({
  modalTitle,
  modalSubtitle,
  modalDetails,
  adornment,
  useTitleSeparator,
  ...props
}: ModalTitleProps) => {
  const classes = useModalStyles(props);
  return (
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <span>
          {modalTitle}
          {modalSubtitle && ':'}
          <Spacer />
          {useTitleSeparator && TitleSeparator}
          {modalSubtitle && <span className={classes.subtitle}>{modalSubtitle}</span>}
        </span>
        {adornment}
      </Stack>
      {modalDetails && <Text variant="subtitle1">{modalDetails}</Text>}
    </DialogTitle>
  );
};

const TitleSeparator = (
  <>
    &mdash;
    <Spacer />
  </>
);
