import React, { ReactNode, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { ProcessesMap } from 'utils/types';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface AutocompleteLocationProps extends UseStyles<typeof useStyles> {
  id: string;
  value: string | null;
  processName: string | null;

  label?: string;
  disabled?: boolean;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: string | null) => void;
  helperText?: ReactNode;
  error?: boolean;
  className?: string;
}

const AutocompleteLocation = observer(
  ({ processName, ...props }: AutocompleteLocationProps): React.ReactElement | null => {
    const { className, id, value, label = 'Location', disabled = false, onChange, error, helperText } = props;
    const [open, setOpen] = useState(false);
    const [processesMap, setProcessesMap] = useState<ProcessesMap>({});
    const [locations, setLocations] = useState<string[]>([]);

    const classes = useStyles(props);
    const { processingService } = useServices();

    useEffect(() => {
      processingService.fetchExistingProcesses().then((existingProcesses) => {
        setProcessesMap(existingProcesses ?? {});
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const processInfo = processName ? processesMap[processName] : null;
      const locations =
        processInfo && processInfo.locations && processInfo.locations.map((value) => value.toUpperCase());
      setLocations(locations ?? []);
    }, [open, processName, processesMap, processingService]);

    return (
      <div className={clsx(classes.root, className)}>
        <Autocomplete
          id={id}
          value={value}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={locations}
          disabled={disabled}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={error}
              helperText={helperText}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </div>
    );
  }
);

export default AutocompleteLocation;
