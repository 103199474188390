import React from 'react';

import { observer } from 'mobx-react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { SettingsVisualToggleKey } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  sectionHeader: {
    padding: theme.spacing(1),
  },

  settingDisabled: {
    color: theme.palette.text.disabled,
  },
}));

export interface PreferenceMenuProps extends UseStyles<typeof useStyles> {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;

  className?: string;
}

const PreferenceMenu = observer((props: PreferenceMenuProps): React.ReactElement | null => {
  const { className, anchorEl, open, onClose } = props;
  const classes = useStyles(props);
  const { actionBarService } = useServices();

  return (
    <div className={clsx(classes.root, className)}>
      <Menu
        id="preference-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        MenuListProps={{ dense: true }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.entries(actionBarService.visualToggles).map(([key, setting]) => (
          <MenuItem
            key={`${key}-VisualToggle`}
            onClick={() => actionBarService.toggleVisualDisplay(key as SettingsVisualToggleKey)}
          >
            <ListItemIcon>
              {setting.active ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon className={classes.settingDisabled} />}
            </ListItemIcon>
            <Typography className={setting.active ? undefined : classes.settingDisabled}>{setting.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
});

export default PreferenceMenu;
