import React from 'react';

import { observer } from 'mobx-react';

import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface ResetButtonProps extends ButtonProps {
  className?: string;
}

const ResetButton = observer(({ className, ...props }: ResetButtonProps): React.ReactElement | null => {
  const classes = useStyles(props);
  const { actionBarService } = useServices();
  const { validationLoading } = actionBarService;

  return (
    <Button className={clsx(classes.root, className)} disabled={validationLoading} {...props}>
      reset
    </Button>
  );
});

export default ResetButton;
