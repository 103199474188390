import React from 'react';

import { observer } from 'mobx-react';

import PersonIcon from '@mui/icons-material/Person';
import { Button, Menu, MenuItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithTheme as WithThemeProps } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    menuPaper: {
      maxWidth: 250,
    },
  });

export interface AccountMenuProps extends WithServicesProps, WithThemeProps {
  classes: {
    root: string;
    menuPaper: string;
  };
}

export interface AccountMenuState {
  anchorEl: null | HTMLElement;
}

class AccountMenu extends React.Component<AccountMenuProps, AccountMenuState> {
  constructor(props: Readonly<AccountMenuProps>) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.props.services.authService.getLoggedInUser();
  }

  openMenu(event: React.MouseEvent<HTMLElement>) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  closeMenu() {
    this.setState({ anchorEl: null });
  }

  logout() {
    const { services } = this.props;
    const { authService } = services;

    this.closeMenu();
    authService.logout(); // Call the airflow logout
  }

  renderLogin(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Button startIcon={<PersonIcon />} href="/login/?next=/dashboard" sx={{ textTransform: 'none' }}>
          Login
        </Button>
      </div>
    );
  }

  renderUser(): React.ReactNode {
    const { classes, services } = this.props;
    const { authService } = services;
    const isOpen = Boolean(this.state.anchorEl);

    return (
      <div className={classes.root}>
        <Button startIcon={<PersonIcon />} onClick={(e) => this.openMenu(e)} sx={{ textTransform: 'none' }}>
          {authService.accountDetails?.name}
        </Button>
        <Menu
          open={isOpen}
          onClose={() => this.closeMenu()}
          anchorEl={this.state.anchorEl}
          transitionDuration={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{
            paper: classes.menuPaper,
          }}
          PaperProps={{
            style: { minWidth: this.state.anchorEl?.clientWidth },
          }}
        >
          <MenuItem onClick={() => this.logout()}>Log Out</MenuItem>
        </Menu>
      </div>
    );
  }

  render(): React.ReactNode {
    const { services } = this.props;
    const { authService } = services;

    if (authService.accountDetails) {
      return this.renderUser();
    }
    return this.renderLogin();
  }
}

export default withStyles(styles, { withTheme: true })(withServices(observer(AccountMenu)));
