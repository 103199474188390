import { ComponentPropsWithoutRef } from 'react';

import { observer } from 'mobx-react';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Stack, Theme, Toolbar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import ActionMenu from 'components/menus/ActionMenu';
import { useContextActionMenu } from 'hooks/useContextActionMenu';
import { MINIMUM_LABEL_WIDTH, SORT_LABEL_WIDTH } from 'utils/constants';

import { PipelineCountAndSortButton } from './PipelineCountAndSortButton';
import PreferenceButton from './PreferenceButton';
import ShareButton from './ShareButton';
import ZoomControls from './ZoomControls';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'hsl(0, 0%, 48%)',
  },
  paper: {},

  actionButton: {},
  dateField: {
    width: 715,
  },
  refreshButton: {
    borderRadius: '0 10px 10px 0',
    borderColor: '#A9A8A8',
  },
  sortMenu: {
    maxHeight: 36,
    width: MINIMUM_LABEL_WIDTH - 16,
    borderRadius: '10px',
  },
  zoomButton: {
    borderRadius: 3,
    margin: 5,
  },

  timelineNavButton: {},
}));

export interface ActionBarProps extends ComponentPropsWithoutRef<'div'> {
  /* Additional props go here */
}

export const ActionBar = observer(({ className, ...props }: ActionBarProps) => {
  const classes = useStyles(props);
  const { actionMenuOpen, anchorEl: actionMenuElement, openContextMenu, closeContextMenu } = useContextActionMenu();

  return (
    <div className={clsx(classes.root, className)} {...props}>
      {actionMenuOpen && (
        <ActionMenu
          anchorEl={actionMenuElement}
          onClose={closeContextMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      )}

      <Toolbar
        style={{
          justifyContent: 'space-between',
        }}
      >
        <PipelineCountAndSortButton />

        {/* Adjust/reset zoom */}
        <div style={{ width: '30%' }}>
          <ZoomControls />
        </div>

        <Stack
          className={classes.timelineNavButton}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width={SORT_LABEL_WIDTH}
        >
          {/* <MonthNavButtons /> */}
          <span />
          <Stack direction="row" justifyContent="right" alignItems="center">
            {/* Action button (changes based on processing mode) */}
            <Tooltip title="Dashboard Actions" arrow placement="top-start">
              <IconButton onClick={openContextMenu}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <PreferenceButton />
            <ShareButton />
          </Stack>
        </Stack>
      </Toolbar>
    </div>
  );
});
