import { action, computed, makeObservable, observable } from 'mobx';

import { ProcessType } from 'utils/constants';
import { EncodeBase64 } from 'utils/utils';

import { ArmflowForm } from './types';

export type ReleaseDataFormJSON = {
  process_name: string | null;
  process_type: ProcessType | null;
  location_name: string | null;
  processing_id: string | null;
  comment: string | null;
};

export type ReleaseDataFormFields = {
  processName: string | null;
  processType: ProcessType | null;
  locationName: string | null;
  processingId: string | null;
  comment: string | null;
};

export const RELEASE_DATA_EMPTY_FORM: ReleaseDataFormFields = {
  processName: null,
  processType: null,
  locationName: null,
  processingId: null,
  comment: '',
};

export default class ReleaseDataForm implements ArmflowForm<ReleaseDataFormFields, ReleaseDataFormJSON> {
  @observable processName: ReleaseDataFormFields['processName'];
  @observable processType: ReleaseDataFormFields['processType'];
  @observable locationName: ReleaseDataFormFields['locationName'];
  @observable processingId: ReleaseDataFormFields['processingId'];
  @observable comment: ReleaseDataFormFields['comment'];

  constructor(autofillData?: ReleaseDataFormFields) {
    this.processName = autofillData ? autofillData.processName : '';
    this.processType = autofillData ? autofillData.processType : null;
    this.locationName = autofillData ? autofillData.locationName : '';
    this.processingId = autofillData ? autofillData.processingId : '';
    this.comment = autofillData ? autofillData.comment : '';

    makeObservable(this);
  }

  @computed get query(): string {
    return EncodeBase64(JSON.stringify(this));
  }

  @computed get toHistoryString(): string {
    const { processName, locationName, processingId } = this;
    return [processName, locationName, processingId].join(' ');
  }

  @action updateProcessName(name: string | null) {
    this.processName = name;
  }

  @action updateProcessType(processType: ProcessType | null) {
    this.processType = processType;
  }

  @action updateLocation(location: string | null) {
    this.locationName = location;
  }

  @action updateProcessingId(processingId: string | null) {
    this.processingId = processingId;
  }

  @action updateComment(comment: string) {
    this.comment = comment;
  }

  @action updateForm(formValues: Partial<ReleaseDataFormFields>) {
    const { processName, processType, locationName, processingId, comment } = formValues;

    if (processName !== undefined) this.processName = processName;
    if (processType !== undefined) this.processType = processType;
    if (locationName !== undefined) this.locationName = locationName;
    if (processingId !== undefined) this.processingId = processingId;
    if (comment !== undefined) this.comment = comment;
  }

  @action reset() {
    this.processName = '';
    this.processType = null;
    this.locationName = '';
    this.processingId = '';
    this.comment = '';
  }

  toJSON(): ReleaseDataFormJSON {
    let json_to_stringify: ReleaseDataFormJSON = {
      process_name: null,
      process_type: null,
      location_name: null,
      processing_id: null,
      comment: null,
    };

    json_to_stringify.process_name = this.processName;
    json_to_stringify.process_type = this.processType;
    json_to_stringify.location_name = this.locationName;
    json_to_stringify.processing_id = this.processingId;
    json_to_stringify.comment = this.comment;

    return json_to_stringify;
  }

  @action
  fromJSON(formValues: Partial<ReleaseDataFormJSON>) {
    const convertedFields: ReleaseDataFormFields = ReleaseDataForm.fromJSON(formValues);
    this.updateForm(convertedFields);
  }

  /** Created static method so components don't need to construct class instance
   *  @todo Could probably just move this outside of class
   */
  static fromJSON(formValues: Partial<ReleaseDataFormJSON>): ReleaseDataFormFields {
    const convertedFields: ReleaseDataFormFields = {
      processName: formValues.process_name ?? null,
      processType: formValues.process_type ?? null,
      locationName: formValues.location_name ?? null,
      processingId: formValues.processing_id ?? null,
      comment: formValues.comment ?? '',
    };
    return convertedFields;
  }
}
