/** @jsxImportSource @emotion/react */

/** originally based on MUI's table example: https://mui.com/material-ui/react-table/#sorting-amp-selecting */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  Checkbox,
  css,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  TableRowProps,
  Tooltip,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { useServices } from 'services';

import { GeneralAutocomplete } from 'components/common/GeneralAutocomplete';
import { LabeledTextField } from 'components/common/LabeledTextField';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Text } from 'components/styles';
import { RunMode, RunModeSelectionOptions } from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';

import { EnhancedTableHead } from './EnhancedTableHead';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { getComparator, processStateIsEditable, stableSort } from './tableUtils';
import { Data, Order, ProcessStateTableData } from './types';

export interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

export interface ProcessStateTableProps {
  pipelineId: PipelineIdentifier;
  data: ProcessStateTableData[];

  selectedRows: readonly number[];
  onSetSelected: (selected: readonly number[]) => void;

  onSubmitted?: () => void;

  hideTitle?: boolean;
}

/**
 * @todo Do we want/need pagination?
 * @todo Do we want a confirmation dialog?
 */
const ProcessStateTable = observer(
  ({
    pipelineId,
    data,
    selectedRows: selected,
    onSetSelected: setSelected,
    onSubmitted,
    hideTitle,
  }: ProcessStateTableProps) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof ProcessStateTableData>('changedOn');
    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const [selected, setSelected] = useState<readonly number[]>([]);
    // const [page, setPage] = useState(0);
    // const [dense, setDense] = useState(false);
    // const [rowsPerPage, setRowsPerPage] = useState(5);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const disableReasonField = !selected.length;

    const { processingService } = useServices();

    const [newReason, setNewReason] = useState<string | null>(null);
    const [selectedRunMode, setSelectedRunMode] = useState<string | null>(null);

    const editableData = useMemo(
      () => data.filter((processStates) => processStateIsEditable(processStates.processType)),
      [data]
    );

    const clearFields = useCallback(() => {
      setNewReason(null);
      setSelectedRunMode('');
    }, []);

    const clearSelection = () => {
      setSelected([]);
    };

    useEffect(() => {
      if (!selected.length) {
        clearFields();
      }
    }, [clearFields, selected.length]);

    const handleResetRunModeChange = () => {
      clearFields();
      clearSelection();
    };

    const handleSubmitModeChange = async (value: RunMode | null) => {
      setIsSubmitting(true);
      await processingService.sleep(500); // artificial wait to feel like something's happening

      const selectedProcessTypes = selected
        .map((id) => editableData.find((value) => value.id === id)?.processType ?? '')
        .filter((value) => !!value);

      try {
        if (!value) {
          console.warn(`Submitted run mode value '${value}' is not a valid run mode.`);
          return;
        }

        await processingService.submitRunModeChange(value, pipelineId, selectedProcessTypes, newReason?.trim() ?? '');

        // Run post-submission methods
        setIsSubmitting(false);
        clearFields();
        clearSelection();
        if (onSubmitted) {
          onSubmitted();
        }
      } catch (error) {
        console.error('ProcessStateTable: Error encountered while submitting new.');
      }
    };

    const theme = useTheme();

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ProcessStateTableData) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = editableData.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
      // onSetSelected(newSelected)
    };

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const handleChangePage = (event: unknown, newPage: number) => {
    //   setPage(newPage);
    // };

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setRowsPerPage(parseInt(event.target.value, 10));
    //   setPage(0);
    // };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const visibleRows = useMemo(
    //   // () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    //   () =>
    //     data
    //       ? stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //       : [],
    //   [data, order, orderBy, page, rowsPerPage]
    // );

    const sortedRows = useMemo(
      () => (data ? stableSort(data, getComparator(order, orderBy)) : []),
      // TODO: #CommentedForReference Planning to reference code below for future generalization
      // () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      // data.sort(getComparator(order, orderBy))
      [data, order, orderBy]
    );

    return (
      <Stack sx={{ width: '100%' }} gap={'0.5rem'}>
        <Text css={{ padding: theme.spacing(0, 2) }}>Current Process States</Text>
        <Paper elevation={4} sx={{ width: '100%', paddingBottom: theme.spacing(1) }}>
          {!hideTitle && <EnhancedTableToolbar numSelected={selected.length} tableLabel="Process States" />}
          <TableContainer>
            <Table css={tableStyle} aria-labelledby="tableTitle" size={'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={editableData.length}
              />
              <TableBody>
                {/* TODO: #CommentedForReference Planning to reference code below for future generalization */}
                {/* {visibleRows.map((row, index) => { */}
                {sortedRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isEditable = processStateIsEditable(row.processType);

                  const editProps: Partial<TableRowProps> = isEditable
                    ? { sx: { cursor: 'pointer' }, onClick: (event) => handleClick(event, row.id) }
                    : { sx: { [`.${tableCellClasses.root}`]: { color: theme.palette.text.disabled } } };
                  const editableTooltipText = isEditable
                    ? undefined
                    : `Process States for "${row.processType}" are read-only`;

                  return (
                    <Tooltip arrow key={row.id} placement="left" title={editableTooltipText}>
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        {...editProps}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={!isEditable}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.processType}
                        </TableCell>
                        <TableCell>{row.state}</TableCell>
                        <TableCell>{row.changedOn}</TableCell>
                        <TableCell css={{ maxWidth: 500 }}>{row.description}</TableCell>
                      </TableRow>
                    </Tooltip>
                  );
                })}
                {/* TODO: #CommentedForReference Planning to reference code below for future generalization */}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>

          {/* TODO: #CommentedForReference Planning to reference code below for future generalization */}
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

          {/* Fallback if there's no states found */}
          {!sortedRows.length && (
            <Text italic padding={theme.spacing(5)} textAlign="center">
              No Pipeline States
            </Text>
          )}
        </Paper>

        {/* Process State Change fields */}
        <Text css={{ padding: theme.spacing(1, 2, 0), color: disableReasonField ? grey[500] : undefined }}>
          Change State for Selected Processes
        </Text>

        <Paper elevation={2}>
          {
            <form
              css={[formStyle, disableReasonField ? formDisabledStyle : formEnabledStyle]}
              onSubmit={(e) => {
                e.preventDefault();
                return handleSubmitModeChange(
                  selectedRunMode && selectedRunMode in RunModeSelectionOptions
                    ? RunModeSelectionOptions[selectedRunMode]
                    : null
                );
              }}
            >
              <RunModeSelectContainer>
                <ReasonFieldsContainer>
                  <GeneralAutocomplete
                    label="New State"
                    value={selectedRunMode}
                    disabled={disableReasonField || isSubmitting}
                    size="small"
                    options={Object.keys(RunModeSelectionOptions)}
                    onChange={setSelectedRunMode}
                    helperText={disableReasonField && 'Check one or more processes to change the state'}
                  />

                  <LabeledTextField
                    label="Reason for Change"
                    value={newReason ?? ''}
                    disabled={!selectedRunMode || isSubmitting}
                    size="small"
                    fullWidth
                    onChange={setNewReason}
                    helperText={!disableReasonField && !selectedRunMode && 'Select a new process state'}
                  />
                </ReasonFieldsContainer>

                <span css={submitButtonStyle}>
                  <SubmitButton
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={disableReasonField || !newReason?.trim() || isSubmitting}
                  >
                    {isSubmitting ? <LoadingSpinner size={theme.typography.fontSize} /> : 'Update'}
                  </SubmitButton>
                  <SubmitButton
                    size="small"
                    variant="outlined"
                    onClick={handleResetRunModeChange}
                    disabled={disableReasonField || isSubmitting}
                  >
                    Reset
                  </SubmitButton>
                </span>
              </RunModeSelectContainer>
            </form>
          }
        </Paper>
      </Stack>
    );
  }
);

export default ProcessStateTable;

const tableStyle = css`
  min-width: 750px;
  thead {
    background-color: ${grey[800]};
  }
  tbody {
    background-color: ${grey[900]};
  }
`;

const formStyle = css`
  border-color: ${grey[700]};
  border-radius: 5px;
`;

const formDisabledStyle = css`
  background-color: hsl(0, 0%, 17%);
`;
const formEnabledStyle = css`
  background-color: ${grey[900]};
  background-color: hsl(0, 0%, 13%);
`;

const RunModeSelectContainer = styled('div')(
  ({ theme }) => css`
    /* Stack fields when small */
    ${theme.breakpoints.down('md')} {
      display: flex;
      gap: 2rem;
      flex-direction: column;

      padding: 0.5rem 2rem;
      padding-bottom: 1rem;
    }

    /* Render in row otherwise */
    ${theme.breakpoints.up('md')} {
      display: grid;
      column-gap: 1rem;
      justify-content: center;
      grid-template-columns: 1fr 1fr;

      padding: 0.5rem 15rem;
      padding-bottom: 1rem;
    }
  `
);

const ReasonFieldsContainer = styled('div')(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 300px;
    }
  `
);

const SubmitButton = styled(Button)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 250px;
    }
  `
);

const submitButtonStyle = css`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  padding: 0;
`;
