import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { IconButton, IconButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { UseStyles } from 'styles/utilityTypes';
import { ProcessType } from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface TimelineLabelButtonProps extends UseStyles<typeof useStyles> {
  disabled: IconButtonProps['disabled'];
  type: ProcessType;
  buttonContent: ReactNode;
  pipelineId?: PipelineIdentifier;
  className?: string;

  onClick?: () => void;
}

const TimelineLabelButton = observer((props: TimelineLabelButtonProps): React.ReactElement | null => {
  const { buttonContent, disabled, onClick } = props;

  return (
    <IconButton disabled={disabled} size="small" color="primary" component="span" onClick={onClick}>
      {buttonContent}
    </IconButton>
  );
});

export default TimelineLabelButton;
