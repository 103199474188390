import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { FormLabelOutlined } from 'components/filters/tabs/FilterTab';
import { Text } from 'components/styles';
import { ConfDirDescriptions, ConfDirOptions } from 'models/RunProcessForm';
import { UseStyles } from 'styles/utilityTypes';
import { ConfDirResponse } from 'utils/types';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
  },
  moreInfo: {
    padding: theme.spacing(1, 2),
    backgroundColor: grey[900],
    borderRadius: 5,
    marginBottom: theme.spacing(1),
  },
}));

export interface ConfDirRadioProps extends UseStyles<typeof useStyles> {
  id: string;
  value: string | null;
  onChange: (value: string) => void;
  onClear: () => void;

  // fetching parameters
  processName: string | null;
  locationName: string | null;
  processingId?: string | null;
  isReprocessing?: boolean;

  label?: string;
  disabled?: boolean;
  helperText?: ReactNode;
  error?: boolean;
  disabledValues?: ConfDirOptions[];

  className?: string;
}

export type ExistingConfDirPayload = {
  process_name: ConfDirRadioProps['processName'];
  location_name: ConfDirRadioProps['locationName'];
  processing_id: ConfDirRadioProps['processingId'];
  is_reprocessing: ConfDirRadioProps['isReprocessing'];
};

const ConfDirRadio = observer((props: ConfDirRadioProps): React.ReactElement | null => {
  const classes = useStyles(props);
  const [armflowConfDir, setArmflowConfDir] = useState<ConfDirResponse | null>(null);
  // const [nonReleasedConfDir, setNonReleasedConfDir] = useState<ConfDirOptions | null>(null);

  // const { className, id, value, onChange, onClear, label, helperText, error, disabledValues } = props;
  const { className, id, value, onChange, label, disabled, disabledValues } = props;
  // const { processName, locationName, processingId, isReprocessing } = props;

  // const missingCoreInfo = !processName || !locationName;
  // const missingProcessingId = isReprocessing && !processingId;
  // const noDisableRadio = missingCoreInfo || missingProcessingId;

  const theme = useTheme();
  const { processingService } = useServices();

  const confDir = value as ConfDirOptions;
  const path = confDir === ConfDirOptions.ARMFlowConf ? armflowConfDir?.conf_dir : ConfDirDescriptions[confDir]?.path;

  const handleChange = useCallback((value: string) => onChange(value), [onChange]);

  useEffect(() => {
    processingService.fetchArmflowConfDir().then((data) => setArmflowConfDir(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run once, hence no dependencies

  return (
    <div>
      <FormLabelOutlined
        style={{
          padding: theme.spacing(0, 0.8),
          transform: 'translate(-18px, -22px) scale(0.75)',
          backgroundColor: '#383838',
        }}
      >
        {label ?? 'Conf. Directory'}
      </FormLabelOutlined>

      <RadioGroup
        row
        className={clsx(classes.root, className)}
        id={id}
        value={confDir}
        onChange={(_, val) => handleChange(val)}
      >
        <span>
          <FormControlLabel
            value={ConfDirOptions.DataConf}
            control={<Radio size="small" />}
            label={'Data conf'}
            disabled={disabled || disabledValues?.includes(ConfDirOptions.DataConf)}
          />

          {/* Disable if armflow conf dir couldn't be retrieved */}
          <FormControlLabel
            value={ConfDirOptions.ARMFlowConf}
            control={<Radio size="small" />}
            label={'ARMFlow conf'}
            disabled={
              disabled ||
              disabledValues?.includes(ConfDirOptions.ARMFlowConf) ||
              !armflowConfDir ||
              !armflowConfDir?.conf_dir
            }
          />
        </span>
      </RadioGroup>
      {value ? (
        <div className={classes.moreInfo}>
          <Text variant="subtitle2">
            PATH: <code>{path}</code>
          </Text>
          <Text variant="caption">
            <i>{`*${ConfDirDescriptions[confDir].description}`}</i>
          </Text>
        </div>
      ) : null}
    </div>
  );
});

export default ConfDirRadio;
