import React from 'react';

import { observer } from 'mobx-react';

import { Tooltip } from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { SpinningSyncIcon } from 'components/styles';
import { UseStyles } from 'styles/utilityTypes';

import TimelineLabelButton, { TimelineLabelButtonProps } from '../TimelineLabelButton';
import RunningProcessesPopover from './RunningProcessesPopover';

export const useStopProcessStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  stopProcess: {
    padding: theme.spacing(2),
    width: 300,
  },
  actions: {
    width: '100%',
    justifyContent: 'right',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  button: {},
}));

const RunningSpinner = () => (
  <Tooltip title="View running processes" placement="right">
    <SpinningSyncIcon style={{ color: green[300] }} />
  </Tooltip>
);

export interface TimelineRunningButtonProps
  extends Required<Pick<TimelineLabelButtonProps, 'pipelineId' | 'type'>>,
    UseStyles<typeof useStopProcessStyles> {
  disabled?: TimelineLabelButtonProps['disabled'];
  className?: string;
}

const TimelineRunningButton = observer((props: TimelineRunningButtonProps): React.ReactElement | null => {
  const { className, disabled, pipelineId, type } = props;

  const classes = useStopProcessStyles(props);

  return (
    <div className={clsx(classes.root, className)}>
      <RunningProcessesPopover pipelineId={pipelineId} processType={type}>
        <TimelineLabelButton
          disabled={disabled}
          pipelineId={pipelineId}
          type={type}
          buttonContent={<RunningSpinner />}
        />
      </RunningProcessesPopover>
    </div>
  );
});

export default TimelineRunningButton;
