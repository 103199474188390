import React, { useState } from 'react';

import { observer } from 'mobx-react';

// import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { UseStyles } from 'styles/utilityTypes';

import PreferenceMenu from './PreferenceMenu';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface PreferenceButtonProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const PreferenceButton = observer((props: PreferenceButtonProps): React.ReactElement | null => {
  const { className } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const classes = useStyles(props);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <span className={clsx(classes.root, className)}>
      <Tooltip title="Open Preferences" arrow placement="top-end">
        <IconButton size="small" onClick={handleOpenMenu}>
          <VisibilityIcon />
          {/* <SettingsIcon /> */}
        </IconButton>
      </Tooltip>
      <PreferenceMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleCloseMenu} />
    </span>
  );
});

export default PreferenceButton;
