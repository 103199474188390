import { createFilterOptions } from '@mui/material';

import { ProcessesMap } from 'utils/types';

export const processNameFilterOptions = <T extends string>(processesMap?: ProcessesMap) => {
  return createFilterOptions<T>({
    // Use combined process name & type to allow user to search on processName OR processType
    stringify: processesMap ? (name) => `${name}${processesMap[name] ? processesMap[name].type : ''}` : undefined,
  });
};
