import { ComponentPropsWithoutRef, useMemo } from 'react';

import { observer } from 'mobx-react';

import { css, styled } from '@mui/material';

import { useDraggable } from '@dnd-kit/core';

import { useServices } from 'services';

import { DATE_ROW_HEIGHT, MINIMUM_LABEL_WIDTH } from 'utils/constants';

interface _ResizeBarProps extends ComponentPropsWithoutRef<'div'> {
  horizontalPosition?: number;

  /**
   * Indicates how far around the ResizeBar is considered the "hover zone"
   * @todo need to implement
   */
  draggableRadius?: number | string;
}

const _ResizeBar = observer(({ horizontalPosition = 0, ...props }: _ResizeBarProps) => {
  const { setNodeRef, transform, listeners, attributes } = useDraggable({ id: 'timeline-resize-bar' });

  const {
    actionBarService: { labelWidth, MaxLabelWidth },
  } = useServices();

  const offsetToMin = useMemo(() => MINIMUM_LABEL_WIDTH - labelWidth, [labelWidth]);
  const offsetToMax = useMemo(() => MaxLabelWidth - labelWidth, [MaxLabelWidth, labelWidth]);
  const widthToBe = transform && labelWidth + transform.x;

  // Prevent bar from being dragged outside beyond min/max bounds
  let xTranslation = transform?.x;
  if (widthToBe && widthToBe < MINIMUM_LABEL_WIDTH) {
    xTranslation = offsetToMin;
  } else if (widthToBe && widthToBe > MaxLabelWidth) {
    xTranslation = offsetToMax;
  }

  // Translation CSS to apply to <div/>
  const style = transform ? { transform: `translate3d(${xTranslation}px, ${transform.y}px, 0)` } : undefined;

  // `labelWidth` subtracted by 1px to prevent overlap with timeline header date
  return <div ref={setNodeRef} style={{ ...style, left: labelWidth - 1 }} {...listeners} {...attributes} {...props} />;
});

export const ResizeBar = styled(_ResizeBar)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    background-color: ${theme.palette.grey[500]};
    height: 100%;
    width: 2px;

    /* top: ${DATE_ROW_HEIGHT}px;
    height: calc(100% - ${DATE_ROW_HEIGHT}px); */

    &:hover,
    &:active {
      width: 4px;
      cursor: ew-resize;
      background-color: ${theme.palette.grey[300]};
      :active {
        background-color: ${theme.palette.common.white};
      }
    }
  `
);
