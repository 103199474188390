import { action, makeObservable, observable } from 'mobx';

import _ from 'lodash';

import { FiltersJSON } from 'models/Filters';
import { ArmflowFormHistory, ArmflowFormModelMap } from 'models/types';
import { ARMFlowForms, SettingsVisualToggleKey } from 'utils/constants';
import { SettingsVisualToggle } from 'utils/types';
import { deepcopy } from 'utils/utils';

/**
 * The StorageService maintains the saving and loading of any settings we want the browser to
 * persist between sessions, such as visual toggles and applied filters.
 *
 * This is accomplished via the use of JavaScript's localStorage.
 */

const workspaceKey = 'armflowStorageService';
const MaxFormHistoryItems = 10;

enum StorageKey {
  SavedPreferences = 'savedPreferences',
  SavedFilter = 'savedFilters',
  FormHistory = 'formHistory',
}

type VisualSettingsStorageJSON = Partial<Record<SettingsVisualToggleKey, Pick<SettingsVisualToggle, 'active'>>>;

type StorageFormat = {
  [StorageKey.SavedPreferences]: StorageService['savedPreferences'];
  [StorageKey.SavedFilter]: StorageService['savedFilter'];
  [StorageKey.FormHistory]: StorageService['formHistory'];
};

export default class StorageService {
  savedPreferences: VisualSettingsStorageJSON | null = null;
  savedFilter: FiltersJSON | null = null;
  formHistory: ArmflowFormHistory | null = null;
  // formHistory: Partial<ArmflowFormHistory> | null = null;

  constructor() {
    this._loadFromLocalStorage();

    makeObservable(this, {
      savedPreferences: observable,
      savedFilter: observable,
      setVisualPreference: action,
      setFilters: action,
      clear: action,

      formHistory: observable,
      addFormToHistory: action,
      clearFormHistory: action,
    });
  }

  private _loadFromLocalStorage() {
    const storageJson = localStorage.getItem(workspaceKey);
    if (!storageJson) return;

    const decoded = this._parseStorage(storageJson);
    if (!decoded) return;

    this.savedPreferences = decoded[StorageKey.SavedPreferences] ?? null;
    this.savedFilter = decoded[StorageKey.SavedFilter] ?? null;
    this.formHistory = decoded[StorageKey.FormHistory] ?? null;
  }

  private _saveToLocalStorage() {
    const allSettings: StorageFormat = {
      savedPreferences: this.savedPreferences,
      savedFilters: this.savedFilter,
      formHistory: this.formHistory,
    };

    localStorage.setItem(workspaceKey, JSON.stringify(allSettings));
  }

  private _parseStorage = (stringifiedJson: string) => {
    try {
      return JSON.parse(stringifiedJson) as Partial<StorageFormat>;
    } catch (error) {
      return null;
    }
  };

  clear() {
    this.savedFilter = null;
    this.savedPreferences = null;
    this.formHistory = null;
    localStorage.removeItem(workspaceKey);
  }

  setVisualPreference(key: SettingsVisualToggleKey, active: SettingsVisualToggle['active']) {
    if (!this.savedPreferences) {
      this.savedPreferences = { [key]: { active } };
    } else this.savedPreferences[key] = { active };

    this._saveToLocalStorage();
  }

  setFilters(filters: StorageService['savedFilter']) {
    this.savedFilter = filters;
    this._saveToLocalStorage();
  }

  addFormToHistory<T extends ARMFlowForms>(
    formType: T,
    formData: ArmflowFormModelMap[T],
    replaceTopItem: boolean = false
  ) {
    if (!this.formHistory) {
      // Initialize history for form type if none exists in history
      this.formHistory = {
        [ARMFlowForms.RUN_PROCESS]: [],
        [ARMFlowForms.RELEASE_DATA]: [],
        [ARMFlowForms.PRUNE_DATA]: [],
        [ARMFlowForms.RUN_BUNDLER]: [],
      };
    }

    // TODO: Check if T is in ARMFlowForms
    function isValidFormType() {
      return Object.values(ARMFlowForms).includes(formType);
    }

    // Throw error if invalid form type
    if (!isValidFormType()) {
      const message = `'${formType}' is not a valid ARMFlow form type`;
      console.error(message);
      throw new Error(message);
    }

    const formHistory = this.formHistory[formType];
    const formJSON = formData.toJSON();

    // Push form data to top of history
    const tempHistory = deepcopy(formHistory) as ArmflowFormHistory[T];
    if (replaceTopItem) tempHistory[0] = formJSON;
    else tempHistory.unshift(formJSON as any); // Casting to `any` since the type should be exclusively RunProcessFormJSON, ReleaseDataFormJSON, or PruneDataFormJSON

    // Remove duplicate(s) from history, ignoring all undefined & null values
    const cleanedFormHistory = tempHistory.map((historyData) =>
      _.omitBy(_.omitBy(historyData, _.isUndefined), _.isNull)
    );
    const history = _.uniqWith(cleanedFormHistory, _.isEqual);

    // Remove eldest item if size is above maximum
    if (history.length > MaxFormHistoryItems) history.pop();

    this.formHistory[formType] = history as any;

    this._saveToLocalStorage();
  }

  clearFormHistory(formType: ARMFlowForms) {
    if (this.formHistory) this.formHistory[formType] = [];
  }
}
