import React from 'react';
import { ReactNode } from 'react';
import { useState } from 'react';

import { observer } from 'mobx-react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Stack, styled } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from 'components/common/StyledAccordion';
import PipelineErrorIcon from 'components/icons/PipelineErrorIcon';
import RunModeIcon from 'components/icons/RunModeIcon';
import { Text } from 'components/styles/Text';
import { UseStyles } from 'styles/utilityTypes';
import {
  ArchivedState,
  DataStatusLabels,
  FileState,
  PipelineErrorLevel,
  ProcessStatus,
  ReleaseState,
  RunMode,
} from 'utils/constants';

import DataPipelineImage from '../../../assets/images/pipeline.svg';
import DataStatusLabel from '../DataStatusLabel';
import ProcessStatusLabel from '../ProcessStatusLabel';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 1rem',
  },
  image: {
    marginLeft: '-24px',
    height: '175px',
  },
  firstParagraph: {
    marginTop: '0px',
  },
}));

enum LegendSection {
  DataPipelines = 'Data Pipelines',
  PipelineErrors = 'Pipeline Errors',
  PipelineProcessStates = 'Pipeline Process States',
  IngestVAPStatus = 'Ingest/VAP Status',
  OutputFileReleaseStatus = 'Output File & Release Status',
}

const IconBullet = styled('div')`
  display: grid;
  grid-template-columns: 2.5rem 1fr;
`;

export interface LegendTabProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const LegendTab = observer((props: LegendTabProps): React.ReactElement | null => {
  const { className } = props;
  const theme = useTheme();
  const [openAccordion, setOpenAccordion] = useState<LegendSection | null>(null);

  const classes = useStyles(props);

  return (
    // TODO: Review the text for each section
    <div className={clsx(classes.root, className)}>
      <AccordionWrapper
        onChange={setOpenAccordion}
        isOpen={openAccordion === LegendSection.DataPipelines}
        title={LegendSection.DataPipelines}
        details={
          <div>
            <img className={classes.image} alt="data pipeline" src={DataPipelineImage} />
            <Text>
              <p className={classes.firstParagraph}>
                Each row represents a <b>Data Pipeline</b>, which is the set of data processing elements working in
                series working in series to process ARM data for a specific location and instrument.
              </p>

              <p>
                All the symbols and colors on the pipeline represent the combined statuses of the different processes
                that make up the pipeline.
              </p>

              <p>See the below sections for more details.</p>
            </Text>
          </div>
        }
      />
      <AccordionWrapper
        onChange={setOpenAccordion}
        isOpen={openAccordion === LegendSection.PipelineErrors}
        title={LegendSection.PipelineErrors}
        details={
          <Text>
            <p>
              If the most recent run of the pipeline had problems, a triangle will appear to the left of the timeline.
              The color indicates the highest priority problem that is currently open:
            </p>
            <Stack spacing={theme.spacing(1)}>
              <IconBullet>
                <PipelineErrorIcon error_level={PipelineErrorLevel.ERROR} />
                <span>
                  <b>Error</b>: The process failed to run.
                </span>
              </IconBullet>
              <IconBullet>
                <PipelineErrorIcon error_level={PipelineErrorLevel.WARNING} />
                <span>
                  <b>Warning</b>: There were warnings in the process log.
                </span>
              </IconBullet>
              <IconBullet>
                <PipelineErrorIcon error_level={PipelineErrorLevel.NOINPUT} />
                <span>
                  <b>No Input Data</b>: The process did not have enough input to run.
                </span>
              </IconBullet>
              <IconBullet>
                <PipelineErrorIcon error_level={PipelineErrorLevel.INFO} />
                <span>
                  <b>Info</b>: There are conditions of interest regarding pipeline operation.
                </span>
              </IconBullet>
            </Stack>

            <p>
              Note that a pipeline error could be shown if either a regular run OR a reprocessing run failed. Click the
              icon to see more information.
            </p>
            <p>
              All pipeline errors are accompanied by a corresponding Service Now INC. If there are open tickets, but
              they are all being addressed, the icon will change:
            </p>
            <IconBullet>
              <PipelineErrorIcon error_level={PipelineErrorLevel.ASSIGNED} />
              <span>
                <b>In Progress</b>: All open issues are being addressed.
              </span>
            </IconBullet>
            <p>Once the process is able to successfully run again, the open issues will be automatically resolved.</p>
          </Text>
        }
      />
      <AccordionWrapper
        onChange={setOpenAccordion}
        isOpen={openAccordion === LegendSection.PipelineProcessStates}
        title={LegendSection.PipelineProcessStates}
        details={
          <Stack spacing={theme.spacing(1)}>
            <p>
              Pipeline process states are shown to the left of each timeline and represent how (or if) pipelines can be
              run via ARMFlow. The following states are available:
            </p>
            <IconBullet>
              <RunModeIcon runMode={RunMode.AUTO} />
              <span>
                <b>Auto</b>: ARMFlow will run process automatically whenever required inputs are available.
              </span>
            </IconBullet>
            <IconBullet>
              <RunModeIcon runMode={RunMode.MANUAL} />
              <span>
                <b>Manual</b>: ARMFlow will only run process when user manually initiates it via the UI or REST API
              </span>
            </IconBullet>
            <IconBullet>
              <RunModeIcon runMode={RunMode.CRITICAL_PAUSE} />
              <span>
                <b>Critical Pause</b>: The process has put itself into manual mode because of a critical problem with
                the data. ARMFlow will only run process when user manually initiates it via the UI or REST API
              </span>
            </IconBullet>
            <IconBullet>
              <RunModeIcon runMode={RunMode.LOCKED} />
              <span>
                <b>Locked</b>: The process is currently locked. ARMFlow will not allow the process to be run for any
                reason.
              </span>
            </IconBullet>
            {/* TODO: RunMode.END_OF_LIFE state doesn't currently exist */}
            {/* <IconBullet>
              <RunModeIcon runMode={RunMode.END_OF_LIFE} />
              <span>
                <b>End of Life</b>: The process has been decommissioned at this location. ARMFlow will only allow
                reprocessing runs.
              </span>
            </IconBullet> */}
            <IconBullet>
              <RunModeIcon runMode={RunMode.DEPRECATED} />
              <span>
                <b>Deprecated</b>: The process has been deprecated. ARMFlow will not allow the process to be run for any
                reason.
              </span>
            </IconBullet>
          </Stack>
        }
      />
      <AccordionWrapper
        onChange={setOpenAccordion}
        isOpen={openAccordion === LegendSection.IngestVAPStatus}
        title={LegendSection.IngestVAPStatus}
        details={
          <Text>
            ADI status associated with each processed day are represented by colored boxes in the timeline. No color in
            the box means that the process did not run for that day.
            <Box padding={theme.spacing(1, 2)}>
              {<ProcessStatusLabel type={ProcessStatus.SUCCESS} />}
              {<ProcessStatusLabel type={ProcessStatus.WARNING} />}
              {<ProcessStatusLabel type={ProcessStatus.NOINPUT} />}
              {<ProcessStatusLabel type={ProcessStatus.ERROR} />}
              {<ProcessStatusLabel type={ProcessStatus.DID_NOT_RUN} />}
            </Box>
            If a given day is surrounded by a dotted line, this means that day was reprocessed and the status of the
            reprocessing is shown:
            <Box padding={theme.spacing(1, 2)}>{<ProcessStatusLabel type={ProcessStatus.REPROCESSED} />}</Box>
          </Text>
        }
      />
      <AccordionWrapper
        onChange={setOpenAccordion}
        isOpen={openAccordion === LegendSection.OutputFileReleaseStatus}
        title={LegendSection.OutputFileReleaseStatus}
        details={
          <Text>
            <p>Roll up file status for each processed day are represented by circles in the timeline:</p>
            <Box padding={theme.spacing(1, 2)}>
              <DataStatusLabel file_state={FileState.GOOD} label={DataStatusLabels.GOOD} />
              <DataStatusLabel file_state={FileState.SOME_BAD} label={DataStatusLabels.SOME_BAD} />
              <DataStatusLabel file_state={FileState.BAD} label={DataStatusLabels.BAD} />
              <DataStatusLabel file_state={FileState.SOME_MISSING} label={DataStatusLabels.SOME_MISSING} />
              <DataStatusLabel file_state={FileState.MISSING} label={DataStatusLabels.MISSING} />
              <DataStatusLabel release_state={ReleaseState.RELEASING} label={DataStatusLabels.RELEASING} />
              <DataStatusLabel archive_state={ArchivedState.SOME_ARCHIVED} label={DataStatusLabels.SOME_ARCHIVED} />
              <DataStatusLabel archive_state={ArchivedState.ARCHIVED} label={DataStatusLabels.ARCHIVED} />
            </Box>
            <p>
              To see file-specific details, right click on the circle and select the ‘<b>Info</b>’ option.
            </p>
          </Text>
        }
      />
    </div>
  );
});

export default LegendTab;

interface AccordionWrapperProps {
  title: LegendSection;
  details?: ReactNode;
  isOpen?: boolean;
  onChange?: (section: LegendSection | null) => void;
}

const AccordionWrapper = ({ title, details, isOpen, onChange, ...props }: AccordionWrapperProps) => {
  return (
    <StyledAccordion expanded={isOpen} onChange={() => onChange && onChange(!isOpen ? title : null)} {...props}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Text>{title}</Text>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Text>{details}</Text>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
