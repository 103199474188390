import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';

import DataStatusIcon from './DataStatusIcon';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  file: {
    height: '100%',
    position: 'absolute',
    fontSize: 36, // TODO: font sizes need to scale based upon zoom level
  },
  release: {
    height: '100%',
    position: 'absolute',
    transform: `translate(${0}px, ${10}px)`,
  },
  archive: {
    height: '100%',
    position: 'absolute',
    'z-index': 10,
  },
}));

export interface DataStatusTimelineIcon2Props extends UseStyles<typeof useStyles> {
  columnIndex: number;
  rowIndex: number;
  visible?: boolean;

  className?: string;
}

const DataStatusTimelineIcon = observer((props: DataStatusTimelineIcon2Props): React.ReactElement | null => {
  const { className, rowIndex, columnIndex, visible } = props;

  const classes = useStyles(props);

  const { processingService } = useServices();
  const { timelineData } = processingService;

  const pipelineIndex = rowIndex - 1;
  const runDataIndex = columnIndex - 1;

  const pipelineData = timelineData.timelines[pipelineIndex] ?? null;
  const runData = pipelineData.days[runDataIndex] ?? null;

  let fileIcon: ReactNode;
  let archiveIcon: ReactNode;
  let releaseIcon: ReactNode;
  if (runData) {
    fileIcon = <DataStatusIcon file_state={runData.file_state} className={classes.file} timeline />;
    releaseIcon = <DataStatusIcon release_state={runData.release_state} className={classes.release} timeline />;
    archiveIcon = <DataStatusIcon archive_state={runData.archive_state} className={classes.archive} timeline />;
  }

  if (!visible) return null;
  return (
    <Stack direction="row" justifyContent="center" className={clsx(classes.root, className)}>
      {fileIcon}
      {archiveIcon}
      {releaseIcon}
    </Stack>
  );
});

export default DataStatusTimelineIcon;
