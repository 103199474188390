import React from 'react';

import { observer } from 'mobx-react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { ArmflowConfirmDialog } from 'components/dialogs/common/ArmflowConfirmDialog';
import { Text } from 'components/styles';
import { UseStyles } from 'styles/utilityTypes';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface StopProcessConfirmProps extends UseStyles<typeof useStyles>, ArmflowConfirmDialog {
  pipelineName: string;
  processingIdList: Array<string | null>;
  className?: string;
}

const StopProcessConfirm = observer((props: StopProcessConfirmProps): React.ReactElement | null => {
  const { className, open, pipelineName, processingIdList, onCancel, onSubmit } = props;

  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <div className={clsx(classes.root, className)}>
      <Dialog open={open}>
        <DialogTitle>
          Stop Process?
          {/* {'  '}? */}
        </DialogTitle>

        <DialogContent dividers>
          <Stack width={300} spacing={theme.spacing(2)}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Text>PCM Process Name:</Text>
              <Text>{pipelineName}</Text>
            </Box>
            <Stack spacing={theme.spacing(1)}>
              <Text>Processing IDs:</Text>
              <Stack paddingLeft={theme.spacing(4)}>
                {processingIdList.map((value, idx) => (
                  <li key={idx}>{value}</li>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onSubmit} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default StopProcessConfirm;
