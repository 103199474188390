import React from 'react';

import { observer } from 'mobx-react';

import { Stack, TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import clsx from 'clsx';
import { DateTime } from 'luxon';

import { UseStyles } from 'styles/utilityTypes';
import { CommonDatePickerProps, CommonTextFieldProps } from 'utils/constants';

type FormDateRange = [DateTime | null, DateTime | null];

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  datePicker: {
    width: '50%',
  },
}));

export interface FormDateRangePickerProps extends UseStyles<typeof useStyles> {
  value: FormDateRange;
  onChange: (date: FormDateRange, keyboardInputValue?: string | undefined) => void;

  required?: boolean;
  disabled?: boolean;
  startText?: React.ReactNode;
  endText?: React.ReactNode;

  startError?: boolean;
  startHelperText?: string | false | undefined;
  endError?: boolean;
  endHelperText?: string | false | undefined;

  className?: string;

  // Misc
  hideField?: 'start' | 'end';
  additionalTextProps?: Partial<TextFieldProps>;
}

const FormDateRangePicker = observer((props: FormDateRangePickerProps): React.ReactElement | null => {
  const { className, hideField } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, className)}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Stack direction="row" gap="0.5rem">
          {/* Start Date */}
          {hideField !== 'start' && <FormStartDatePicker {...props} />}

          {/* End Date */}
          {hideField !== 'end' && <FormEndDatePicker {...props} />}
        </Stack>
      </LocalizationProvider>
    </div>
  );
});

export default FormDateRangePicker;

export type FormDatePickerProps = FormDateRangePickerProps;

export const FormStartDatePicker = ({
  value,
  startText,
  startHelperText,
  startError,
  required,
  disabled,
  onChange,
  additionalTextProps,
  ...props
}: FormDatePickerProps) => {
  const classes = useStyles(props);
  const [startDate, endDate] = value;

  return (
    <DatePicker
      {...CommonDatePickerProps}
      label={startText}
      value={startDate}
      disabled={disabled}
      defaultCalendarMonth={endDate ?? undefined}
      onChange={(value: DateTime | null) => {
        onChange([value, endDate]);
      }}
      renderInput={(params: TextFieldProps) => (
        <TextField
          {...CommonTextFieldProps(params)}
          required={required}
          className={classes.datePicker}
          helperText={startHelperText}
          error={startError}
          {...additionalTextProps}
        />
      )}
    />
  );
};

export const FormEndDatePicker = ({
  value,
  endText,
  endHelperText,
  endError,
  required,
  disabled,
  onChange,
  additionalTextProps,
  ...props
}: FormDatePickerProps) => {
  const classes = useStyles(props);
  const [startDate, endDate] = value;

  return (
    <DatePicker
      {...CommonDatePickerProps}
      label={endText}
      value={endDate}
      disabled={disabled}
      defaultCalendarMonth={startDate ?? undefined}
      onChange={(value: DateTime | null) => {
        onChange([startDate, value]);
      }}
      renderInput={(params: TextFieldProps) => (
        <TextField
          {...CommonTextFieldProps(params)}
          required={required}
          className={classes.datePicker}
          helperText={endHelperText}
          error={endError}
          {...additionalTextProps}
        />
      )}
    />
  );
};
