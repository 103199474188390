import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export const useModalStyles = makeStyles((theme: Theme) => ({
  root: {},
  log: {},
  subtitle: {
    color: grey[400],
  },
  progress: {
    zIndex: theme.zIndex.modal + 1,
  },
  error: {
    zIndex: theme.zIndex.modal + 2,
  },
}));
