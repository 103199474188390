import React from 'react';

import { observer } from 'mobx-react';

import { Button, ButtonProps } from '@mui/material';
import { Stack } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithTheme as WithThemeProps } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import clsx from 'clsx';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export interface ApplyFilterButtonsProps extends WithServicesProps, WithThemeProps {
  className?: string;
  applyButtonType?: ButtonProps['type'];
  onReset?: () => void;

  classes: {
    root: string;
  };
}

export interface ApplyFilterButtonsState {}

class ApplyFilterButtons extends React.Component<ApplyFilterButtonsProps, ApplyFilterButtonsState> {
  render(): React.ReactNode {
    const { classes, services, className, applyButtonType, onReset } = this.props;
    const { processingService } = services;
    const { currentModeFilters: filters } = processingService;
    const { timelineStartDate, timelineEndDate } = filters;

    return (
      <Stack className={clsx(classes.root, className)} direction="row" spacing={1}>
        <Button
          size="small"
          variant="contained"
          disabled={
            filters.disableApplyButton ||
            !filters.changeCounter ||
            !processingService.isValidDateRange([timelineStartDate, timelineEndDate], false)
          }
          type={applyButtonType}
        >
          <b>Apply</b>
        </Button>
        <Button size="small" onClick={onReset} disabled={filters.isDefaultValues([timelineStartDate, timelineEndDate])}>
          Reset
        </Button>
      </Stack>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withServices(observer(ApplyFilterButtons)));
