import React, { useMemo } from 'react';
import { ReactNode } from 'react';

import { Popover } from '@mui/material';
import { useTheme } from '@mui/styles';

import PopupState, { bindHover, bindPopover, bindTrigger } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import { PopoverAnchorPointProps } from 'utils/constants';

export interface TextPopoverProps {
  text: string | ReactNode;

  popoverText: string;
  popoverId: string;

  trigger?: 'hover' | 'click' | 'none'; // TODO: this should probably be required or 'click'-by-default
  popoverHeaderText?: TextPopoverProps['popoverText'];
  popoverPosition?: PopoverAnchorPointProps;
  textTagName?: keyof React.JSX.IntrinsicElements;
  paperStyleOverride?: React.CSSProperties;
}

export const TextPopover = ({
  text,
  textTagName = 'span',
  popoverText,
  popoverId,
  trigger = 'none',
  popoverPosition,
  paperStyleOverride = {},
}: TextPopoverProps) => {
  const theme = useTheme();

  const TextTag = useMemo(() => {
    return textTagName;
  }, [textTagName]);

  const getPopoverAndTrigger = () => {
    switch (trigger) {
      case 'hover':
        return {
          bindFunc: bindHover,
          PopoverComponent: HoverPopover,
        };

      case 'click':
        return {
          bindFunc: bindTrigger,
          PopoverComponent: Popover,
        };

      default:
        return {
          bindFunc: null,
          PopoverComponent: null,
        };
    }
  };

  const { bindFunc, PopoverComponent } = getPopoverAndTrigger();

  if (!bindFunc || !PopoverComponent) return <span>{text}</span>;

  return (
    <>
      <PopupState variant="popper" popupId={popoverId}>
        {(popupState) => (
          <span>
            {/* <span {...bindHover(popupState)}>{text}</span> */}
            <span {...bindFunc(popupState)}>{text}</span>

            {popoverText && (
              <PopoverComponent
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                {...popoverPosition}
                // style={{
                //   pointerEvents: 'none',
                // }}
                slotProps={{
                  paper: {
                    style: {
                      padding: theme.spacing(0, 2),
                      ...paperStyleOverride,
                    },
                  },
                }}
              >
                <TextTag>{popoverText}</TextTag>
              </PopoverComponent>
            )}
          </span>
        )}
      </PopupState>
    </>
  );
};
