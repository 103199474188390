import { action, makeObservable, observable } from 'mobx';

import { AxiosResponse } from 'axios';

import { API } from 'utils/constants';
import { AccountDetails } from 'utils/types';

import AdiApiService from './AdiApiService';

type AuthServiceProps = {
  adiApiService: AdiApiService;
};

export default class AuthService {
  private adiApiService: AdiApiService;

  // Account details
  accountDetails: AccountDetails | null;

  constructor({ adiApiService }: AuthServiceProps) {
    this.adiApiService = adiApiService;
    this.accountDetails = null;

    makeObservable(this, {
      accountDetails: observable,
      getLoggedInUser: action,
      logout: action,
    });
  }

  getLoggedInUser() {
    // Fetch the logged in user if it has not been set yet
    if (!this.accountDetails) {
      return this.adiApiService.axios
        .get(API.ME)
        .then((response) => {
          const data: AccountDetails = response.data;
          const { name, email } = data;
          if (name) {
            this.accountDetails = {
              name,
              email,
            };
          }
        })
        .catch((error) => {
          this.adiApiService.handleError(error, {
            id: 'error-retrieving-current-user',
            message: 'Cannot retrieve current user.',
          });
          return ['Cannot retrieve current user.', []];
        });
    }
  }

  logout() {
    return this.adiApiService.axios
      .get(API.LOGOUT)
      .then((_: AxiosResponse) => {
        this.accountDetails = null;
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: 'error-in-logout',
          title: 'Error During Logout',
        });

        return ['Cannot logout.', []];
      });
  }
}
