import React from 'react';

import { observer } from 'mobx-react';

import { makeStyles } from '@mui/styles';

import { useServices } from 'services';

import PipelineErrorIcon from 'components/icons/PipelineErrorIcon';
import { PipelineTimeline } from 'models/Timeline';
import { UseStyles } from 'styles/utilityTypes';
import { PipelineErrorArgs } from 'utils/types';

import TimelineLabelButton, { TimelineLabelButtonProps } from './TimelineLabelButton';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface TimelineErrorButtonProps
  extends Required<Pick<TimelineLabelButtonProps, 'pipelineId' | 'type'>>,
    UseStyles<typeof useStyles> {
  error_level: PipelineTimeline['error_level'];
  disabled?: TimelineLabelButtonProps['disabled'];
  className?: string;
}

const TimelineErrorButton = observer((props: TimelineErrorButtonProps): React.ReactElement | null => {
  const { error_level, disabled, pipelineId, type } = props;
  const { process_name, site, facility } = pipelineId;

  const { processingService } = useServices();

  const displayErrorLogDialog = () => {
    // TODO: (2024/07/09, Elvis) Should `processType` be used in error-fetch request args? It was previously passed but unused
    const pipelineErrorPayload: PipelineErrorArgs = { pipelineId: { process_name, site, facility } };
    processingService.fetchPipelineError(pipelineErrorPayload);
    processingService.setSelectedPipelineId(pipelineId);
  };

  return (
    <TimelineLabelButton
      disabled={disabled}
      pipelineId={pipelineId}
      type={type}
      onClick={() => displayErrorLogDialog()}
      buttonContent={<PipelineErrorIcon error_level={error_level} />}
    />
  );
});

export default TimelineErrorButton;
