import * as yup from 'yup';

import { ReleaseDataFormFields } from 'models/ReleaseDataForm';

const validationSchema = yup.object({
  processName: yup.string().nullable().required('PCM Process Name is a required field'),
  locationName: yup.string().nullable().required('Location is a required field'),
  processingId: yup.string().nullable().required('Processing Id is a required field'),
  comment: yup.string(),
});

const formIds: Record<keyof ReleaseDataFormFields, keyof ReleaseDataFormFields> = {
  processName: 'processName',
  processType: 'processType',
  locationName: 'locationName',
  processingId: 'processingId',
  comment: 'comment',
};

export const ReleaseDataFormTokens = {
  formIds,
  validationSchema,
};
