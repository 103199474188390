import React, { FormEvent, useMemo } from 'react';

import { observer } from 'mobx-react';

import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  styled,
  TextField,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';
import _ from 'lodash';

import { useServices } from 'services';

import { processNameFilterOptions } from 'components/autocomplete/filterOptions';
import { ProcessNameListItem } from 'components/autocomplete/ProcessNameListItem';
import { TextPopover } from 'components/common/TextPopover';
import { ToggleFilterKeys, ToggleRunType } from 'models/Filters';
import { UseStyles } from 'styles/utilityTypes';
import { AnchorPoints, ProcessType, RefreshReason } from 'utils/constants';

import ApplyFilterButtons from '../ApplyFilterButtons';
import BorderedFilterFieldGroup from '../components/BorderedFilterFieldGroup';
import TimelineDatePicker from '../TimelineDatePicker';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontFamily: 'Raleway',
    color: '#ffffff',
  },
  formControl: {
    padding: theme.spacing(2, 2),
    paddingTop: 0,

    minWidth: 180,
    fontFamily: 'Raleway',
    width: '100%',
    // paddingRight: '70px',
  },
  selectionStyle: {
    fontFamily: 'Raleway',
  },
  formBorder: {
    transform: 'translate(8px, -6px)',
    fontSize: 12,
    // backgroundColor: '#424242',
  },
  toggleGroup: {
    paddingBottom: theme.spacing(2),
  },
  toggleButton: {
    padding: theme.spacing(0, 2),
  },
  resetButton: {
    marginTop: '15px',
  },
  applyButton: {
    marginLeft: '1rem',
  },
}));

export interface FilterTabProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const FilterTab = observer((props: FilterTabProps): React.ReactElement | null => {
  const { className } = props;

  const classes = useStyles(props);
  const { processingService } = useServices();
  const { currentModeFilters: filters } = processingService;

  const onApplyFilters = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    processingService.applyFilters(RefreshReason.Manual);
  };

  const onResetFilters = () => {
    filters.reset();
    processingService.applyFilters();
  };

  const onPipelineTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ProcessType;
    if (Object.values(ProcessType).includes(value)) {
      if (event.target.checked) {
        filters.addType(value as ProcessType);
      } else {
        filters.removeType(value as ProcessType);
      }
    }
  };

  const onPipelineNameChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    filters.updateProcessNames(value);
  };

  const onLocationChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    filters.updateLocations(value);
  };

  const onUserChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    filters.updateUsers(value);
  };

  const onShowRunTypeChange: RadioGroupProps['onChange'] = (event, value) => {
    const radioValue = value as ToggleRunType;
    switch (radioValue) {
      case ToggleRunType.Regular:
        filters.toggleShowRegularRuns(true);
        filters.toggleShowReprocessingRuns(false); // unset the others
        break;

      case ToggleRunType.Reprocessing:
        filters.toggleShowReprocessingRuns(true);
        filters.toggleShowRegularRuns(false); // unset the others
        break;

      case ToggleRunType.All:
      default:
        // set all to True
        filters.toggleShowRegularRuns(true);
        filters.toggleShowReprocessingRuns(true);
        break;
    }
  };

  // Construct list of toggle filters items
  const toggleItems: ToggleFilterProps[] = useMemo(() => {
    const items: ToggleFilterProps[] = [
      {
        label: 'Show pipeline errors only',
        activeKey: 'pipelineErrorsOnly',
        onChange: (e) => filters.toggleErrorsOnlyDisplay(e.target.checked),
      },
    ];
    return items;
  }, [filters]);

  // Construct list of toggle filters items
  const radioItems: RadioItem[] = useMemo(() => {
    const items: RadioItem[] = [
      {
        label: 'Show all',
        value: ToggleRunType.All,
      },
      {
        label: 'Show standard only',
        value: ToggleRunType.Regular,
      },
      {
        label: 'Show reprocessing only',
        value: ToggleRunType.Reprocessing,
      },
    ];
    return items;
  }, []);

  // Determine default radio selection
  const defaultRunType = useMemo(() => {
    if (filters.showRegularRuns && !filters.showReprocessingRuns) {
      return ToggleRunType.Regular;
    } else if (filters.showReprocessingRuns && !filters.showRegularRuns) {
      return ToggleRunType.Reprocessing;
    }
    return ToggleRunType.All;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run on first render

  return (
    <div className={clsx(classes.root, className)}>
      <form onSubmit={onApplyFilters}>
        <TimelineDatePicker className={classes.formControl} />
        <PipelineTypeFilter onChange={onPipelineTypeChange} />
        <PipelineNameFilter onChange={onPipelineNameChange} />
        <LocationFilter onChange={onLocationChange} />
        <RanByUserFilter onChange={onUserChange} />
        <div className={classes.toggleGroup}>
          <RadioFilter items={radioItems} onChange={onShowRunTypeChange} defaultValue={defaultRunType} />
        </div>
        <div className={classes.toggleGroup}>
          {toggleItems.map((item) => (
            <ToggleFilter key={_.kebabCase(item.activeKey)} {...item} />
          ))}
        </div>
        <ApplyFilterButtons applyButtonType="submit" className={classes.applyButton} onReset={onResetFilters} />
      </form>
    </div>
  );
});

export default FilterTab;

export const FormGroupOutlined = styled(FormGroup)`
  border: 1px solid #757575;
  border-radius: 4px;
  padding: 10px;
`;

export const FormLabelOutlined = styled(FormLabel)`
  background-color: #4c4c4c;
  padding: 0px 4px 0px 4px;
  position: absolute;
`;

type TextFieldAutocomplete = AutocompleteProps<string, true, false, false>;
interface FilterFieldProps<T> extends UseStyles<typeof useStyles> {
  onChange?: T;
}

export const PipelineTypeFilter = observer((props: FilterFieldProps<CheckboxProps['onChange']>) => {
  const { onChange } = props;
  const classes = useStyles(props);
  const { processingService } = useServices();
  const filters = processingService.currentModeFilters;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <FormLabelOutlined className={classes.formBorder}>Pipeline Type</FormLabelOutlined>
      <FormGroupOutlined row>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.processType.has(ProcessType.INGEST)}
              onChange={onChange}
              name={ProcessType.INGEST}
              value={ProcessType.INGEST}
              style={{ color: '#ffffff' }}
            />
          }
          label="Ingest"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.processType.has(ProcessType.VAP)}
              onChange={onChange}
              name={ProcessType.VAP}
              value={ProcessType.VAP}
              style={{ color: '#ffffff' }}
            />
          }
          label="VAP"
        />
      </FormGroupOutlined>
    </FormControl>
  );
});

export const PipelineNameFilter = observer((props: FilterFieldProps<TextFieldAutocomplete['onChange']>) => {
  const { onChange } = props;
  const { processingService } = useServices();
  const classes = useStyles(props);
  const { currentModeFilters: filters, processesMap } = processingService;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Autocomplete
        multiple
        value={Array.from(filters.processName)}
        onChange={onChange}
        options={processingService.processNames}
        filterOptions={processNameFilterOptions(processesMap)}
        renderOption={(props, option) => (
          <ProcessNameListItem {...props} processName={option} processInfo={processesMap[option]} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="PCM Process Name"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </FormControl>
  );
});

export const LocationFilter = observer((props: FilterFieldProps<TextFieldAutocomplete['onChange']>) => {
  const { onChange } = props;
  const { processingService } = useServices();
  const classes = useStyles(props);
  const filters = processingService.currentModeFilters;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Autocomplete
        multiple
        value={Array.from(filters.location)}
        onChange={onChange}
        options={processingService.locations}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Location"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </FormControl>
  );
});

export const RanByUserFilter = observer((props: FilterFieldProps<TextFieldAutocomplete['onChange']>) => {
  const { onChange } = props;
  const { authService, processingService } = useServices();
  const classes = useStyles(props);
  const theme = useTheme();
  const filters = processingService.currentModeFilters;
  const isLoggedIn = !!authService.accountDetails;
  const labelName = 'Ran By';
  const disablePopover = !isLoggedIn;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <TextPopover
        trigger={!disablePopover ? 'none' : 'hover'}
        popoverId="user-filter-popover"
        popoverText={`Filtering by '${labelName}' requires login`}
        popoverPosition={AnchorPoints['Left']}
        paperStyleOverride={{
          padding: theme.spacing(1, 2),
        }}
        text={
          <Autocomplete
            multiple
            disabled={disablePopover}
            value={Array.from(filters.user)}
            onChange={onChange}
            options={processingService.users}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${labelName} ${disablePopover ? '(disabled)' : ''}`}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        }
      />
    </FormControl>
  );
});

type ToggleFilterProps = FilterFieldProps<CheckboxProps['onChange']> & {
  label: string;
  activeKey: ToggleFilterKeys;
};
export const ToggleFilter = observer((props: ToggleFilterProps) => {
  const classes = useStyles(props);
  const { processingService } = useServices();
  const { activeKey, label, onChange } = props;

  const filters = processingService.currentModeFilters;
  const active = filters[activeKey];

  return (
    <FormControl component="fieldset" className={clsx(classes.formControl, classes.toggleButton)}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={active} onChange={onChange} name={_.kebabCase(activeKey)} />}
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
});

type RadioItem = {
  label: string;
  value: ToggleRunType;
};
type RadioFilterProps = Pick<RadioGroupProps, 'onChange' | 'defaultValue'> & {
  items: RadioItem[];
};
export const RadioFilter = observer((props: RadioFilterProps) => {
  const classes = useStyles(props);
  const { items, ...groupProps } = props;
  return (
    <BorderedFilterFieldGroup label="Display Runs" className={clsx(classes.formControl, classes.toggleButton)}>
      <RadioGroup {...groupProps}>
        {items.map(({ label, value }) => {
          return (
            <FormControlLabel
              key={_.kebabCase(label)}
              control={<Radio value={value} name={_.kebabCase(label)} />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </BorderedFilterFieldGroup>
  );
});
