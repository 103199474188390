import { action, makeObservable, observable } from 'mobx';

export default class DrawerService {
  drawerOpen: boolean = true;

  constructor() {
    makeObservable(this, {
      drawerOpen: observable,
      toggleDrawer: action,
    });
  }

  toggleDrawer = (open: boolean): void => {
    this.drawerOpen = open;
  };
}
