import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/material';

export const SpinningSyncIcon = styled(SyncIcon)`
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
`;
