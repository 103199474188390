/** @jsxImportSource @emotion/react */
import { ComponentPropsWithoutRef } from 'react';

import { css } from '@emotion/react';
import { Autocomplete, autocompleteClasses, BaseTextFieldProps, InputLabel, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';

type InheritedTextFieldProps = Pick<BaseTextFieldProps, 'size' | 'helperText'>;

export interface GeneralAutocompleteProps
  extends Omit<ComponentPropsWithoutRef<'span'>, 'onChange'>,
    InheritedTextFieldProps {
  className?: string;

  value: string | null;
  options?: readonly string[];

  label?: string;
  labelId?: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
}

export const GeneralAutocomplete = ({
  className,
  label,
  disabled,
  value,
  options = [],
  onChange,

  /** TextField props */
  size,
  helperText,
  ...props
}: GeneralAutocompleteProps) => {
  return (
    <span {...props}>
      <InputLabel css={disabled && disabledLabelStyle}>{label}</InputLabel>
      <Autocomplete
        css={disabled && disabledTextFieldStyle}
        value={value}
        options={options}
        disabled={disabled}
        cy-test="general-autocomplete"
        onChange={(_, value) => onChange && onChange(value)}
        renderInput={(params) => (
          <TextField {...params} css={disabled && disabledTextFieldStyle} size={size} helperText={helperText} />
        )}
      />
    </span>
  );
};

const disabledLabelStyle = css`
  color: ${grey[700]};
`;

const disabledTextFieldStyle = css`
  .${autocompleteClasses.inputRoot} {
    background-color: ${grey[800]};
  }
`;
