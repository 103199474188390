import React from 'react';

import { observer } from 'mobx-react';

import CommentIcon from '@mui/icons-material/Comment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { DateTime } from 'luxon';

import { useServices } from 'services';

import CopyText from 'components/common/CopyText';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from 'components/common/StyledAccordion';
import { TextPopover, TextPopoverProps } from 'components/common/TextPopover';
import { Text } from 'components/styles';
import { UseStyles } from 'styles/utilityTypes';
import { FileDownloadTypes, LogTypeLabel, ProcessStatusLabel } from 'utils/constants';
import { LogfileParameters, PipelineIdentifier, RunInfoFileStatus } from 'utils/types';

import { ActionLink } from '../../common/ActionLink';
import { InfoViewerTable, InfoViewerTableProps } from '../InfoViewer';
import InfoStatusIcon from './InfoStatusIcon';

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProcessHeaders: InfoViewerTableProps['headers'] = [
  null,
  'Status',
  'Processing ID',
  'Run Directory',
  'Ran On',
  'Ran By',
  'Logs',
];
const OutputsHeaders: InfoViewerTableProps['headers'] = [
  null,
  'Files or Datastream',
  'Validation Errors',
  'Archive Status',
  'Release By',
  'Release On',
  'Comment',
];

const InfoViewerTextPopover = ({ ...props }: TextPopoverProps) => <TextPopover textTagName="pre" {...props} />;

export interface InfoViewerBodyProps extends UseStyles<typeof useStyles> {
  className?: string;
  pipelineId: Required<Omit<PipelineIdentifier, 'processingId'>>;
  dataDate: DateTime;
}

const InfoViewerBody = observer((props: InfoViewerBodyProps): React.ReactElement | null => {
  const { className, pipelineId, dataDate } = props;

  const classes = useStyles(props);
  const { processingService } = useServices();
  const { infoViewerData } = processingService;
  const { processType } = pipelineId;

  const info = infoViewerData && infoViewerData[processType];
  if (!info) return null;

  const { process_status, file_status } = info;
  const { logfile_keys, processing_id, ran_by, ran_on, run_directory, status, status_message } = process_status;

  const isRunning = status === ProcessStatusLabel.RUNNING;
  const autoExpand =
    status !== ProcessStatusLabel.SUCCESS || file_status.some((fs) => Object.keys(fs.validation_errors).length > 0);

  const logfileParams: LogfileParameters = {
    date: dataDate.toISODate(),
    processing_id: isRunning ? processing_id : undefined,
  };

  return (
    <div className={clsx(classes.root, className)}>
      <StyledAccordion defaultExpanded={autoExpand}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text>{processType}</Text>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <InfoViewerTable
            title="Process"
            headers={ProcessHeaders}
            rows={[
              [
                <InfoStatusIcon key={'statusIcon'} status={status} />,
                <InfoViewerTextPopover
                  trigger="hover"
                  key={'statusHover'}
                  text={status}
                  popoverId={'infoViewerStatusPopover'}
                  popoverText={status_message}
                />,
                processing_id,
                run_directory ? <CopyText key="runDir" text={run_directory} /> : 'N/A',
                ran_on,
                ran_by,
                <div key="logfiles">
                  {logfile_keys.map((_fileType) => {
                    const fileType =
                      isRunning && _fileType === FileDownloadTypes.PROCESS_LOG
                        ? FileDownloadTypes.REALTIME_PROCESS_LOG
                        : _fileType;

                    return (
                      <div key={fileType}>
                        <ActionLink onClick={() => processingService.fetchLogFile(pipelineId, logfileParams, fileType)}>
                          View {LogTypeLabel[fileType]}
                        </ActionLink>
                      </div>
                    );
                  })}
                </div>,
              ],
            ]}
          />

          <InfoViewerTable
            title="Outputs"
            headers={OutputsHeaders}
            rows={file_status.map((status) => [
              Object.keys(status.validation_errors).length ? (
                <InfoStatusIcon key={'statusIcon'} status={ProcessStatusLabel.ERROR} />
              ) : (
                <InfoStatusIcon key={'statusIcon'} status={ProcessStatusLabel.SUCCESS} />
              ),
              status.name,
              <ValidationErrorStack key="validationErrors" validationErrors={status.validation_errors} />,
              status.archive_status,
              status.released_by,
              status.released_on,
              status.release_comment ? (
                <InfoViewerTextPopover
                  trigger="click"
                  key={'commentHover'}
                  text={
                    <IconButton key={'comment'}>
                      <CommentIcon />
                    </IconButton>
                  }
                  popoverId={'infoViewerCommentPopover'}
                  popoverText={status.release_comment}
                />
              ) : (
                '- - -'
              ),
            ])}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
});

export default InfoViewerBody;

interface ValidationErrorStackProps {
  validationErrors: RunInfoFileStatus['validation_errors'];
}

const ValidationErrorStack = ({ validationErrors, ...props }: ValidationErrorStackProps) => {
  return (
    <Stack>
      {Object.entries(validationErrors).map(([key, value]) => {
        // return key;
        return (
          <InfoViewerTextPopover
            trigger="hover"
            key={'validationErrorHover'}
            text={key}
            popoverId={'infoViewerValidationErrorPopover'}
            popoverText={value}
          />
        );
      })}
    </Stack>
  );
};
