import { action, computed, makeObservable, observable } from 'mobx';

import { ProcessType } from 'utils/constants';
import { EncodeBase64 } from 'utils/utils';

import { ArmflowForm } from './types';

export type RunBundlerFormJSON = {
  process_name: string | null;
  process_type: ProcessType | null;
  location_name: string | null;
};

export type RunBundlerFormFields = {
  processName: string | null;
  processType: ProcessType | null;
  locationName: string | null;
};

export const RUN_BUNDLER_EMPTY_FORM: RunBundlerFormFields = {
  processName: null,
  processType: null,
  locationName: null,
};

export default class RunBundlerForm implements ArmflowForm<RunBundlerFormFields, RunBundlerFormJSON> {
  processName: RunBundlerFormFields['processName'];
  processType: RunBundlerFormFields['processType'];
  locationName: RunBundlerFormFields['locationName'];

  constructor(autofillData?: RunBundlerFormFields) {
    this.processName = autofillData ? autofillData.processName : '';
    this.processType = autofillData ? autofillData.processType : null;
    this.locationName = autofillData ? autofillData.locationName : '';

    makeObservable(this, {
      processName: observable,
      processType: observable,
      locationName: observable,

      query: computed,
      toHistoryString: computed,

      fromJSON: action,
      updateProcessName: action,
      updateProcessType: action,
      updateLocation: action,
      updateForm: action,
      reset: action,
    });
  }

  get query(): string {
    return EncodeBase64(JSON.stringify(this));
  }

  // toHistoryString() {
  get toHistoryString(): string {
    const { processName, locationName } = this;
    return [processName, locationName].join(' ');
  }

  updateProcessName(name: string | null) {
    this.processName = name;
  }

  updateProcessType(processType: ProcessType | null) {
    this.processType = processType;
  }

  updateLocation(location: string | null) {
    this.locationName = location;
  }

  updateForm(formValues: Partial<RunBundlerFormFields>) {
    const { processName, processType, locationName } = formValues;

    if (processName !== undefined) this.processName = processName;
    if (processType !== undefined) this.processType = processType;
    if (locationName !== undefined) this.locationName = locationName;
  }

  reset() {
    this.processName = '';
    this.processType = null;
    this.locationName = '';
  }

  toJSON(): RunBundlerFormJSON {
    let json_to_stringify: RunBundlerFormJSON = {
      process_name: null,
      process_type: null,
      location_name: null,
    };

    json_to_stringify.process_name = this.processName;
    json_to_stringify.process_type = this.processType;
    json_to_stringify.location_name = this.locationName;

    return json_to_stringify;
  }

  fromJSON(formValues: Partial<RunBundlerFormJSON>) {
    const convertedFields: RunBundlerFormFields = RunBundlerForm.fromJSON(formValues);
    this.updateForm(convertedFields);
  }

  /** Created static method so components don't need to construct class instance
   *  @todo Could probably just move this outside of class
   */
  static fromJSON(formValues: Partial<RunBundlerFormJSON>): RunBundlerFormFields {
    const convertedFields: RunBundlerFormFields = {
      processName: formValues.process_name ?? null,
      processType: formValues.process_type ?? null,
      locationName: formValues.location_name ?? null,
    };
    return convertedFields;
  }
}
