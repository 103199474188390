import React, { ReactNode } from 'react';
import { useMemo } from 'react';

import { observer } from 'mobx-react';

import LockIcon from '@mui/icons-material/Lock';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { PipelineTimeline } from 'models/Timeline';
import { UseStyles } from 'styles/utilityTypes';
import { RunMode } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: 30,
    height: 30,
  },
  letter: {
    position: 'absolute',
    top: 5,
    width: '100%',
    fontSize: theme.typography.fontSize,
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
    color: '#353535',
    fontSize: 30,
    left: 0,
    top: 0,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  lock: {
    position: 'absolute',
    left: 3,
  },
}));

export interface RunModeIconProps extends UseStyles<typeof useStyles> {
  runMode: PipelineTimeline['mode'];
  disabled?: boolean;

  className?: string;
}

const RunModeIcon = observer(({ runMode, disabled, ...props }: RunModeIconProps): React.ReactElement | null => {
  const { className } = props;

  const classes = useStyles(props);

  const iconComponent = useMemo(() => {
    let letter: ReactNode = null;
    switch (runMode) {
      case RunMode.MANUAL:
        letter = 'M';
        break;
      case RunMode.AUTO:
        letter = 'A';
        break;
      case RunMode.CRITICAL_PAUSE:
        letter = 'P';
        break;
      case RunMode.DEPRECATED:
        letter = 'D';
        break;
      case RunMode.LOCKED:
        return <LockIcon className={classes.lock} />;
      default:
        break;
    }

    return (
      <>
        <SquareRoundedIcon className={classes.icon} />
        <Typography className={classes.letter}>{letter}</Typography>
      </>
    );
  }, [classes.icon, classes.letter, classes.lock, runMode]);

  return <div className={clsx(classes.root, disabled && classes.disabled, className)}>{iconComponent}</div>;
});
export default RunModeIcon;
