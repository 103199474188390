import { useMemo } from 'react';

import { FormikHelpers, FormikState } from 'formik';

import { API } from 'utils/constants';

export interface ArmflowFormDialog {
  validatorPath: API;
}

export type HandleFormUpdatePayload = {
  formId: string;
  value: unknown;
  skipTouch?: boolean;
};

/**
 * Update Formik form values and auto-touch field so validation isn't out-of-sync.
 * This circumvents out-of-sync bug present in Formik package:
 *  - https://github.com/jaredpalmer/formik/issues/2059#issuecomment-612733378
 */
export function useArmflowFormUpdater(
  setValueFunc: FormikHelpers<unknown>['setFieldValue'],
  setTouchedFunc: FormikHelpers<unknown>['setFieldTouched'],
  submitCount: FormikState<unknown>['submitCount']
): (entries: HandleFormUpdatePayload | HandleFormUpdatePayload[]) => void {
  // Determine whether a submission's been attempted by user
  const submissionAttempted = submitCount > 0;

  // Memoizing since the only dependency which should regularly trigger this should be `submissionAttempted`
  const handler = useMemo(() => {
    return (entries: HandleFormUpdatePayload | HandleFormUpdatePayload[]) => {
      const entryList = Array.isArray(entries) ? entries : [entries];
      entryList.forEach(({ formId, value, skipTouch }) => {
        setValueFunc(formId, value);
        if (!skipTouch && submissionAttempted) setTimeout(() => setTouchedFunc(formId, true));
      });
    };
  }, [setTouchedFunc, setValueFunc, submissionAttempted]);

  return handler;
}
