import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { ModalDialogProps } from './props';
import { useModalStyles } from './styles';

export const GenericModal = (props: ModalDialogProps) => {
  const classes = useModalStyles(props);
  const { modalInfo, onModalClose } = props;

  const { id, title: titleText, customComponent = { component: null, onClose: () => {} } } = modalInfo;
  const { component, onClose } = customComponent;

  const handleClose = () => {
    if (onClose) onClose();
    if (onModalClose) onModalClose(id);
  };

  return (
    <Dialog
      // open={show_dialogue}
      open={true}
      className={classes.error}
      onClose={handleClose}
    >
      {/* Title */}
      <DialogTitle>{titleText === null ? 'Error' : titleText}</DialogTitle>

      {/* Text */}
      <DialogContent>{component}</DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
