import * as yup from 'yup';

import { RunBundlerFormFields } from 'models/RunBundlerForm';

const formIds: Record<keyof RunBundlerFormFields, keyof RunBundlerFormFields> = {
  processName: 'processName',
  processType: 'processType',
  locationName: 'locationName',
};

const validationSchema = yup.object({
  processName: yup.string().nullable().required('PCM Process Name is a required field'),
  locationName: yup.string().nullable().required('Location is a required field'),
});

export const RunBundlerFormTokens = {
  formIds,
  validationSchema,
};
