import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ActionLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
  &:active {
    filter: brightness(80%);
  }
`;
