import React, { ReactNode, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Autocomplete, Button, CircularProgress, Stack, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { RunType } from 'utils/constants';

export enum AutocompleteProcessingIdLabel {
  ProcessingId = 'Processing ID',
  ReprocessingEWO = 'Reprocessing EWO',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  ewoButton: {
    textTransform: 'unset',
  },
  missingEwoText: {
    color: theme.palette.warning.main,
    textAlign: 'right',
  },
}));

export interface AutocompleteProcessingIdProps extends UseStyles<typeof useStyles> {
  id: string;
  value: string | null;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: string | null) => void;
  allowNewIfNonexistent?: boolean;

  label?: string;
  disabled?: boolean;
  helperText?: ReactNode;
  error?: boolean;

  processName: string | null;
  locationName: string | null;
  isReprocessing?: boolean;

  // Retrieves all processing IDs, for both Reprocessing and Manual runs. Supersedes isReprocessing prop
  fetchAllIds?: boolean;

  className?: string;
}

const AutocompleteProcessingId = observer(
  ({
    processName,
    locationName,
    isReprocessing,
    fetchAllIds,
    ...props
  }: AutocompleteProcessingIdProps): React.ReactElement | null => {
    const {
      className,
      id,
      value,
      label = isReprocessing
        ? AutocompleteProcessingIdLabel.ReprocessingEWO
        : AutocompleteProcessingIdLabel.ProcessingId,
      onChange,
      error,
      disabled = false,
      helperText,
      allowNewIfNonexistent,
    } = props;
    const [open, setOpen] = useState(false);
    const [processingIds, setProcessingIds] = useState<string[]>([]);
    const [ewoUrl, setEwoUrl] = useState<string | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles(props);
    const { processingService } = useServices();

    const freeSolo = allowNewIfNonexistent && processingIds.length === 0;
    const usingEwo = isReprocessing;

    // Get URL to EWO table.
    useEffect(() => {
      processingService.fetchEwoUrl().then((data) => {
        setEwoUrl(data.ewo_url);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Only need to run this once

    useEffect(() => {
      if (open && processName && locationName) {
        const runType: RunType = isReprocessing ? RunType.REPROCESSING : RunType.MANUAL;
        const fetchedIds: string[] = [];
        setIsLoading(true);

        processingService
          // Fetch list of IDs depending on the run type (currently only between manual/repocessing)
          .fetchPipelineIds(processName, locationName, !fetchAllIds ? runType : undefined)
          .then((pipelineIds) => {
            pipelineIds && fetchedIds.push(...pipelineIds);
          })
          .finally(() => {
            setProcessingIds(fetchedIds);
            setIsLoading(false);
          });
      }
    }, [fetchAllIds, isReprocessing, locationName, open, processName, processingService]);

    useEffect(() => {
      if (!open) {
        setProcessingIds([]);
      }
    }, [open]);

    const ewoTooltipMessage = ewoUrl ? 'Go to EWO table' : 'No EWO URL retrieved from server';
    return (
      <div className={clsx(classes.root, className)}>
        <Autocomplete
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={processingIds ?? []}
          loading={isLoading}
          disabled={disabled || isLoading}
          freeSolo={freeSolo}
          id={id}
          value={value}
          onChange={!freeSolo ? onChange : undefined}
          onInputChange={freeSolo ? onChange : undefined}
          renderInput={(params) => (
            <Stack>
              <Stack direction={'row'} gap={'0.5rem'}>
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  error={error}
                  helperText={helperText}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
                {usingEwo && (
                  <Stack height={56} justifyContent={'center'}>
                    <Tooltip title={ewoTooltipMessage} placement="right">
                      <Button
                        className={classes.ewoButton}
                        endIcon={<OpenInNewIcon />}
                        target="_blank"
                        href={ewoUrl ?? ''}
                        disabled={!ewoUrl}
                      >
                        EWOs
                      </Button>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>

              {/* Warning message in case server returns no EWO */}
              {usingEwo && ewoUrl === null && (
                <Typography variant="caption" className={classes.missingEwoText}>
                  No EWO table URL retrieved from server
                </Typography>
              )}
            </Stack>
          )}
        />
      </div>
    );
  }
);

export default AutocompleteProcessingId;
