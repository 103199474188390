/** @jsxImportSource @emotion/react */
import React from 'react';

import { observer } from 'mobx-react';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { CapitalizedButton } from 'components/styles/CapitalizedButton';
import { UseStyles } from 'styles/utilityTypes';
import { FileDownloadTypes } from 'utils/constants';
import { LogfileParameters, PipelineIdentifier } from 'utils/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export interface ViewComlogButtonProps extends UseStyles<typeof useStyles> {
  pipelineId: PipelineIdentifier;
  logfileParams: LogfileParameters;
  className?: string;
}

const ViewComlogButton = observer((props: ViewComlogButtonProps): React.ReactElement | null => {
  const { className, pipelineId, logfileParams } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const { processingService } = useServices();

  const handleClickComlogButton = () => {
    processingService.fetchLogFile(pipelineId, logfileParams, FileDownloadTypes.COMLOG);
  };

  return (
    <CapitalizedButton
      className={clsx(classes.root, className)}
      css={{ marginLeft: theme.spacing(2) }}
      startIcon={<ArticleOutlinedIcon />}
      onClick={handleClickComlogButton}
    >
      View Comlog
    </CapitalizedButton>
  );
});

export default ViewComlogButton;
