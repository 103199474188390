import React from 'react';

import { observer } from 'mobx-react';

import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { ArchivedState, FileState, ReleaseState } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStyles = makeStyles({
  root: {},
  bullseyeTopTimeline: {
    height: '100%',
    position: 'absolute',
    'z-index': 1,
    fontSize: '19px', // TODO: icon font sizes need to be computed relative to the size of the zoom
  },
  bullseyeTopLegend: {
    position: 'absolute',
    'z-index': 1,
    fontSize: '13px', // TODO: icon font sizes need to be computed relative to the size of the zoom
    marginTop: '5px',
  },
  bullseyeBottomTimeline: {
    height: '100%',
    position: 'absolute',
    fontSize: '36px',
  },
  bullseyeBottomLegend: {
    position: 'absolute',
  },
  wrapperTimeline: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '0',
  },
  wrapperLegend: {
    display: 'flex',
    justifyContent: 'center',
    width: '0',
    padding: '0 0.7em',
  },
});

export interface DataStatusIconProps extends UseStyles<typeof useStyles> {
  archive_state?: ArchivedState;
  file_state?: FileState;
  release_state?: ReleaseState;
  timeline: boolean; // What does 'timeline' indicate?
  className?: string;
}

const DataStatusIcon = observer((props: DataStatusIconProps): React.ReactElement | null => {
  const { className, file_state, release_state, archive_state, timeline } = props;
  const { actionBarService } = useServices();
  const classes = useStyles(props);
  const theme = useTheme();

  const { visualToggles } = actionBarService;
  const blueToggle = visualToggles['blue-success'].active;

  let icon: React.ReactNode;
  if (file_state !== undefined) {
    if (file_state === FileState.GOOD) {
      // Green circle
      icon = (
        <CircleIcon
          className={clsx(classes.root, className)}
          sx={{
            color: blueToggle ? theme.palette.qualityControlPassed.light : theme.palette.qualityControlPassed.main,
          }}
        />
      );
    } else if (file_state === FileState.SOME_BAD) {
      // Bullseye (stack two circles)
      const topClass = timeline ? classes.bullseyeTopTimeline : classes.bullseyeTopLegend;
      const bottomClass = timeline ? classes.bullseyeBottomTimeline : classes.bullseyeBottomLegend;
      const wrapperClass = timeline ? classes.wrapperTimeline : classes.wrapperLegend;
      icon = (
        <span className={wrapperClass}>
          <CircleIcon
            className={topClass}
            sx={{
              color: blueToggle ? theme.palette.qualityControlPassed.light : theme.palette.qualityControlPassed.main,
            }}
          />
          <CircleIcon className={bottomClass} sx={{ color: theme.palette.qualityControlFailed.main }} />
        </span>
      );
    } else if (file_state === FileState.BAD) {
      // Red circle
      icon = (
        <CircleIcon className={clsx(classes.root, className)} sx={{ color: theme.palette.qualityControlFailed.main }} />
      );
    } else if (file_state === FileState.MISSING) {
      // Red circle
      icon = <CircleIcon className={clsx(classes.root, className)} sx={{ color: theme.palette.filesMissing.main }} />;
    } else if (file_state === FileState.SOME_MISSING) {
      // Bullseye (stack two circles)
      const topClass = timeline ? classes.bullseyeTopTimeline : classes.bullseyeTopLegend;
      const bottomClass = timeline ? classes.bullseyeBottomTimeline : classes.bullseyeBottomLegend;
      const wrapperClass = timeline ? classes.wrapperTimeline : classes.wrapperLegend;
      icon = (
        <span className={wrapperClass}>
          <CircleIcon
            className={topClass}
            sx={{
              color: blueToggle ? theme.palette.qualityControlPassed.light : theme.palette.qualityControlPassed.main,
            }}
          />
          <CircleIcon className={bottomClass} sx={{ color: theme.palette.filesMissing.main }} />
        </span>
      );
    }
  } else if (archive_state !== undefined) {
    if (archive_state === ArchivedState.ARCHIVED) {
      // White check mark
      icon = <CheckIcon className={clsx(classes.root, className)} />;
    } else if (archive_state === ArchivedState.SOME_ARCHIVED) {
      // Black check mark
      icon = <CheckIcon className={clsx(classes.root, className)} sx={{ color: '#121212' }} />;
    }
  } else if (release_state !== undefined) {
    if (release_state === ReleaseState.RELEASING) {
      // White ellipses
      icon = <MoreHorizIcon className={clsx(classes.root, className)} />;
    }
  }

  return <>{icon}</>;
});

export default DataStatusIcon;
