import React from 'react';

import { observer } from 'mobx-react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { ConfirmationFormItemList } from 'utils/types';

import { ArmflowConfirmDialog } from '../common/ArmflowConfirmDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  runningText: {
    textAlign: 'center',
    padding: `${theme.spacing(2)} 0px`,
  },
}));

export interface RunBundlerFormConfirmProps extends UseStyles<typeof useStyles>, ArmflowConfirmDialog {
  className?: string;
}

const RunBundlerFormConfirm = observer((props: RunBundlerFormConfirmProps): React.ReactElement | null => {
  const { className, open, onCancel, onClose, onSubmit } = props;
  const classes = useStyles(props);
  const { actionBarService, processingService } = useServices();

  const { disableConfirmButton } = actionBarService;
  const { runBundlerDialogForm } = processingService;
  const { processName, locationName } = runBundlerDialogForm;

  const formItems: ConfirmationFormItemList = [
    { name: 'PCM Process Name', value: processName },
    { name: 'Location', value: locationName },
  ];

  return (
    <div className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown') {
            actionBarService.setDisableConfirmButton(false);
            !!onClose && onClose(event, reason);
          }
        }}
      >
        <DialogTitle>Confirm Run Bundler</DialogTitle>

        <DialogContent dividers>
          <Grid container width={450} justifyContent={'space-between'} rowGap={'1rem'}>
            {formItems.map((entry) => (
              <Grid container xs={12} justifyContent={'inherit'} key={entry.name}>
                <Typography>{entry.name}:</Typography>
                <Typography>{entry.value}</Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        {/* Action buttons */}
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onSubmit} disabled={disableConfirmButton} color="primary" variant="outlined">
            Run Bundler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default RunBundlerFormConfirm;
