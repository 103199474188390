import { action, computed, makeObservable, observable } from 'mobx';

import { DateTime } from 'luxon';

import { ProcessType } from 'utils/constants';
import { DateRange } from 'utils/types';
import { EncodeBase64 } from 'utils/utils';

import { ArmflowForm } from './types';

export type PruneDataFormJSON = {
  process_name: string | null;
  process_type: ProcessType | null;
  location_name: string | null;
  processing_id: string | null;
  prune_all: boolean | null;
  start_date: string | null | undefined;
  end_date: string | null | undefined;
};

export type PruneDataFormFields = {
  processName: string | null;
  processType: ProcessType | null;
  locationName: string | null;
  processingId: string | null;
  pruneAll: boolean;
  startDate: DateTime | null;
  endDate: DateTime | null;
};

export const PRUNE_DATA_EMPTY_FORM: PruneDataFormFields = {
  processName: null,
  processType: null,
  locationName: null,
  processingId: null,
  pruneAll: false,
  startDate: null,
  endDate: null,
};

export default class PruneDataForm implements ArmflowForm<PruneDataFormFields, PruneDataFormJSON> {
  processName: PruneDataFormFields['processName'];
  processType: PruneDataFormFields['processType'];
  locationName: PruneDataFormFields['locationName'];
  processingId: PruneDataFormFields['processingId'];
  pruneAll: PruneDataFormFields['pruneAll'];
  startDate: PruneDataFormFields['startDate'];
  endDate: PruneDataFormFields['endDate'];

  validArchiveStartDate: boolean;
  validArchiveEndDate: boolean;

  constructor(autofillData?: PruneDataFormFields) {
    this.processName = autofillData ? autofillData.processName : '';
    this.processType = autofillData ? autofillData.processType : null;
    this.locationName = autofillData ? autofillData.locationName : '';
    this.processingId = autofillData ? autofillData.processingId : '';
    this.pruneAll = autofillData ? autofillData.pruneAll : false;
    this.startDate = autofillData ? autofillData.startDate : null;
    this.endDate = autofillData ? autofillData.endDate : null;

    this.validArchiveStartDate = true;
    this.validArchiveEndDate = true;

    makeObservable(this, {
      processName: observable,
      processType: observable,
      locationName: observable,
      processingId: observable,
      pruneAll: observable,
      startDate: observable,
      endDate: observable,
      validArchiveStartDate: observable,
      validArchiveEndDate: observable,

      query: computed,
      toHistoryString: computed,

      fromJSON: action,
      updateProcessName: action,
      updateProcessType: action,
      updateLocation: action,
      updateDate: action,
      setDateValidity: action,
      updateForm: action,
      reset: action,
    });
  }

  get query(): string {
    return EncodeBase64(JSON.stringify(this));
  }

  // toHistoryString() {
  get toHistoryString(): string {
    const { processName, locationName, startDate, endDate } = this;
    return [processName, locationName, startDate, endDate].join(' ');
  }

  updateProcessName(name: string | null) {
    this.processName = name;
  }

  updateProcessType(processType: ProcessType | null) {
    this.processType = processType;
  }

  updateLocation(location: string | null) {
    this.locationName = location;
  }

  updateProcessingId(processingId: string | null) {
    this.processingId = processingId;
  }

  togglePruneAll(prune_all?: boolean) {
    if (prune_all !== undefined) this.pruneAll = prune_all;
    else this.pruneAll = !this.pruneAll;
  }

  updateDate(dateRange: DateRange) {
    this.startDate = dateRange.begin;
    this.endDate = dateRange.end;
  }

  setDateValidity(datesValid: [boolean, boolean]) {
    const [startValid, endValid] = datesValid;
    this.validArchiveStartDate = startValid;
    this.validArchiveEndDate = endValid;
  }

  updateForm(formValues: Partial<PruneDataFormFields>) {
    const { processName, processType, locationName, processingId, pruneAll, startDate, endDate } = formValues;

    if (processName !== undefined) this.processName = processName;
    if (processType !== undefined) this.processType = processType;
    if (locationName !== undefined) this.locationName = locationName;
    if (processingId !== undefined) this.processingId = processingId;
    if (pruneAll !== undefined) this.pruneAll = pruneAll;
    if (startDate !== undefined) this.startDate = startDate;
    if (endDate !== undefined) this.endDate = endDate;
  }

  reset() {
    this.processName = '';
    this.processType = null;
    this.locationName = '';
    this.processingId = '';
    this.pruneAll = false;
    this.startDate = null;
    this.endDate = null;

    this.validArchiveStartDate = true;
    this.validArchiveEndDate = true;
  }

  toJSON(): PruneDataFormJSON {
    let json_to_stringify: PruneDataFormJSON = {
      process_name: null,
      process_type: null,
      location_name: null,
      processing_id: null,
      prune_all: false,
      start_date: null,
      end_date: null,
    };

    json_to_stringify.process_name = this.processName;
    json_to_stringify.process_type = this.processType;
    json_to_stringify.location_name = this.locationName;
    json_to_stringify.processing_id = this.processingId;
    json_to_stringify.prune_all = this.pruneAll;
    json_to_stringify.start_date = this.startDate?.toISODate();
    json_to_stringify.end_date = this.endDate?.toISODate();

    return json_to_stringify;
  }

  fromJSON(formValues: Partial<PruneDataFormJSON>) {
    const convertedFields: PruneDataFormFields = PruneDataForm.fromJSON(formValues);
    this.updateForm(convertedFields);
  }

  /** Created static method so components don't need to construct class instance
   *  @todo Could probably just move this outside of class
   */
  static fromJSON(formValues: Partial<PruneDataFormJSON>): PruneDataFormFields {
    const convertedFields: PruneDataFormFields = {
      processName: formValues.process_name ?? null,
      processType: formValues.process_type ?? null,
      locationName: formValues.location_name ?? null,
      processingId: formValues.processing_id ?? null,
      pruneAll: formValues.prune_all ?? false,
      startDate: formValues.start_date ? DateTime.fromISO(formValues.start_date) : null,
      endDate: formValues.end_date ? DateTime.fromISO(formValues.end_date) : null,
    };
    return convertedFields;
  }
}
