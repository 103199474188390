import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    height: '100%',
  },
}));

export const NoContentPlaceholder = () => {
  const classes = useStyles();
  return <code className={classes.placeholder}> No data found </code>;
};
