import React from 'react';

import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface LoadingSpinnerProps extends CircularProgressProps {
  spacing?: number;
  className?: string;
}

export const LoadingSpinner = (props: LoadingSpinnerProps): React.ReactElement | null => {
  const { className, spacing, ...circularProgressProps } = props;

  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <Box className={clsx(classes.root, className)} textAlign={'center'} margin={spacing && theme.spacing(spacing)}>
      <CircularProgress {...circularProgressProps} />
    </Box>
  );
};
