import React from 'react';

import { observer } from 'mobx-react';

import { css } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled, Tab } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import FilterTab from './filters/tabs/FilterTab';
import LegendTab from './filters/tabs/LegendTab';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // height: `calc(100vh - ${64 * 2 + 56}px)`,
      height: `calc(100vh - ${64 + 96 + 56}px)`,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      overflow: 'auto',
    },
    formControl: {
      // padding: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      minWidth: 180,
      fontFamily: 'Raleway',
      width: '100%',
      // paddingRight: '70px',
    },
    tabPanel: {
      padding: 0,
    },
  });

// const StyledTabs = styled(Tabs)(
const StyledTabs = styled(TabList)(
  (theme) => css`
    padding-bottom: 1rem;
    margin: 0 1rem;
    /* height: 4rem; */
    /* background-color: green; */
  `
);

const TabNames = {
  Filters: 'filters',
  Legend: 'legend',
};

export interface FiltersViewProps extends WithServicesProps {
  classes: {
    root: string;
    formControl: string;
    tabPanel: string;
  };
}

interface FiltersViewState {
  currentTab: string;
}

class FiltersView extends React.Component<FiltersViewProps, FiltersViewState> {
  constructor(props: FiltersViewProps) {
    super(props);
    this.state = {
      currentTab: TabNames.Filters,
    };
  }

  render() {
    const { classes } = this.props;
    const { currentTab } = this.state;
    return (
      <div className={classes.root}>
        <TabContext value={currentTab}>
          <StyledTabs value={currentTab} onChange={(_, value) => this.setState({ currentTab: value })}>
            <Tab label="Filters" value={TabNames.Filters} />
            <Tab label="Legend" value={TabNames.Legend} />
          </StyledTabs>
          <TabPanel value={TabNames.Filters} className={classes.tabPanel}>
            <FilterTab />
          </TabPanel>
          <TabPanel value={TabNames.Legend} className={classes.tabPanel}>
            <LegendTab />
          </TabPanel>
        </TabContext>
      </div>
    );
  }
}

export default withStyles(styles)(withServices(observer(FiltersView)));
