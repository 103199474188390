import React, { useCallback, useEffect, useMemo } from 'react';

import { observer } from 'mobx-react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { API, AppState, ARMFlowForms, ProcessingServiceModalIds } from 'utils/constants';

import InfoViewer from './dialogs/InfoViewer';
import LogViewer from './dialogs/LogViewer';
import { ApplicationErrorModal } from './dialogs/modals/ApplicationErrorModal';
import { GeneralProcessErrorsModal } from './dialogs/modals/GeneralProcessErrorsModal';
import { GenericModal } from './dialogs/modals/GenericModal';
import { PipelineErrorHistoryModal } from './dialogs/modals/PipelineErrorHistoryModal';
import { ProcessStatesModal } from './dialogs/modals/ProcessStatesModal';
import { ProgressModal } from './dialogs/modals/ProgressModal';
import { PruneDataFormDialog } from './dialogs/pruneDataForm/PruneDataFormDialog';
import { ReleaseDataFormDialog } from './dialogs/releaseDataForm/ReleaseDataFormDialog';
import { RunBundlerFormDialog } from './dialogs/runBundlerForm/RunBundlerFormDialog';
import { RunProcessFormDialog } from './dialogs/runProcessForm/RunProcessFormDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export interface ModalsViewFuncProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const ModalsView = observer((props: ModalsViewFuncProps): React.ReactElement | null => {
  const { className } = props;

  const classes = useStyles(props);
  const { actionBarService, modalsService, processingService } = useServices();

  const { topModal } = modalsService;
  const { status } = processingService;
  const { openForms, openViewers } = actionBarService;
  const { logViewer: logViewerOpen, infoViewer: infoViewerOpen } = openViewers;

  const checkProcessingServiceStatus = useCallback(() => {
    const { state, message } = status;
    switch (state) {
      case AppState.LOADING: {
        modalsService.pushModal({
          id: ProcessingServiceModalIds.LOADING,
          title: message,
          message: message,
          state: state,
        });
        break;
      }
      case AppState.IDLE: {
        // Remove loading modal from stack since status is idle
        modalsService.popModal(ProcessingServiceModalIds.LOADING);
        break;
      }
      case AppState.ERROR: {
        modalsService.pushModal({
          id: ProcessingServiceModalIds.ERROR,
          title: 'Processing Service Error',
          message: message,
          state: state,
        });
        break;
      }
      default:
        break;
    }
  }, [modalsService, status]);

  const handleCloseButton = useCallback(
    (modal_id: string) => {
      // Revert processing service status to idle if associated ID is found
      if (Object.values(ProcessingServiceModalIds).includes(modal_id as ProcessingServiceModalIds)) {
        processingService.updateStatus(AppState.IDLE, null);
      }

      // Remove modal from service stack
      modalsService.popModal(modal_id);
    },
    [modalsService, processingService]
  );

  /** Check current status of ProcessingService, overriding currently rendered modals accordingly */
  useEffect(() => {
    checkProcessingServiceStatus();
  }, [checkProcessingServiceStatus]);

  const modalComponent = useMemo(() => {
    if (!topModal) {
      return null;
    }

    const { state: modalState, id: modalId } = topModal;
    switch (modalState) {
      case AppState.LOADING:
        return <ProgressModal modalInfo={topModal} />;
      case AppState.ERROR:
        return <ApplicationErrorModal modalInfo={topModal} onModalClose={() => handleCloseButton(modalId)} />;
      case AppState.PIPELINE_ERROR:
        return (
          <PipelineErrorHistoryModal
            modalInfo={topModal}
            onModalClose={() => handleCloseButton(modalId)}
            hideTitleInc
          />
        );
      case AppState.SYSTEM_ERRORS:
        return <GeneralProcessErrorsModal modalInfo={topModal} onModalClose={() => handleCloseButton(modalId)} />;
      case AppState.PIPELINE_ERROR_HISTORY:
        return (
          <PipelineErrorHistoryModal
            modalInfo={topModal}
            onModalClose={() => handleCloseButton(modalId)}
            showTabs
            showErrorHistory
          />
        );
      case AppState.PROCESS_STATES:
        return <ProcessStatesModal modalInfo={topModal} onModalClose={() => handleCloseButton(modalId)} />;
      case AppState.GENERIC:
        return <GenericModal modalInfo={topModal} onModalClose={() => handleCloseButton(modalId)} />;
      default:
        return null;
    }
  }, [handleCloseButton, topModal]);

  return (
    <div className={clsx(classes.root, className)}>
      {/* Modal component based on item picked up from the stack */}
      {modalComponent}

      {/* TODO: attempting to open realtime-log after process completed doesn't show good feedback */}
      {infoViewerOpen && <InfoViewer />}

      {/* Modals handled/living outside the modal stack */}
      {logViewerOpen && <LogViewer />}

      {/* Forms */}
      {openForms[ARMFlowForms.RUN_PROCESS] && <RunProcessFormDialog validatorPath={API.VALIDATE_RUN_PROCESS} />}
      {openForms[ARMFlowForms.RELEASE_DATA] && <ReleaseDataFormDialog validatorPath={API.VALIDATE_RELEASE_DATA} />}
      {openForms[ARMFlowForms.PRUNE_DATA] && <PruneDataFormDialog validatorPath={API.VALIDATE_PRUNE_DATA} />}
      {openForms[ARMFlowForms.RUN_BUNDLER] && <RunBundlerFormDialog />}
    </div>
  );
});

export default ModalsView;
