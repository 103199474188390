import React from 'react';

import { observer } from 'mobx-react';

import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import ArmFlowLogoPng2 from '../assets/images/Logo2-transparent.svg';
import AccountMenu from './menus/AccountMenu';
import ExternalLinkMenu from './menus/ExternalLinkMenu';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    titleGroup: {
      flexGrow: 1,
      fontFamily: 'Montserrat',
      fontSize: 32,
      fontWeight: 600,
      position: 'relative',
      zIndex: 100,
      marginLeft: '-5rem',
      backgroundColor: '#272727',
    },
    titleColor1: {
      color: 'white',
      textShadow: '-1px 1px 5px #121212, 1px 1px 5px #121212',
    },
    titleColor2: {
      color: '#8e8e8e',
    },
    logo: {
      display: 'flex',
      height: 80,
      paddingRight: theme.spacing(1.5),
    },
    navButton: {
      borderRadius: 10,
      paddingLeft: 20,
      paddingRight: 20,
      fontFamily: 'Raleway',
    },
  });

export interface IAppProps extends WithServicesProps {
  classes: {
    appBar: string;
    titleGroup: string;
    titleColor1: string;
    titleColor2: string;
    logo: string;
    navButton: string;
  };
}

class Navbar extends React.Component<IAppProps> {
  render() {
    const { classes } = this.props;
    return (
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar>
          {/* Logo & Title */}
          <img className={classes.logo} alt="armflow logo" src={ArmFlowLogoPng2} />
          <Typography variant="h4" className={classes.titleGroup}>
            <b className={classes.titleColor1}>ARM</b>
            <b className={classes.titleColor2}>Flow</b> Dashboard
          </Typography>

          <AccountMenu />
          <ExternalLinkMenu />
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withServices(observer(Navbar)));
