import { css } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';

import { FILTER_PANEL_COLOR } from 'utils/constants';

export const StyledAccordion = styled(Accordion)(({ theme }) => css``);

export const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => css`
    /* background-color: ${FILTER_PANEL_COLOR}; */
    flex-direction: row-reverse;
    gap: 0.5rem;
    padding: 0 0.5rem;
  `
);
export const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => css`
    background-color: ${FILTER_PANEL_COLOR};
  `
);
