import TimelinesDate from '../TimelinesDate';
import { TimelineGridCellProps } from './TimelineGridCell';

export const TimelineScrollSyncHeader = ({
  gridCellProps,
  hoveredColumnIndex,
  onMouseEnter,
}: TimelineGridCellProps) => {
  const { key, columnIndex, style } = gridCellProps;
  if (columnIndex < 1) return null;
  return (
    <TimelinesDate
      gridCellKey={key}
      columnIndex={columnIndex}
      hoveredColumnIndex={hoveredColumnIndex}
      style={style}
      onMouseEnter={onMouseEnter}
    />
  );
};
