import React, { useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Button,
  css,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  IconButtonProps,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
  Theme,
  Tooltip,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-ambiance';
import 'ace-builds/src-noconflict/theme-github';
import clsx from 'clsx';
import AceEditor from 'react-ace';

import { useServices } from 'services';

import { useInterval } from 'hooks/useInterval';
import { UseStyles } from 'styles/utilityTypes';
import { FileDownloadTypes, LiveLogDelay, LogTypeLabel } from 'utils/constants';
import { FetchLogFileOptions } from 'utils/types';
import { generatePipelineStandardModalTitle } from 'utils/utils';

import { ModalTitle } from './modals/ModalTitle';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  themeSelect: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  themeSelectInput: {
    width: 125,
  },
  dialogActions: {
    padding: '0 1.5rem',
    paddingBottom: theme.spacing(1),
  },
}));

const ThemeLabelId = 'viewer-theme';
enum LogViewerThemes {
  // Dark = 'dracula',
  Dark = 'ambiance',
  Light = 'github',
}

export interface LogViewerFuncProps extends UseStyles<typeof useStyles> {
  title?: string | null;
  className?: string;
}

const LogViewer = observer((props: LogViewerFuncProps): React.ReactElement | null => {
  const { className, title } = props;
  const aceRef = useRef<AceEditor | null>(null);
  const [aceEditorTheme, setAceEditorTheme] = useState(LogViewerThemes.Dark);
  const [liveLogToggle, setLiveLogToggle] = useState(true);

  const classes = useStyles(props);
  const theme = useTheme();
  const { actionBarService, processingService } = useServices();
  const { logViewerData } = processingService;
  const { pipelineId, fileType, date, processingId } = logViewerData;
  const pipelineModalTitle = generatePipelineStandardModalTitle(pipelineId);
  const open = actionBarService.openViewers.logViewer;

  const showLiveLogToggle = fileType === FileDownloadTypes.REALTIME_PROCESS_LOG;

  const handleGoToFileEnd = useCallback(() => {
    if (aceRef.current) {
      const editor = aceRef.current.editor;
      const numLines = logViewerData.text.split('\n').length;
      editor.gotoLine(numLines, 0, true);
      editor.navigateLineEnd();
    }
  }, [logViewerData.text]);

  const findDateLine = useCallback((reversedLog, regex, dateString) => {
    let targetLine = -1;
    for (let i = 0; i < reversedLog.length; i++) {
      const line = reversedLog[i];
      if (regex.test(line) && line.includes(dateString)) {
        targetLine = i;
        break;
      }
    }

    return targetLine;
  }, []);

  useInterval(() => {
    if (showLiveLogToggle && liveLogToggle && date?.isValid) {
      const options: FetchLogFileOptions = {
        background: true,
        onError: () => liveLogToggle && setLiveLogToggle(false),
      };

      processingService
        .fetchLogFile(pipelineId, { date: date.toISODate(), processing_id: processingId }, fileType, options)
        .then(() => handleGoToFileEnd());
    }
  }, LiveLogDelay);

  useEffect(() => {
    setTimeout(() => goToDate(), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only run on first mount

  const goToDate = () => {
    if (!date?.isValid || !aceRef.current) {
      return;
    }

    const dateString = date.toISODate();
    let regex: RegExp = /.*/;
    if (fileType === FileDownloadTypes.PROCESS_LOG) {
      regex = /Storing:\s+(\S+)\s+(\S+)\s+(\S+)/;
    } else if (fileType === FileDownloadTypes.COMLOG) {
      regex = /Start Time:/;
    }
    const editor = aceRef.current.editor;
    const logLines = logViewerData.text.split('\n');
    const reversedLog = logLines.reverse();
    let targetLine: number = findDateLine(reversedLog, regex, dateString);

    //handles the case where storing has been skipped for a specific date
    if (targetLine === -1) {
      regex = /Processing data:\s/;
      targetLine = findDateLine(reversedLog, regex, dateString);
    }

    const lineNumber = logLines.length - targetLine;
    editor.gotoLine(lineNumber, 0, true);
  };

  const handleClose = useCallback(() => {
    actionBarService.setLogViewerOpen(false);
  }, [actionBarService]);

  const logViewerTitle = fileType ? LogTypeLabel[fileType] : 'Log Viewer';
  const logViewerSubtitle = title ? `${title}` : `${pipelineModalTitle} ${date ? `- ${date.toISODate()}` : ''}`;

  return (
    <div className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        maxWidth="xl"
        fullWidth
        // fullScreen
      >
        <ModalTitle modalTitle={logViewerTitle} modalSubtitle={logViewerSubtitle} />

        <DialogContent>
          <div className={classes.themeSelect}>
            <InputLabel id={ThemeLabelId}>Theme</InputLabel>
            <span>
              <Select
                size="small"
                labelId={ThemeLabelId}
                value={aceEditorTheme}
                className={classes.themeSelectInput}
                onChange={(e) => setAceEditorTheme(e.target.value as LogViewerThemes)}
              >
                <MenuItem value={LogViewerThemes.Light}>Light</MenuItem>
                <MenuItem value={LogViewerThemes.Dark}>Dark</MenuItem>
              </Select>
            </span>
          </div>

          <div style={{ position: 'relative' }}>
            <AceEditor
              ref={aceRef}
              readOnly
              showPrintMargin={false}
              mode="text"
              theme={aceEditorTheme}
              fontSize={theme.typography.fontSize}
              width="100%"
              value={logViewerData.text}
              // navigateToFileEnd
            />
            <AceScrollToEndButton onClick={() => handleGoToFileEnd()} />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
          style={{ justifyContent: showLiveLogToggle ? 'space-between' : 'right' }}
        >
          {showLiveLogToggle && (
            <FormControlLabel
              control={<Switch onChange={(e) => setLiveLogToggle(e.target.checked)} checked={liveLogToggle} />}
              label="Realtime Monitoring"
              title={`Realtime Monitoring (${LiveLogDelay / 1000}s polling rate)`}
            />
          )}
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default LogViewer;

const AceScrollToEndButton = styled((props: IconButtonProps) => (
  <Tooltip title="Scroll to end" placement="left">
    <IconButton {...props}>
      <ArrowDownwardIcon />
    </IconButton>
  </Tooltip>
))(
  () => css`
    position: absolute;
    right: 40px;
    bottom: 20px;
  `
);
