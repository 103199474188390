import TimelinesLabel from '../TimelinesLabel';
import { TimelineGridCellProps } from './TimelineGridCell';

export const TimelineScrollSyncLabel = ({ gridCellProps, hoveredRowIndex, onMouseEnter }: TimelineGridCellProps) => {
  const { key, rowIndex, style } = gridCellProps;
  return (
    <TimelinesLabel
      gridCellKey={key}
      rowIndex={rowIndex}
      hoveredRowIndex={hoveredRowIndex}
      style={style}
      onMouseEnter={onMouseEnter}
    />
  );
};
