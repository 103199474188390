/** @jsxImportSource @emotion/react */
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { IconButton, Tooltip, useTheme } from '@mui/material';

import { useServices } from 'services';

export const SystemErrorButton = () => {
  const theme = useTheme();
  const { processingService } = useServices();

  const handleShowSystemErrors = () => {
    processingService.fetchSystemErrors({ isRecent: true });
  };

  return (
    <Tooltip title="System errors encountered.  Click to view." arrow placement="top" disableInteractive={false}>
      <IconButton css={{ color: theme.palette.error.main }} onClick={handleShowSystemErrors}>
        <CrisisAlertIcon />
      </IconButton>
    </Tooltip>
  );
};
