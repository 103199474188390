import { computed, makeObservable, observable } from 'mobx';

import _ from 'lodash';
import { DateTime } from 'luxon';

import {
  ArchivedState,
  FileState,
  PipelineErrorLevel,
  ProcessStatus,
  ProcessType,
  ReleaseState,
  RunMode,
  SortOrder,
} from 'utils/constants';

// Consider renaming type name
export type Run = {
  // Currently expected parameters from run data
  process_status?: number; // bits could match multiple masks
  file_state?: FileState; // integer representing the quality of files produced by the pipeline
  archive_state?: ArchivedState; // integer representing the archive state of all good files  produced by the pipeline
  release_state?: ReleaseState; // integer representing if the pipeline has any data that are currently being released
};

type RunCompressed = [ProcessStatus, FileState, ArchivedState, ReleaseState];

export type PipelineTimeline = {
  process: string; // process name
  type: ProcessType; // process type
  site: string; // location
  facility: string;
  mode: RunMode | null;
  has_conf: boolean;
  days: Run[];
  running?: string[]; // strings represent processing IDs (format: '[RUN_TYPE]: [name]')
  error?: boolean; // pipeline error (show triangle icon)
  error_level?: PipelineErrorLevel;
};

export type TimelineJSON = Omit<TimelineProps, 'start_date' | 'end_date'> & {
  start_date: string;
  end_date: string;
};

export type TimelineHash = {
  hash: number;
};

interface TimelineProps {
  start_date: DateTime;
  end_date: DateTime;
  total_pipelines: number;
  timelines: PipelineTimeline[];
}
export default class Timeline implements TimelineProps {
  @observable start_date;
  @observable end_date;
  @observable total_pipelines;
  @observable timelines;

  constructor(
    start_date: string | DateTime,
    end_date: string | DateTime,
    count: number,
    timelines: PipelineTimeline[]
  ) {
    makeObservable(this);
    // TODO: think of a safer way to handle string here, or raise an exception if it doesn't fit ISO format
    this.start_date = typeof start_date === 'string' ? DateTime.fromISO(start_date.slice(0, 10)) : start_date;
    this.end_date = typeof end_date === 'string' ? DateTime.fromISO(end_date.slice(0, 10)) : end_date;
    // this.timelines = timelines ? timelines : [];

    // expand days attribute in timelines to match type
    let all_timelines: PipelineTimeline[] = new Array(timelines.length);
    if (timelines) {
      timelines.forEach((pipeline, i) => {
        all_timelines[i] = {
          process: pipeline.process,
          type: pipeline.type,
          site: pipeline.site,
          facility: pipeline.facility,
          mode: pipeline.mode,
          has_conf: pipeline.has_conf,
          running: pipeline.running,
          days: this._convertRun(pipeline.days as RunCompressed[]),
        };

        if (pipeline.error) all_timelines[i].error = pipeline.error;
        if (pipeline.error_level) all_timelines[i].error_level = pipeline.error_level;
      });
    }
    this.timelines = all_timelines;
    this.total_pipelines = count;
  }

  @computed
  get totalDays() {
    const dateDiff = this.end_date.diff(this.start_date, 'day');
    return dateDiff.days;
    /**
     * Ceiling is used because non-whole numbers are occasionally computed outputs non-whole numbers, causing offset exception
     * Ticket: https://code.arm.gov/docker/airflow/airflow-dashboard/-/issues/11
     * Fix found here: https://github.com/bvaughn/react-virtualized/issues/1219#issuecomment-464623156
     */
    // if (this.start_date && this.end_date)
    //   return Math.ceil(Math.abs(this.end_date.valueOf() - this.start_date.valueOf()) / (1000 * 3600 * 24));
    // return 0;
  }

  _convertRun(run_data: (RunCompressed | null)[]): Run[] {
    let converted_runs: Run[] = new Array(run_data.length);

    // [process_status_flags, file_state, archive_state, releasing_state]
    run_data.forEach((run, index) => {
      let new_run: Run = {};
      if (run) {
        new_run.process_status = run[0];
        new_run.file_state = run[1];
        new_run.archive_state = run[2];
        new_run.release_state = run[3];
      }
      converted_runs[index] = new_run;
    });

    return converted_runs;
  }

  equals(other: Timeline): boolean {
    const thisJSON = JSON.parse(JSON.stringify(this));
    const otherJSON = JSON.parse(JSON.stringify(other));

    return _.isEqual(thisJSON, otherJSON);
  }

  toJSON(): TimelineJSON {
    const newJson: TimelineJSON = {
      start_date: this.start_date.toISODate(),
      end_date: this.end_date.toISODate(),
      total_pipelines: this.total_pipelines,
      timelines: sortPipelines(this.timelines, SortOrder.NAME),
    };

    return newJson;
  }
}

export const sortPipelines = (pipelines: PipelineTimeline[], sortBy: SortOrder) => {
  // Sorting functions
  const sortByName = (firstEl: PipelineTimeline, secondEl: PipelineTimeline) => {
    return firstEl.process.localeCompare(secondEl.process);
  };
  const sortByType = (firstEl: PipelineTimeline, secondEl: PipelineTimeline) => {
    return firstEl.type.localeCompare(secondEl.type);
  };
  const sortByLocation = (firstEl: PipelineTimeline, secondEl: PipelineTimeline) => {
    const location_1 = [firstEl.site, firstEl.facility].join(' ');
    const location_2 = [secondEl.site, secondEl.facility].join(' ');
    return location_1.localeCompare(location_2);
  };

  const sorters = {
    [SortOrder.NAME]: [sortByName, sortByLocation, sortByType],
    [SortOrder.TYPE]: [sortByType, sortByName, sortByLocation],
    [SortOrder.LOCATION]: [sortByLocation, sortByName, sortByType],
  };

  const pipeline_compare = (firstEl: PipelineTimeline, secondEl: PipelineTimeline) => {
    let compareVal = 0;
    for (const sorter of sorters[sortBy]) {
      compareVal = sorter(firstEl, secondEl);
      if (compareVal !== 0) break;
    }
    return compareVal;
  };

  return [...pipelines].sort(pipeline_compare);
};
