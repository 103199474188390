import React from 'react';

import { observer } from 'mobx-react';

import { Stack, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import DataStatusIcon, { DataStatusIconProps } from 'components/icons/DataStatusIcon';
import { UseStyles } from 'styles/utilityTypes';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface DataStatusLabelProps extends UseStyles<typeof useStyles> {
  className?: string;

  label: string;
  archive_state?: DataStatusIconProps['archive_state'];
  file_state?: DataStatusIconProps['file_state'];
  release_state?: DataStatusIconProps['release_state'];
  timeline?: DataStatusIconProps['timeline'];
}

const DataStatusLabel = observer((props: DataStatusLabelProps): React.ReactElement | null => {
  const { className, file_state, release_state, archive_state, label, timeline } = props;

  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <Stack className={clsx(classes.root, className)} direction="row" spacing={1} style={{ paddingBottom: '10px' }}>
      <DataStatusIcon
        file_state={file_state}
        release_state={release_state}
        archive_state={archive_state}
        timeline={!!timeline}
      />
      <Typography color={theme.palette.text.primary} style={{ lineHeight: 1 }}>
        {label}
      </Typography>
    </Stack>
  );
});

export default DataStatusLabel;
