import React from 'react';

import { observer } from 'mobx-react';

import { CircularProgress, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { API } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  loading: {
    textAlign: 'center',
  },
}));

export interface ValidationBoxProps extends UseStyles<typeof useStyles> {
  apiPath: API;
  className?: string;
}

const ValidationBox = observer((props: ValidationBoxProps): React.ReactElement | null => {
  const { className, apiPath } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const { adiApiService, actionBarService } = useServices();

  const isLoading = actionBarService.validationLoading;

  const formErrors = adiApiService.validation[apiPath];

  return (
    <Typography className={clsx(classes.root, className, isLoading && classes.loading)} variant="subtitle2">
      {isLoading ? (
        <>
          {'Validating '}
          <CircularProgress size={theme.typography.fontSize} />
        </>
      ) : (
        formErrors &&
        formErrors.errors && (
          <>
            Form errors detected. Please check and revise the respective field(s):
            <ul>
              {formErrors.errors.map((value, idx) => (
                <li key={idx}>
                  <Typography color={theme.palette.error.light}>
                    {/* <Typography> */}
                    <b>{value.field_id}</b>
                  </Typography>
                  <div style={{ whiteSpace: 'pre-line' }}>{value.message}</div>
                </li>
              ))}
            </ul>
          </>
        )
      )}
    </Typography>
  );
});

export default ValidationBox;
