import React, { ComponentPropsWithoutRef, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { Button, CircularProgress, css, Popover, Stack, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';
import _ from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { useServices } from 'services';

import { Text } from 'components/styles/Text';
import { useInterval } from 'hooks/useInterval';
import { AnchorPoints, AUTO_REFRESH_DELAY, FileDownloadTypes, ProcessType, RunType } from 'utils/constants';
import { CurrentRunsInfoResponse, PipelineIdentifier } from 'utils/types';

import StopProcessConfirm from './StopProcessConfirm';

const useStyles = makeStyles((theme) => ({
  root: {},
  loading: {
    margin: theme.spacing(2, 5),
  },
  popover: {
    padding: '0.5rem',
  },
  noProcesses: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
  },
}));

export interface RunningProcessListProps extends ComponentPropsWithoutRef<'span'> {
  pipelineId: PipelineIdentifier;
  processType: ProcessType;
  className?: string;
}

const RunningProcessesPopover = observer((props: RunningProcessListProps): React.ReactElement | null => {
  const { className, pipelineId, processType, ...spanProps } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [runningInfo, setRunningInfo] = useState<CurrentRunsInfoResponse['running'] | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const classes = useStyles(props);
  const theme = useTheme();
  const { actionBarService, processingService } = useServices();
  const { openViewers } = actionBarService;

  const { logViewer: logViewerOpen } = openViewers;
  const { process_name, site, facility } = pipelineId;
  const location = `${site} ${facility}`;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'currentRuns',
  });
  const popoverProps = bindPopover(popupState);

  const pipelineData = processingService.findPipelineInfo(pipelineId, processType);
  const runningList = pipelineData ? pipelineData.running : null;

  useInterval(() => {
    if (popupState.isOpen && !logViewerOpen && !isLoading && runningInfo?.length) {
      // Load data
      processingService.fetchRunningProcessInfo(pipelineId).then((data) => {
        setRunningInfo(data?.running ?? null);
      });
    }
  }, AUTO_REFRESH_DELAY);

  useEffect(() => {
    // On initial open, flag as loading and load data
    if (popupState.isOpen) {
      console.debug('RunningProcessesPopover: Loading processId popover data');

      // Mark as loading
      setIsLoading(true);

      // Load data
      processingService.fetchRunningProcessInfo(pipelineId).then((data) => {
        setRunningInfo(data?.running ?? null);
        setIsLoading(false); // Loading is finished
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState.isOpen]); // Only wish to execute when opened

  const handleStopButton = (processingId: string) => {
    setSelectedValue(processingId);
    setShowConfirm(true);
  };

  const handleViewLogButton = (date: string, processingId: string) => {
    const pipelineId: PipelineIdentifier = { process_name, site, facility };
    processingService.fetchLogFile(
      pipelineId,
      { date, processing_id: processingId },
      FileDownloadTypes.REALTIME_PROCESS_LOG
    );
  };

  const submitStopProcess = () => {
    // Send request to server to stop process
    if (selectedValue) {
      processingService
        .stopProcess({ process_name, site, facility }, selectedValue)
        .then(() => processingService.refresh());
    }

    // Close popover and confirm
    setShowConfirm(false);
    popoverProps.onClose();
  };

  return (
    <div className={clsx(classes.root, className)}>
      {/* Surrounding popover context */}
      <span {...spanProps} {...(runningList?.length && bindTrigger(popupState))}>
        {props.children}
      </span>

      {/* Popover */}
      <Popover {...popoverProps} {...AnchorPoints.Right} onClose={() => popoverProps.onClose()}>
        {isLoading ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Stack className={classes.popover} spacing={theme.spacing(1)}>
            {!runningInfo?.length ? (
              <div className={classes.noProcesses}>
                <Text>No running processes.</Text>
                <Text>Please refresh timeline.</Text>
              </div>
            ) : (
              <>
                <Text fontSize={18} medium textAlign={'center'}>
                  Running Processes
                  <Text fontSize={14}>
                    {location.toUpperCase()} - {process_name} - {processType}
                  </Text>
                </Text>

                <ProcessGrid>
                  {/* Headers */}
                  <Text lowercase smallCaps>
                    Run Type
                  </Text>
                  <Text lowercase smallCaps>
                    Processing Id
                  </Text>
                  <Text lowercase smallCaps>
                    Start Date
                  </Text>
                  <Text lowercase smallCaps>
                    End Date
                  </Text>
                  <Text /> {/* space filler */}
                  <Text /> {/* space filler */}
                  {runningInfo?.map(({ processing_id, run_type, start_date, end_date, has_runtime_log }, key) => (
                    <>
                      <Text fontSize={14}>{_.capitalize(RunType[run_type])}</Text>
                      <Text fontSize={14}>{processing_id}</Text>
                      <Text fontSize={14}>{start_date}</Text>
                      <Text fontSize={14}>{end_date}</Text>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleViewLogButton(start_date, processing_id)}
                        disabled={!has_runtime_log}
                      >
                        View Log
                      </Button>
                      <Button variant="outlined" size="small" onClick={() => handleStopButton(processing_id)}>
                        Stop
                      </Button>
                    </>
                  ))}
                </ProcessGrid>
              </>
            )}
          </Stack>
        )}
      </Popover>

      {/* Confirm Stop Process */}
      {showConfirm && (
        <StopProcessConfirm
          open={showConfirm}
          pipelineName={process_name}
          processingIdList={[selectedValue]}
          onCancel={() => setShowConfirm(false)}
          onClose={() => setShowConfirm(false)}
          onSubmit={() => submitStopProcess()}
        />
      )}
    </div>
  );
});

export default RunningProcessesPopover;

const ProcessGrid = styled('div')(
  ({ theme }) => css`
    display: grid;
    flex-direction: row;
    column-gap: ${theme.spacing(2)};
    row-gap: ${theme.spacing(1)};
    grid-template-columns: repeat(6, auto);

    align-items: center;

    padding: ${theme.spacing(2)};

    border-radius: 5px;
    background-color: ${grey[900]};
  `
);
