import React, { useCallback, useState } from 'react';
import { ReactNode } from 'react';

import { observer } from 'mobx-react';

import CheckIcon from '@mui/icons-material/Check';
import ShareIcon from '@mui/icons-material/Share';
import { Alert, AlertColor, IconButton, Snackbar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { createFilterURL } from 'utils/utils';

const useStyles = makeStyles(() => ({
  root: {},
}));

export enum ShareButtonTooltipText {
  SHARE = 'Share URL with current filters',
  COPIED = 'Copied URL!',
}

export type SnackbarInfo = {
  message: ReactNode;
  severity: AlertColor;
};

export interface ShareButtonProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const ShareButton = observer((props: ShareButtonProps): React.ReactElement | null => {
  const { className } = props;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [tooltipText, setTooltipText] = useState<ShareButtonTooltipText>(ShareButtonTooltipText.SHARE);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackbarInfo>();

  const classes = useStyles(props);
  const { processingService } = useServices();
  const { currentModeFilters } = processingService;
  const { timelineStartDate, timelineEndDate } = currentModeFilters;

  const handleClick = useCallback(() => {
    const urlPath = window.location.pathname !== '/' ? window.location.pathname : '';
    const currentUrl = `${window.location.origin}${urlPath}`;
    const copyUrl = createFilterURL(currentUrl, timelineStartDate, timelineEndDate, currentModeFilters.query);

    navigator.clipboard.writeText(copyUrl).then(
      () => {
        /* clipboard successfully set */
        setSnackbarInfo({
          severity: 'info',
          message: 'Copied URL with filters to clipboard!',
        });
        setOpenSnackbar(true);
        setTooltipText(ShareButtonTooltipText.COPIED);
      },
      (reason) => {
        /* clipboard write failed */
        setSnackbarInfo({ severity: 'error', message: 'Failed to copy URL to clipboard.' });
        setOpenSnackbar(true);
        console.error(reason);
      }
    );
  }, [currentModeFilters.query, timelineEndDate, timelineStartDate]);

  const handleSnackbarClose = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    setTooltipText(ShareButtonTooltipText.SHARE);
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  }, []);

  return (
    <span className={clsx(classes.root, className)}>
      <Tooltip cy-test="actionBar-share-button" title={tooltipText} arrow placement="top" onClick={handleClick}>
        <IconButton>{openSnackbar ? <CheckIcon /> : <ShareIcon />}</IconButton>
      </Tooltip>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarInfo?.severity} sx={{ width: '100%' }}>
          {snackbarInfo?.message}
        </Alert>
      </Snackbar>
    </span>
  );
});

export default ShareButton;
