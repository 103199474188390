import React from 'react';

import { observer } from 'mobx-react';

import { Stack } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import { ActionBar } from './actionBar/ActionBar';
import Drawer from './Drawer';
import FiltersView from './FiltersView';
import ModalsView from './ModalsView';
import Navbar from './Navbar';
import Timelines from './timeline/Timelines';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // height: '100vh',
      // height: '100%',
      // overflow: 'hidden',
      minWidth: 750,
    },

    // We have to override global MultiGrid style to fix a bug in the timelines view where they have "overflow: auto hidden"
    // On macs or when you zoom way out in the browser, the browser is rendering this double value as overflow instead of hidden
    '@global': {
      '.ReactVirtualized__Grid': {
        overflow: 'hidden',
      },
      'div.TopRightGrid_ScrollWrapper > div': {
        overflow: 'hidden !important',
      },
      'div.BottomLeftGrid_ScrollWrapper > div': {
        overflow: 'hidden !important',
      },
    },
  });

export interface IAppProps extends WithServicesProps {
  classes: {
    root: string;
  };
}

class App extends React.Component<IAppProps> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/* Navbar */}
        <Navbar />
        <ActionBar />

        {/* Body */}
        <Stack direction={'row'}>
          <Timelines />
          <Drawer>
            <FiltersView />
          </Drawer>
        </Stack>

        {/* Miscellaneous/feedback components */}
        <ModalsView />
      </div>
    );
  }
}

export default withStyles(styles)(withServices(observer(App)));
