import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { processNameFilterOptions } from 'components/autocomplete/filterOptions';
import { UseStyles } from 'styles/utilityTypes';
import { ProcessType } from 'utils/constants';
import { ProcessesMap } from 'utils/types';

import { ProcessNameListItem } from './ProcessNameListItem';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface AutocompleteProcessNameProps extends UseStyles<typeof useStyles> {
  id: string;
  value: string | null;

  label?: string;
  disabled?: boolean;
  onChange?: (value: string | null, processType: ProcessType | null) => void;
  helperText?: ReactNode;
  error?: boolean;
  className?: string;
  filterType?: ProcessType;
}

const AutocompleteProcessName = observer((props: AutocompleteProcessNameProps): React.ReactElement | null => {
  const {
    className,
    id,
    value,
    label = 'PCM Process Name',
    disabled = false,
    onChange,
    error,
    helperText,
    filterType,
  } = props;

  const [processesMap, setProcessesMap] = useState<ProcessesMap>({});
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles(props);
  const { processingService } = useServices();

  useEffect(() => {
    setIsLoading(true);
    processingService.fetchExistingProcesses().then((existingProcesses) => {
      // Filter away processes where `has_conf` == `false`
      const processesWithConfFile = Object.entries(existingProcesses).filter(([_, info]) => info.has_conf);
      // Filter Process Type to only display certain processes
      const processesOfType = filterType
        ? processesWithConfFile.filter(([_, info]) => info.type === filterType)
        : processesWithConfFile;
      const filteredProcesses = existingProcesses ? Object.fromEntries(processesOfType) : {};
      setProcessesMap(filteredProcesses);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processList: string[] = useMemo(() => {
    return Object.keys(processesMap);
  }, [processesMap]);

  return (
    <div className={clsx(classes.root, className)}>
      <Autocomplete
        id={id}
        autoHighlight
        loading={isLoading}
        disabled={disabled || isLoading}
        value={value}
        options={processList}
        onChange={(_, value) => onChange && onChange(value, value ? processesMap[value].type : null)}
        renderOption={(props, option) => (
          <ProcessNameListItem {...props} processName={option} processInfo={processesMap[option]} />
        )}
        filterOptions={processNameFilterOptions(processesMap)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={error}
            helperText={helperText}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
});

export default AutocompleteProcessName;
