import { ComponentPropsWithoutRef } from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  Stack,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { FormikHelpers, useFormik } from 'formik';

import { useServices } from 'services';

import AutocompleteLocation from 'components/autocomplete/AutocompleteLocation';
import AutocompleteProcessName from 'components/autocomplete/AutocompleteProcessName';
import { useArmflowFormSubmitter } from 'hooks/useArmflowFormSubmitter';
import ReleaseDataForm, { RELEASE_DATA_EMPTY_FORM, ReleaseDataFormFields } from 'models/ReleaseDataForm';
import { ARMFlowForms } from 'utils/constants';

import { ArmflowFormDialog, useArmflowFormUpdater } from '../common/ArmflowFormDialog';
import AutocompleteProcessingId from '../fields/AutocompleteProcessingId';
import FormHistory from '../fields/FormHistory';
import ValidationBox from '../fields/ValidationBox';
import ResetButton from '../form/ResetButton';
import ReleaseDataFormConfirm from './ReleaseDataFormConfirm';
import { ReleaseDataFormTokens } from './schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formControl: {
    width: 460,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export interface ReleaseDataFormDialogProps extends ArmflowFormDialog, ComponentPropsWithoutRef<'div'> {}

export const ReleaseDataFormDialog = observer(({ className, validatorPath, ...props }: ReleaseDataFormDialogProps) => {
  // const [filesToRelease, setFilesToRelease] = useState<string[] | null>(null);
  // const [numFiles, setNumFiles] = useState<number>(0);

  const classes = useStyles(props);
  const { actionBarService, adiApiService, processingService } = useServices();
  const { handleCloseForm, closeConfirmation, confirmSubmit } = useArmflowFormSubmitter({
    formType: ARMFlowForms.RELEASE_DATA,
    validatorPath,
  });

  const handleSubmit = (values: ReleaseDataFormFields, formikHelpers: FormikHelpers<ReleaseDataFormFields>) => {
    processingService.releaseDataDialogForm.updateForm({
      processName: values.processName ?? undefined,
      processType: values.processType ?? undefined,
      locationName: values.locationName ?? undefined,
      comment: values.comment ?? undefined,
      processingId: values.processingId ?? undefined,
    });

    // 1) Before opening confirmation, call validation endpoint (API.VALIDATE_RELEASE_DATA) for releasing data
    // 2) Show errors in some box below fields, before buttons
    // 3) if no errors, open confirmation dialog
    processingService
      .validateForm(validatorPath, ARMFlowForms.RELEASE_DATA, processingService.releaseDataDialogForm)
      .then(() => formikHelpers.setSubmitting(false));
  };

  const { openForms, openFormConfirms, validationLoading } = actionBarService;

  const formOpen = openForms[ARMFlowForms.RELEASE_DATA];
  const confirmationOpen = openFormConfirms[ARMFlowForms.RELEASE_DATA];

  const releaseDataDialogForm = processingService.releaseDataDialogForm;
  const { formIds, validationSchema } = ReleaseDataFormTokens;

  const initialValues = {
    processName: releaseDataDialogForm.processName,
    processType: releaseDataDialogForm.processType,
    locationName: releaseDataDialogForm.locationName,
    comment: releaseDataDialogForm.comment,
    processingId: releaseDataDialogForm.processingId,
  };

  // Initialize Formik
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });

  const { values, touched, errors, setFieldValue, setFieldTouched, submitCount, isSubmitting } = formik;

  // Get special handler function for updating our Formik forms
  const handleFormUpdate = useArmflowFormUpdater(setFieldValue, setFieldTouched, submitCount);

  return (
    <div className={clsx(classes.root, className)} {...props}>
      {/* Form confirmation dialog */}
      {confirmationOpen && (
        <ReleaseDataFormConfirm
          open={confirmationOpen}
          onClose={closeConfirmation}
          onCancel={closeConfirmation}
          onSubmit={confirmSubmit}
        />
      )}

      {/* Form dialog */}
      <Dialog
        open={confirmationOpen ? false : formOpen}
        onClose={(_, reason) => {
          if (reason === 'escapeKeyDown') {
            handleCloseForm();
          }
        }}
      >
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
            Release Data
            <Stack direction="row">
              <FormHistory
                disabled={validationLoading}
                form={ARMFlowForms.RELEASE_DATA}
                onSelected={(formJSON) => {
                  const converted = ReleaseDataForm.fromJSON(formJSON);
                  formik.setValues({ ...values, ...converted });
                }}
              />
              <ResetButton
                disabled={isSubmitting}
                onClick={() => {
                  formik.resetForm({ values: RELEASE_DATA_EMPTY_FORM });
                  releaseDataDialogForm.reset();
                  adiApiService.clearValidatorError(validatorPath);
                }}
              />
            </Stack>
          </DialogTitle>

          <DialogContent dividers>
            {/* PCM Process Name selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteProcessName
                  id={formIds.processName}
                  value={values.processName}
                  disabled={isSubmitting}
                  onChange={(value, processType) => {
                    handleFormUpdate([
                      { formId: formIds.processName, value },
                      { formId: formIds.locationName, value: null, skipTouch: true }, // Clear location field when process name changes
                      { formId: formIds.processType, value: processType },
                    ]);
                  }}
                  error={touched.processName && Boolean(errors.processName)}
                  helperText={touched.processName && errors.processName}
                />
              </FormControl>
            </FormGroup>

            {/* Location selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteLocation
                  id={formIds.locationName}
                  disabled={!values.processName || isSubmitting}
                  processName={values.processName}
                  value={values.locationName}
                  onChange={(_, value) => handleFormUpdate({ formId: formIds.locationName, value })}
                  error={touched.locationName && Boolean(errors.locationName)}
                  helperText={touched.locationName && errors.locationName}
                />
              </FormControl>
            </FormGroup>

            {/* Processing ID selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteProcessingId
                  fetchAllIds
                  processName={values.processName}
                  locationName={values.locationName}
                  id={formIds.processingId}
                  value={values.processingId}
                  disabled={isSubmitting}
                  onChange={(_, value) => handleFormUpdate({ formId: formIds.processingId, value })}
                  error={touched.processingId && Boolean(errors.processingId)}
                  helperText={touched.processingId && errors.processingId}
                />
              </FormControl>
            </FormGroup>

            {/* Comment selection */}
            <FormGroup>
              <FormControl className={classes.formControl}>
                <TextField
                  id={formIds.comment}
                  value={values.comment}
                  disabled={isSubmitting}
                  error={touched.comment && Boolean(errors.comment)}
                  helperText={touched.comment && errors.comment}
                  onChange={formik.handleChange}
                  label="Comment"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </FormGroup>

            {/* Pre-confirm form validation */}
            {/* Render <ValidationBox apiPath={API.*}/> based on validator object */}
            <ValidationBox apiPath={validatorPath} />
          </DialogContent>

          {/* Action buttons */}
          <DialogActions>
            <Button onClick={handleCloseForm}>Cancel</Button>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={formik.isSubmitting || (!formik.isValid && formik.submitCount > 0)}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
});
