import React from 'react';

import { observer } from 'mobx-react';

import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import RunModeIcon from 'components/icons/RunModeIcon';
import { PipelineTimeline } from 'models/Timeline';
import { UseStyles } from 'styles/utilityTypes';
import { ProcessType, RunModeToLabel } from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';

const useStyles = makeStyles((theme) => ({
  root: {},
  modeButtonGroup: {
    backgroundColor: '#272727',
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
  popover: {},
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

export interface ProcessStateButtonProps extends UseStyles<typeof useStyles> {
  mode: PipelineTimeline['mode'];
  pipelineId: PipelineIdentifier;
  processType: ProcessType;
  disabled?: boolean;
  show?: boolean;

  className?: string;
}

const ProcessStateButton = observer((props: ProcessStateButtonProps): React.ReactElement | null => {
  const { className, mode, pipelineId, disabled, show = true } = props;
  const classes = useStyles(props);
  const { processingService } = useServices();

  const tooltipText = mode !== null ? RunModeToLabel[mode] : null;

  const handleClick = () => {
    processingService.openPipelineStatesModal(pipelineId);
  };

  return (
    <>
      {show && (
        <div className={clsx(classes.root, className)}>
          <Tooltip title={tooltipText} placement="top">
            <IconButton cy-test={'process-state-button'} size="small" onClick={handleClick}>
              <RunModeIcon disabled={disabled} runMode={mode} />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
});

export default ProcessStateButton;
