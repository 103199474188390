import React, { CSSProperties } from 'react';

import { observer } from 'mobx-react';

import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithTheme as WithThemeProps } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import { ProcessStatus } from 'utils/constants';

export enum StatusLabel {
  SUCCESS = 'Success',
  WARNING = 'Warnings',
  ERROR = 'Error',
  NOINPUT = 'No input data found',
  RUNNING = 'Running',
  REPROCESSED = 'Reprocessed',
  DID_NOT_RUN = 'Process did not run or has not completed',
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export interface ProcessStatusLabelProps extends WithServicesProps, WithThemeProps {
  type: ProcessStatus;
  classes: {
    root: string;
  };
}

class ProcessStatusLabel extends React.Component<ProcessStatusLabelProps> {
  render(): React.ReactNode {
    const { classes, theme, type } = this.props;

    let label: string;
    let checkbox_color: string;
    let checkboxBorder: CSSProperties = {
      borderStyle: '',
      borderRadius: 0,
      borderColor: '#fff',
    };
    switch (type) {
      case ProcessStatus.SUCCESS:
        label = StatusLabel.SUCCESS;
        checkbox_color = theme.palette.success.main;
        break;

      case ProcessStatus.WARNING:
        label = StatusLabel.WARNING;
        checkbox_color = theme.palette.warning.main;
        break;

      case ProcessStatus.ERROR:
        label = StatusLabel.ERROR;
        checkbox_color = theme.palette.error.main;
        break;

      case ProcessStatus.NOINPUT:
        label = StatusLabel.NOINPUT;
        checkbox_color = theme.palette.noinput.main;
        break;

      case ProcessStatus.REPROCESSED:
        label = StatusLabel.REPROCESSED;
        checkbox_color = theme.palette.transparent.main;
        checkboxBorder.borderStyle = 'dashed';
        checkboxBorder.borderWidth = 'thin';
        checkboxBorder.borderRadius = 4;
        checkboxBorder.padding = 4;
        break;

      case ProcessStatus.DID_NOT_RUN:
        label = StatusLabel.DID_NOT_RUN;
        checkbox_color = '#1E1E1E';
        break;

      default:
        label = 'N/A';
        checkbox_color = '#ffffff';
        break;
    }

    return (
      <Stack className={classes.root} direction="row" spacing={1} alignItems="center">
        <SquareRoundedIcon
          style={{
            fontSize: 32,
            color: checkbox_color,
            borderColor: checkboxBorder.borderColor,
            borderStyle: checkboxBorder.borderStyle,
            borderRadius: checkboxBorder.borderRadius,
            borderWidth: checkboxBorder.borderWidth,
          }}
        />
        <Typography color={theme.palette.text.primary}>{label}</Typography>
      </Stack>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withServices(observer(ProcessStatusLabel)));
