import React from 'react';

import { observer } from 'mobx-react';

import AttributionIcon from '@mui/icons-material/Attribution';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { purple } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { PipelineTimeline } from 'models/Timeline';
import { UseStyles } from 'styles/utilityTypes';
import { PipelineErrorLevel } from 'utils/constants';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface PipelineErrorIconProps extends UseStyles<typeof useStyles> {
  error_level: PipelineTimeline['error_level'];
  className?: string;
}

const PipelineErrorIcon = observer((props: PipelineErrorIconProps): React.ReactElement | null => {
  const { className, error_level } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const iconStyle: React.CSSProperties = {
    textAlign: 'center',
  };

  let icon = <WarningRoundedIcon />;

  switch (error_level) {
    case PipelineErrorLevel.ERROR:
      iconStyle.color = theme.palette.error.main;
      break;
    case PipelineErrorLevel.WARNING:
      iconStyle.color = theme.palette.warning.main;
      break;
    case PipelineErrorLevel.NOINPUT:
      iconStyle.color = theme.palette.noinput.main;
      break;
    case PipelineErrorLevel.ASSIGNED:
      iconStyle.color = theme.palette.success.main;
      icon = <AttributionIcon />;
      break;
    case PipelineErrorLevel.INFO:
      iconStyle.color = theme.palette.pipelineInfo.main;
      break;

    default:
      iconStyle.color = purple[200];
      break;
  }

  return (
    <div className={clsx(classes.root, className)} style={iconStyle}>
      {icon}
    </div>
  );
});

export default PipelineErrorIcon;
