import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { IconButton, Slider, Stack, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { MAX_ZOOM, MIN_ZOOM } from 'utils/constants';

enum SliderLabel {
  AUTO = 'auto',
  ON = 'on',
  OFF = 'off',
}

const useStyles = makeStyles(() => ({
  root: {},
  zoomButton: {
    borderRadius: 3,
    margin: 5,
  },
}));

export interface ZoomControlsProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const ZoomControls = observer((props: ZoomControlsProps): React.ReactElement | null => {
  const { className } = props;
  const { actionBarService, processingService } = useServices();

  const [zoomSliderLabelDisplay, setZoomSliderLabelDisplay] = useState(SliderLabel.AUTO);

  const classes = useStyles(props);

  const { zoomFactor: zoomFactorStore, sliderZoomFactor } = actionBarService;
  const { totalDays } = processingService.timelineData;

  const zoomFactor = sliderZoomFactor;
  const at_max_zoom = zoomFactor >= MAX_ZOOM;
  const at_min_zoom = zoomFactor <= MIN_ZOOM;
  const sliderLabelType = actionBarService.zoomMode ? SliderLabel.ON : zoomSliderLabelDisplay;

  /** Update core zoom factor in Mobx store if not equal */
  useEffect(() => {
    if (sliderZoomFactor !== zoomFactorStore) actionBarService.adjustZoom(sliderZoomFactor);
  }, [actionBarService, sliderZoomFactor, zoomFactorStore]);

  return (
    <div className={clsx(classes.root, className)}>
      <Stack direction="row" alignItems="center">
        <IconButton
          size="large"
          disabled={at_min_zoom}
          className={classes.zoomButton}
          onClick={actionBarService.zoomOut}
          onMouseEnter={() => setZoomSliderLabelDisplay(SliderLabel.ON)}
          onMouseLeave={() => setZoomSliderLabelDisplay(SliderLabel.AUTO)}
        >
          <RemoveIcon />
        </IconButton>
        <Slider
          onChange={(_, value) => {
            if (typeof value === 'number') actionBarService.adjustSliderZoom(value);
          }}
          valueLabelDisplay={sliderLabelType}
          valueLabelFormat={(x) => {
            return `${Math.trunc(zoomFactor * 100)}%`;
          }}
          size="small"
          value={zoomFactor}
          step={0.1}
          min={MIN_ZOOM}
          max={MAX_ZOOM}
        />
        <IconButton
          size="large"
          disabled={at_max_zoom}
          className={classes.zoomButton}
          onClick={actionBarService.zoomIn}
          onMouseEnter={() => setZoomSliderLabelDisplay(SliderLabel.ON)}
          onMouseLeave={() => setZoomSliderLabelDisplay(SliderLabel.AUTO)}
        >
          <AddIcon />
        </IconButton>
        <Tooltip title="Auto-Zoom to Fit" placement="top">
          {/* <IconButton size="large" onClick={() => actionBarService.autoAdjustZoom(totalDays)}> */}
          <IconButton
            size="large"
            // onClick={() => setLocalZoomLevel(actionBarService.autoAdjustZoom(totalDays) ?? localZoomLevel)}
            onClick={() => actionBarService.autoAdjustZoom(totalDays)}
          >
            <ZoomOutMapIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </div>
  );
});

export default ZoomControls;
