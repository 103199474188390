import * as yup from 'yup';

import { RunProcessFormFields } from 'models/RunProcessForm';

import { AutocompleteProcessingIdLabel } from '../fields/AutocompleteProcessingId';

const cliRegex = /^[a-zA-Z0-9-_ ]+$/;

const formIds: Record<keyof RunProcessFormFields, keyof RunProcessFormFields> = {
  processName: 'processName',
  processType: 'processType',
  locationName: 'locationName',
  processingId: 'processingId',
  confDir: 'confDir',
  startDate: 'startDate',
  endDate: 'endDate',
  isReprocessing: 'isReprocessing',
  commandLineArgs: 'commandLineArgs',
};

const validationSchema = yup.object({
  processName: yup.string().nullable().required('PCM Process Name is a required field'),
  locationName: yup.string().nullable().required('Location is a required field'),
  processingId: yup
    .string()
    .nullable()
    .when(formIds.isReprocessing, {
      is: true,
      then: (schema) => schema.required(`${AutocompleteProcessingIdLabel.ReprocessingEWO} is a required field`),
      otherwise: (schema) => schema,
    }),
  confDir: yup.string().nullable().required('A conf directory type must be chosen'),
  startDate: yup
    .date()
    .nullable() // Must be nullable to not flag as error in form
    .when(formIds.isReprocessing, {
      is: true,
      then: (schema) => schema.required('Start date required when Reprocessing is selected'),
      otherwise: (schema) => schema,
    }),
  endDate: yup
    .date()
    .nullable() // Must be nullable to not flag as error in form
    .when(formIds.isReprocessing, {
      is: true,
      then: (schema) => schema.required('End date required when Reprocessing is selected'),
      otherwise: (schema) => schema,
    }),
  isReprocessing: yup.boolean().nullable(),
  commandLineArgs: yup
    .string()
    .nullable()
    .matches(cliRegex, 'Only alphanumeric characters, dashes, and underscores are accepted'),
});

export const RunProcessFormTokens = {
  formIds,
  validationSchema,
};
