import React from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithTheme as WithThemeProps } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { AxiosResponse } from 'axios';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import { API } from 'utils/constants';
import { ConfirmationFormItemList } from 'utils/types';

import { ArmflowConfirmDialog } from '../common/ArmflowConfirmDialog';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    runningText: {
      textAlign: 'center',
      padding: `${theme.spacing(2)} 0px`,
    },
  });

export interface ReleaseDataFormConfirmProps extends WithServicesProps, WithThemeProps, ArmflowConfirmDialog {
  classes: {
    root: string;
    runningText: string;
  };
}

interface ReleaseDataFormConfirmState {
  filesToRelease: string[] | null;
  numFiles: number;
}

class ReleaseDataFormConfirm extends React.Component<ReleaseDataFormConfirmProps, ReleaseDataFormConfirmState> {
  constructor(props: Readonly<ReleaseDataFormConfirmProps>) {
    super(props);
    this.state = {
      filesToRelease: null,
      numFiles: 0,
    };
  }
  componentDidMount(): void {
    this.fetchFilesToRelease();
  }

  fetchFilesToRelease = () => {
    const { services } = this.props;
    const { adiApiService, processingService } = services;
    const releaseDataDialogForm = processingService.releaseDataDialogForm;
    const { processName, locationName, processingId } = releaseDataDialogForm;

    const url = `${API.FILES_TO_RELEASE}?process_name=${processName}&processing_id=${processingId}&location=${locationName}`;

    adiApiService.axios
      .get(url)
      .then((response: AxiosResponse) => {
        const data: any = response.data;
        let files = data.files_to_release;
        if (files && files.length > 0) {
          this.setState({ filesToRelease: files, numFiles: files.length });
        } else {
          this.setState({ filesToRelease: ['No files available to release'], numFiles: 0 });
        }
      })
      .catch((error) => {
        adiApiService.handleError(error, {
          id: 'error-loading-files-to-release',
          message: 'Error retrieving list of files to release',
        });
        this.setState({ filesToRelease: ['Failed to fetch files to release'], numFiles: 0 });
      });
  };

  render(): React.ReactNode {
    const { classes, services, theme } = this.props;
    const { open, onCancel, onClose, onSubmit } = this.props;
    const { filesToRelease, numFiles } = this.state;
    const { actionBarService, processingService } = services;
    const { disableConfirmButton } = actionBarService;
    const { releaseDataDialogForm } = processingService;
    const { processName, locationName, comment, processingId } = releaseDataDialogForm;
    const formItems: ConfirmationFormItemList = [
      { name: 'PCM Process Name', value: processName },
      { name: 'Location', value: locationName },
      { name: 'Processing ID', value: processingId },
      { name: 'Comment', value: comment },
    ];

    const disabled: boolean = disableConfirmButton || numFiles > 0 ? false : true;

    let filesSection = null;
    if (filesToRelease) {
      filesSection = filesToRelease.map((file, index) => {
        let fileName = file.replace(/^.*[\\/]/, '');
        return (
          <li key={index}>
            <Typography>{fileName}</Typography>
          </li>
        );
      });
    } else {
      filesSection = <CircularProgress size={theme.typography.fontSize} />;
    }

    return (
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason === 'escapeKeyDown') {
              actionBarService.setDisableConfirmButton(false);
              !!onClose && onClose(event, reason);
            }
          }}
        >
          <DialogTitle>Confirm Release Data</DialogTitle>

          <DialogContent dividers>
            <Grid container width={400} justifyContent={'space-between'} rowGap={'1rem'}>
              {formItems.map((entry) => (
                <Grid container xs={12} justifyContent={'inherit'} key={entry.name}>
                  <Typography>{entry.name}:</Typography>
                  <Typography>{entry.value}</Typography>
                </Grid>
              ))}
            </Grid>

            <Stack style={{ marginTop: '1rem' }} spacing={1}>
              <Typography>Files to release:</Typography>
              <Stack spacing={1}>
                <ul>{filesSection}</ul>
              </Stack>
            </Stack>
          </DialogContent>

          {/* Action buttons */}
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            {/* TODO: call service to POST to backend API */}
            <Button onClick={onSubmit} color="primary" variant="outlined" disabled={disabled}>
              Release Data
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withServices(observer(ReleaseDataFormConfirm)));
