/** @jsxImportSource @emotion/react */
import { ComponentPropsWithoutRef } from 'react';

import { observer } from 'mobx-react';

import { css, MenuItem, Select, SelectChangeEvent, Stack, styled, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { AnchorPoints, AppState, SORT_LABEL_WIDTH, SortOrder } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  count: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
}));

export interface PipelineCountAndSortButtonProps extends ComponentPropsWithoutRef<'div'> {}

export const PipelineCountAndSortButton = observer(({ className, ...props }: PipelineCountAndSortButtonProps) => {
  const classes = useStyles(props);
  const { processingService } = useServices();
  const { pipelineCount } = processingService;

  const handleSortOrderChange = (event: SelectChangeEvent<SortOrder>) => {
    const sortValue = event.target.value as SortOrder;

    // Don't sort value hasn't changed or is not within SortOrder enum (indicates programmer error)
    if (Object.values(SortOrder).includes(sortValue) && processingService.sortBy !== sortValue) {
      processingService.updateStatus(AppState.LOADING, 'Sorting pipelines');
      processingService.sleep(250).then(() => {
        processingService.setSortOrder(sortValue);
        processingService.updateStatus(AppState.IDLE, null);
      });
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...props}>
      <Stack css={sortAreaStyle} direction={'row'} gap={'.25rem'} alignItems={'center'}>
        <>
          Showing
          <CountText>{pipelineCount.current}</CountText>
          of
          <CountText>{pipelineCount.total}</CountText>
          pipelines, sorted by
        </>

        <Select
          variant="standard"
          value={processingService.sortBy}
          css={sortMenuStyle}
          onChange={handleSortOrderChange}
          MenuProps={{ MenuListProps: { dense: true }, ...AnchorPoints.BottomLeft }}
        >
          <MenuItem value={SortOrder.LOCATION}>Location</MenuItem>
          <MenuItem value={SortOrder.NAME}>PCM Process Name</MenuItem>
        </Select>
      </Stack>
    </div>
  );
});

const sortMenuStyle = css`
  max-height: 36px;
  border-radius: 10px;
`;

const sortAreaStyle = css`
  width: ${SORT_LABEL_WIDTH}px;
`;

const CountText = styled('span')(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightSemiBold};
  `
);
