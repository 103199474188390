import { css, styled, TableCell, tableCellClasses } from '@mui/material';
import { grey } from '@mui/material/colors';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: css`
    padding: 0.5rem 1rem;
  `,

  [`&.${tableCellClasses.body}`]: css`
    background-color: ${grey[900]};
  `,
}));
