import { ReactNode } from 'react';

import { action, makeObservable, observable } from 'mobx';

import autoBind from 'auto-bind';

import { AppState, TableType } from 'utils/constants';

export type ModalInfo = {
  id: string;
  /** @todo Consider renaming to `modalType`, accepting types `AppState` and `ModalType` (create this enum) */
  state: AppState;
  title: string | null;
  subtitle?: string;

  message?: string;

  errorTableType?: TableType;
  customModalData?: Record<string, unknown>;

  /** WIP - starting to refactor modal system to support pushing through components */
  customComponent?: {
    component: ReactNode; // TODO: may need to change type to React.Component rather than ReactNode if issues with onClose arise
    onClose?: () => void;
  };
};

export default class ModalsService {
  @observable modalStack: ModalInfo[] = [];

  constructor() {
    makeObservable(this);

    // Automatically bind `this` for all class methods
    autoBind(this);
  }

  get topModal(): ModalInfo | null {
    const last_index = this.modalStack.length - 1;
    return this.modalStack[last_index] ?? null;
  }

  @action
  pushModal(payload: ModalInfo) {
    const { id } = payload;
    if (!this.modalStack.some((modal_info) => modal_info.id === id)) {
      this.modalStack.push({ ...payload });
    }
  }

  @action
  popModal(modalId?: string) {
    // take 'id' as parameter, then iterate from end-of-array until 'id' is found
    // return this.modalStack.pop()

    let latest_modal: ModalInfo | null = null;
    for (let i = this.modalStack.length - 1; i >= 0; i--) {
      // Start from the end of array
      if (this.modalStack[i].id === modalId) {
        latest_modal = this.modalStack.splice(i, 1)[0];
      }
    }

    return latest_modal;
  }

  @action
  updateModalById(modalId: string, payload: Omit<ModalInfo, 'id'>, merge = false) {
    const modalIndex = this.modalStack.findIndex((value) => value.id === modalId);

    // const currentInfo = this.modalStack[currentInfoIndex]
    const currentInfo = this.modalStack.at(modalIndex);
    if (!currentInfo) {
      console.warn(`modalId '${modalId}' not found in modalStack`);
      return;
    }

    if (merge) {
      const updatedModalInfo: ModalInfo = { ...currentInfo, ...payload };
      this.modalStack[modalIndex] = updatedModalInfo;
    } else {
      this.modalStack[modalIndex] = { id: modalId, ...payload };
    }
  }
}
