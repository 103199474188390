import React from 'react';

import { observer } from 'mobx-react';

import { styled, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { DATA_ZOOM_THRESHOLD, DATE_VISIBILITY_ZOOM_THRESHOLD } from 'utils/constants';
import { getDateFromOffset } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  dayStyle: {
    position: 'absolute',
    bottom: 5,

    left: '50%',
    transform: 'translate(-50%, -0%)',
    borderLeft: 'none',
  },
  monthStyle: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
    fontFamily: 'Verdana',
    fontWeight: 'light',
  },

  junk: {
    fontFamily: 'Verdana',
    fontWeight: 'light',
  },

  monthBorder: {
    borderLeft: 'solid 1px',
    paddingLeft: theme.spacing(0.5),
  },

  rotate: {
    fontSize: 10,
    transform: 'rotate(-60deg)',
    fontFamily: 'Verdana',
  },

  startBorder: {
    borderLeft: 'solid 2px',
    paddingLeft: theme.spacing(0.5),
  },
  endBorder: {
    borderRight: 'solid 2px',
  },
  allowRotate: {
    justifyContent: 'end',
    // paddingLeft: '.1rem',
  },
}));

const DateGrid = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const YearRow = styled('div')`
  white-space: nowrap;
`;

const DayRow = styled('div')`
  /* justify-self: center; */
  margin-top: auto;
`;

export interface TimelinesDateProps extends UseStyles<typeof useStyles> {
  gridCellKey?: string;
  columnIndex: number;
  hoveredColumnIndex?: number;
  // rowIndex: number;
  // hoveredRowIndex: number;
  // selectedRowIndex: number;
  // selectedColumnIndex: number;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;

  style?: React.CSSProperties;
  className?: string;
}

const TimelinesDate = observer((props: TimelinesDateProps): React.ReactElement | null => {
  const { className } = props;
  const { columnIndex, hoveredColumnIndex, gridCellKey, style, onMouseEnter } = props;

  const classes = useStyles(props);
  const { actionBarService, processingService } = useServices();
  const { zoomFactor } = actionBarService;

  const { start_date: startDate } = processingService.timelineData;

  const startDateOffset = columnIndex - 1;
  const headerDate = getDateFromOffset(startDate, startDateOffset);
  const dateISO: string = headerDate.toISODate();
  const dayOfMonth: number = headerDate.day;
  const tooltipOpen = hoveredColumnIndex === columnIndex;

  const isFirstColumn = columnIndex === 1;
  // const isLastColumn = columnIndex === totalDays;
  const isFirstMonthDay = dayOfMonth === 1;

  const showMonth = isFirstMonthDay || isFirstColumn;
  const rotateDate = zoomFactor < DATE_VISIBILITY_ZOOM_THRESHOLD;
  const showDay = zoomFactor > DATA_ZOOM_THRESHOLD;
  // const showStartEnd = zoomFactor < DATA_ZOOM_THRESHOLD && (columnIndex === 1 || columnIndex === totalDays);

  // const yearMonth = isFirstMonthDay ? dateISO.slice(0, 7).replace('-', ' ') : null;
  const yearMonth = isFirstMonthDay ? dateISO.slice(0, 7) : null; // TODO: Should we be showing month for only the first day-of-month, or for the first column too?
  const dateContent = dayOfMonth;

  return (
    <Tooltip title={dateISO} placement="top" arrow open={tooltipOpen}>
      <div
        key={gridCellKey}
        style={style}
        className={clsx(classes.root, className, showMonth && !showDay && classes.monthBorder)}
        onMouseEnter={onMouseEnter}
      >
        <DateGrid className={clsx(rotateDate && classes.allowRotate)}>
          {
            <YearRow className={clsx(rotateDate && classes.rotate)}>
              {yearMonth}
              {/* <span>{yearMonth}</span> */}
            </YearRow>
          }
          {showDay && <DayRow>{dateContent}</DayRow>}
        </DateGrid>
      </div>
    </Tooltip>
  );
});

export default TimelinesDate;
