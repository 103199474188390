import * as yup from 'yup';

import { PruneDataFormFields } from 'models/PruneDataForm';

const formIds: Record<keyof PruneDataFormFields, keyof PruneDataFormFields> = {
  processName: 'processName',
  processType: 'processType',
  locationName: 'locationName',
  processingId: 'processingId',
  pruneAll: 'pruneAll',
  startDate: 'startDate',
  endDate: 'endDate',
};

const validationSchema = yup.object({
  processName: yup.string().nullable().required('PCM Process Name is a required field'),
  locationName: yup.string().nullable().required('Location is a required field'),
  processingId: yup.string().nullable().required('Processing Id is a required field'),
  // Dates required when "Prune All" is unchecked
  startDate: yup
    .date()
    .nullable()
    .when(formIds.pruneAll, {
      is: false,
      then: (schema) => schema.required('Start/End dates are required'),
      otherwise: (schema) => schema,
    }),
  endDate: yup
    .date()
    .nullable()
    .when(formIds.pruneAll, {
      is: false,
      then: (schema) => schema.required('Start/End dates are required'),
      otherwise: (schema) => schema,
    }),
});

export const PruneDataFormTokens = { formIds, validationSchema };
