import React, { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';

import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { useContextActionMenu } from 'hooks/useContextActionMenu';
import Timeline from 'models/Timeline';
import { UseStyles } from 'styles/utilityTypes';
import {
  ActionMenuItems,
  ArchivedState,
  DATA_ZOOM_THRESHOLD,
  ErrorHistoryType,
  FileState,
  ProcessStatus,
  ReleaseState,
  SettingsVisualToggleKey,
} from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';
import { checkStatusMask, createLocationString, getDateFromOffset } from 'utils/utils';

import DataStatusTimelineIcon from './icons/DataStatusTimelineIcon';
import ActionMenu from './menus/ActionMenu';

const useStyles = makeStyles((theme) => ({
  root: {},
  hoveredCellStyle: {
    filter: 'brightness(0.8)',
  },
  hoveredRowColumnStyle: {
    filter: 'brightness(0.9)',
  },
  selectedStyle: {
    border: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.runBorder.main,
  },
  reprocessedStyle: {
    borderStyle: 'dashed',
    borderWidth: 'thin',
  },
}));

export interface ProcessRunProps extends UseStyles<typeof useStyles> {
  className?: string;

  key: string;
  columnIndex: number;
  rowIndex: number;
  hoveredColumnIndex: number;
  hoveredRowIndex: number;
  selectedRowIndex: number;
  selectedColumnIndex: number;
  style: React.CSSProperties;
  onContextClose: () => void;

  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const ProcessRun = observer((props: ProcessRunProps): React.ReactElement | null => {
  const {
    className,
    columnIndex,
    hoveredColumnIndex,
    hoveredRowIndex,
    rowIndex,
    selectedColumnIndex,
    selectedRowIndex,
    style,
    onContextClose,
  } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const { actionBarService, processingService } = useServices();
  const { anchorEl, handleContextMenu, closeContextMenu } = useContextActionMenu({ onContextClose });

  const pipelineIndex = rowIndex - 1;
  const runDataIndex = columnIndex - 1;

  const timelineData: Timeline = processingService.timelineData;
  const pipelineData = timelineData.timelines[pipelineIndex];
  const runData = pipelineData && pipelineData.days[runDataIndex];
  const isReprocessed =
    runData.process_status !== undefined && Boolean(runData.process_status & ProcessStatus.REPROCESSED);

  const autofillPruneDataDate =
    runData.release_state === ReleaseState.NOT_RELEASING && runData.archive_state === ArchivedState.NOT_ARCHIVED;

  const pipelineId: PipelineIdentifier = {
    process_name: pipelineData.process,
    site: pipelineData.site,
    facility: pipelineData.facility,
    processType: pipelineData.type,
  };

  const checkMask = useCallback(
    (status: number, mask: ProcessStatus) => {
      const filters = processingService.currentModeFilters;
      return checkStatusMask<ProcessStatus>(status, mask, filters.processStatus);
    },
    [processingService.currentModeFilters]
  );

  const { visualToggles } = actionBarService;
  const blueToggle: boolean = visualToggles['blue-success'].active;
  const isRowHovered: boolean = rowIndex === hoveredRowIndex;
  const isColumnHovered: boolean = columnIndex === hoveredColumnIndex;
  const isCellHovered: boolean = rowIndex === hoveredRowIndex && columnIndex === hoveredColumnIndex;
  const isCellSelected: boolean = rowIndex === selectedRowIndex && columnIndex === selectedColumnIndex;
  const showDataStatusIcon: boolean =
    actionBarService.visualToggles[SettingsVisualToggleKey.ARCHIVE].active || isRowHovered;

  const getContextMenu = () => {
    // TODO: Context menu contents change if timeline is disabled
    const isPlaceholder = runData.process_status !== undefined && runData.process_status === ProcessStatus.DID_NOT_RUN;

    return (
      // runData.process_status !== undefined && ()
      <ActionMenu
        anchorEl={anchorEl}
        pipelineId={pipelineId}
        processType={pipelineData.type}
        errorHistoryType={ErrorHistoryType.Pipeline}
        logDate={getDateFromOffset(processingService.timelineData.start_date, runDataIndex)}
        onClose={closeContextMenu}
        disabledActions={{
          [ActionMenuItems.RUN_PROCESS]: false, // Always keep enabled; may be able to just delete this line
          [ActionMenuItems.PRUNE_DATA]: false,
          [ActionMenuItems.RELEASE_DATA]: false,
          [ActionMenuItems.RUN_BUNDLER]: false,
          [ActionMenuItems.ADD_COMMENT]: true, // isPlaceholder,
          [ActionMenuItems.VIEW_PROCESS_LOG]: isPlaceholder,
          [ActionMenuItems.VIEW_ARMFLOW_PROCESS_CONFIG]: isPlaceholder,
          [ActionMenuItems.VIEW_BUNDLE_LOG]: false,

          // work-in-progress; reenable as needed
          [ActionMenuItems.VIEW_SLURM_LOG]: true,
          [ActionMenuItems.VIEW_SLURM_BATCH_SCRIPT]: true,
        }}
        runProcessAutofillData={{
          processName: pipelineId.process_name,
          processType: pipelineData.type,
          locationName: createLocationString(pipelineId.site, pipelineId.facility).toUpperCase(),
          isReprocessing: isReprocessed,
        }}
        releaseDataAutofillData={{
          processName: pipelineId.process_name,
          processType: pipelineData.type,
          locationName: createLocationString(pipelineId.site, pipelineId.facility).toUpperCase(),
        }}
        pruneDataAutofillData={{
          processName: pipelineId.process_name,
          processType: pipelineData.type,
          locationName: createLocationString(pipelineId.site, pipelineId.facility).toUpperCase(),
          startDate: autofillPruneDataDate ? getDateFromOffset(timelineData.start_date, runDataIndex) : null,
          endDate: autofillPruneDataDate ? getDateFromOffset(timelineData.start_date, runDataIndex + 1) : null,
        }}
        runBundlerAutofillData={{
          processName: pipelineId.process_name,
          processType: pipelineData.type,
          locationName: createLocationString(pipelineId.site, pipelineId.facility).toUpperCase(),
        }}
      />
    );
  };

  const getBoxColor = useCallback(() => {
    const boxColorStyle: React.CSSProperties = {};
    if (runData.process_status !== undefined) {
      if (checkMask(runData.process_status, ProcessStatus.ERROR)) {
        boxColorStyle.backgroundColor = theme.palette.error.main;
      } else if (checkMask(runData.process_status, ProcessStatus.WARNING)) {
        boxColorStyle.backgroundColor = theme.palette.warning.main;
      } else if (checkMask(runData.process_status, ProcessStatus.NOINPUT)) {
        boxColorStyle.backgroundColor = theme.palette.noinput.main;
      } else if (checkMask(runData.process_status, ProcessStatus.SUCCESS)) {
        boxColorStyle.backgroundColor = blueToggle ? theme.palette.success.light : theme.palette.success.main;
      }
    }
    if (actionBarService.zoomFactor <= DATA_ZOOM_THRESHOLD) {
      // If we are zoomed out such that the data icons are not drawing, then we have to take the file status into
      // consideration.  If the file status is bad or partially bad, then we will draw a red box when zoomed way out
      if (runData.file_state === FileState.BAD || runData.file_state === FileState.SOME_BAD) {
        boxColorStyle.backgroundColor = theme.palette.error.main;
      } else if (runData.file_state === FileState.MISSING || runData.file_state === FileState.SOME_MISSING) {
        boxColorStyle.backgroundColor = theme.palette.filesMissing.main;
      }
    }

    return boxColorStyle;
  }, [runData.process_status, runData.file_state, actionBarService.zoomFactor, checkMask, theme, blueToggle]);

  const getBorderStyle = useCallback(() => {
    if (isCellSelected) {
      return classes.selectedStyle;
    } else if (isReprocessed) {
      return classes.reprocessedStyle;
    }
  }, [classes.reprocessedStyle, classes.selectedStyle, isCellSelected, isReprocessed]);

  /** Darken row/column if hovered, hovered cell is even darker */
  const getHoveredStyle = useCallback(() => {
    if ((isRowHovered || isColumnHovered) && !isCellHovered) {
      return classes.hoveredRowColumnStyle;
    } else if (isCellHovered) {
      return classes.hoveredCellStyle;
    }
    return null;
  }, [classes.hoveredCellStyle, classes.hoveredRowColumnStyle, isCellHovered, isColumnHovered, isRowHovered]);

  const hoveredStyleClassname = useMemo(() => getHoveredStyle(), [getHoveredStyle]);
  const borderStyleClassname = useMemo(() => getBorderStyle(), [getBorderStyle]);
  const boxColorStyle = useMemo(() => getBoxColor(), [getBoxColor]);

  // const boxColorStyle = useMemo(() => {
  //   return getBoxColor();
  // }, [getBoxColor]);

  return columnIndex > 0 ? (
    <>
      <div
        tabIndex={0}
        role="gridcell"
        // className={clsx(classes.root, className, getHoveredStyle(), getBorderStyle())}
        className={clsx(classes.root, className, hoveredStyleClassname, borderStyleClassname)}
        // id={`${status_name}_${rowIndex}-${columnIndex}`}
        id={`${rowIndex}-${columnIndex}`}
        onFocus={props.onFocus}
        onContextMenu={(e) => handleContextMenu(e, pipelineId)}
        onClick={props.onClick}
        onKeyUp={props.onKeyUp}
        onMouseOver={props.onMouseEnter}
        // onKeyDown={props.onKeyDown}
        // onMouseEnter={this.props.onMouseEnter}
        // onMouseLeave={this.props.onMouseLeave}
        // style={runData && { ...style, ...getBoxColor() }}
        style={runData && { ...style, ...boxColorStyle }}
      >
        {/* Icons */}
        {/*If Zoom level is < threshold, then don't show the data status icon at all, since we don't have room for it.*/}
        {actionBarService.zoomFactor > DATA_ZOOM_THRESHOLD && (
          <DataStatusTimelineIcon columnIndex={columnIndex} rowIndex={rowIndex} visible={showDataStatusIcon} />
        )}
      </div>
      {/* {isCellSelected && getContextMenu()} */}
      {isCellSelected && getContextMenu()}
    </>
  ) : null;
});

export default ProcessRun;
