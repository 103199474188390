import React from 'react';

import { observer } from 'mobx-react';

import DoneIcon from '@mui/icons-material/Done';
import PendingIcon from '@mui/icons-material/Pending';
import WarningIcon from '@mui/icons-material/Warning';
import { PaletteColor } from '@mui/material';
import { CSSProperties, makeStyles, useTheme } from '@mui/styles';

import { UseStyles } from 'styles/utilityTypes';
import { ProcessStatusLabel } from 'utils/constants';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface InfoStatusIconProps extends UseStyles<typeof useStyles> {
  status: ProcessStatusLabel;
  className?: string;
}

const InfoStatusIcon = observer((props: InfoStatusIconProps): React.ReactElement | null => {
  const { status } = props;

  // const classes = useStyles(props);
  const theme = useTheme();

  const getIconColor = (statusType: ProcessStatusLabel): PaletteColor['main'] | undefined => {
    switch (statusType) {
      case ProcessStatusLabel.ERROR:
        return theme.palette.error.main;
      case ProcessStatusLabel.SUCCESS:
        return theme.palette.success.main;
      case ProcessStatusLabel.WARNING:
        return theme.palette.warning.main;
      case ProcessStatusLabel.RUNNING:
        return theme.palette.running.main;
      case ProcessStatusLabel.NOINPUT:
        return theme.palette.noinput.main;
      default:
        break;
    }
  };

  const colorStyle: CSSProperties = { color: getIconColor(status) };

  switch (status) {
    case ProcessStatusLabel.ERROR:
      return <WarningIcon style={colorStyle} />;
    case ProcessStatusLabel.SUCCESS:
      return <DoneIcon style={colorStyle} />;
    case ProcessStatusLabel.WARNING:
      return <DoneIcon style={colorStyle} />;
    case ProcessStatusLabel.RUNNING:
      return <PendingIcon style={colorStyle} />;
    case ProcessStatusLabel.NOINPUT:
      return <DoneIcon style={colorStyle} />;
    default:
      return null;
  }
});

export default InfoStatusIcon;
