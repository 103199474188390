import { useMemo } from 'react';

import { useServices } from 'services';

import { ArmflowFormDialog } from 'components/dialogs/common/ArmflowFormDialog';
import { ARMFlowForms } from 'utils/constants';

type UseArmflowFormSubmitterProps = {
  formType: ARMFlowForms;
  validatorPath?: ArmflowFormDialog['validatorPath'];
};

export function useArmflowFormSubmitter({ formType, validatorPath }: UseArmflowFormSubmitterProps) {
  const { actionBarService, adiApiService, processingService } = useServices();

  const [formModel, submitFormFunc] = useMemo(() => {
    switch (formType) {
      case ARMFlowForms.RUN_PROCESS:
        return [processingService.runProcessDialogForm, processingService.submitRunProcess];

      case ARMFlowForms.RELEASE_DATA:
        return [processingService.releaseDataDialogForm, processingService.submitReleaseData];

      case ARMFlowForms.PRUNE_DATA:
        return [processingService.pruneDataDialogForm, processingService.submitPruneData];

      case ARMFlowForms.RUN_BUNDLER:
        return [processingService.runBundlerDialogForm, processingService.submitRunBundler];

      default:
        return [];
    }
  }, [
    formType,
    processingService.pruneDataDialogForm,
    processingService.releaseDataDialogForm,
    processingService.runBundlerDialogForm,
    processingService.runProcessDialogForm,
    processingService.submitPruneData,
    processingService.submitReleaseData,
    processingService.submitRunBundler,
    processingService.submitRunProcess,
  ]);

  const closeConfirmation = () => {
    actionBarService.setFormConfirmationOpen(formType, false);
  };

  const closeDialog = () => {
    actionBarService.setFormDialogOpen(formType, false); // close form dialog
  };

  const handleCloseForm = () => {
    closeDialog();
    formModel?.reset(); // Clean up form object on-close
    if (validatorPath) adiApiService.clearValidatorError(validatorPath);
  };

  const confirmSubmit = () => {
    submitFormFunc?.(() => {
      closeConfirmation();
      closeDialog();
    });
  };

  return { handleCloseForm, confirmSubmit, closeConfirmation };
}
