import { ReactNode } from 'react';

import { TableType } from 'utils/constants';

type ARMFlowTableCell = ReactNode;

const PipelineErrorHeaderNames = [
  'Process Type',
  'Processing Id',
  'Level',
  'Error Codes',
  'Message',
  'Latest Occurrence',
  'State',
  'Assignee',
  'Comments',
  'Actions',
];
export const TableHeader: Record<TableType, ARMFlowTableCell[]> = {
  [TableType.PipelineError]: PipelineErrorHeaderNames,
  [TableType.ErrorHistory]: PipelineErrorHeaderNames,
  [TableType.SystemErrors]: [
    'Process Type',
    'Level',
    'Error Codes',
    'Message',
    'Latest Occurrence',
    'State',
    'Actions',
  ],
};
