import { useMemo } from 'react';

import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material';
import { CSSProperties, useTheme } from '@mui/styles';

interface TextProps extends TypographyProps {
  // Style
  italic?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  smallCaps?: boolean;

  // Font Weights
  light?: boolean;
  regular?: boolean;
  medium?: boolean;
  bold?: boolean;
}

export const Text = ({
  italic = false,
  capitalize = false,
  uppercase = false,
  lowercase = false,
  smallCaps = false,

  light = false,
  regular = false,
  medium = false,
  bold = false,
  style,
  ...props
}: TextProps) => {
  const theme = useTheme();

  const cssProperties: CSSProperties = useMemo(
    () => ({
      fontStyle: italic ? 'italic' : undefined,
      fontVariant: smallCaps ? 'small-caps' : undefined,
      textTransform: capitalize ? 'capitalize' : uppercase ? 'uppercase' : lowercase ? 'lowercase' : undefined,
      fontWeight: bold
        ? theme.typography.fontWeightBold
        : medium
        ? theme.typography.fontWeightMedium
        : regular
        ? theme.typography.fontWeightRegular
        : light
        ? theme.typography.fontWeightLight
        : undefined,
    }),
    [
      bold,
      capitalize,
      italic,
      light,
      lowercase,
      medium,
      regular,
      smallCaps,
      theme.typography.fontWeightBold,
      theme.typography.fontWeightLight,
      theme.typography.fontWeightMedium,
      theme.typography.fontWeightRegular,
      uppercase,
    ]
  );

  return <Typography style={{ ...cssProperties, ...(style ?? {}) }} {...props} />;
};
