import { Stack, Toolbar, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

interface EnhancedTableToolbarProps {
  numSelected: number;
  tableLabel?: string;
}

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, tableLabel } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Stack flex={'1 1 auto'} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" id="tableTitle" component="div">
          {tableLabel}
        </Typography>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
      </Stack>
    </Toolbar>
  );
}
