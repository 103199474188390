import { observer } from 'mobx-react';

import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton, Stack, styled, TextField, Tooltip, useTheme } from '@mui/material';

import { useServices } from 'services';

import { Text } from 'components/styles';
import { DEFAULT_NUMBER_DAYS_TO_FILTER, PastDaysFieldMax, PastDaysFieldMin } from 'utils/constants';

interface PastDaysFieldProps {
  inputValue: string | null;
  disableNumberInput?: boolean;
  onInputChange?: (value: PastDaysFieldProps['inputValue']) => void;
  onNumDaysChange?: (numDays: number) => void;
}
export const PastDaysField = observer(
  ({ inputValue, onInputChange, disableNumberInput, onNumDaysChange }: PastDaysFieldProps) => {
    const { processingService } = useServices();
    const { currentModeFilters: filters } = processingService;

    const theme = useTheme();

    return (
      <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'} gap={theme.spacing(1)}>
        <Text variant="body2" style={{ whiteSpace: 'nowrap' }}>
          Show last
        </Text>
        <ShortTextField
          /**
           * Using these props instead of applying `type="number"` prop. This means we don't get the increment buttons for now.
           * See: https://mui.com/material-ui/react-text-field/#type-quot-number-quot
           */
          size="small"
          inputProps={{ min: PastDaysFieldMin, max: PastDaysFieldMax, inputMode: 'numeric', pattern: '[0-9]*' }}
          value={inputValue}
          disabled={disableNumberInput}
          onChange={(e) => onInputChange && onInputChange(e.currentTarget.value)}
          onFocus={() => filters.setDisableApplyButton(true)} // Lock "apply filter" button while user updates input
          onBlur={(e) => {
            const { value } = e.currentTarget;
            let numDays = parseInt(value);
            if (onNumDaysChange) onNumDaysChange(numDays);

            // Unlock "apply filter" button
            filters.setDisableApplyButton(false);
          }}
        />
        <Text variant="body2">days</Text>

        {/* Button to reset text field to default (solution for now since it's easier than via reset button) */}
        {!disableNumberInput && (
          <Tooltip title={`Revert to default (${DEFAULT_NUMBER_DAYS_TO_FILTER} days)`} placement="top">
            <IconButton
              disabled={disableNumberInput}
              size="small"
              onClick={() => onNumDaysChange && onNumDaysChange(DEFAULT_NUMBER_DAYS_TO_FILTER)}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  }
);
const ShortTextField = styled(TextField)`
  width: 75px;
`;
