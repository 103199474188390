import React, { useState } from 'react';

import { observer } from 'mobx-react';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import clsx from 'clsx';

import { Spacer } from 'components/styles';
import { UseStyles } from 'styles/utilityTypes';

import { FormDateRangePickerProps, FormEndDatePicker, FormStartDatePicker } from '../fields/FormDateRangePicker';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface ConfirmDateEditProps extends UseStyles<typeof useStyles> {
  // dateString: string;
  dateRange: FormDateRangePickerProps['value'];
  fieldName: Required<FormDateRangePickerProps>['hideField'];
  onChange: FormDateRangePickerProps['onChange'];
  tooltipText?: string;
  className?: string;
}

const ConfirmDateEdit = observer((props: ConfirmDateEditProps): React.ReactElement | null => {
  const { className, dateRange, tooltipText, fieldName, onChange } = props;
  const [editMode, setEditMode] = useState(false);

  const classes = useStyles(props);

  const dateToUse = fieldName === 'start' ? dateRange[0] : dateRange[1];
  const FieldComponent =
    fieldName === 'start' ? (
      <FormStartDatePicker
        startText="Start"
        value={dateRange}
        onChange={(value) => onChange(value)}
        additionalTextProps={{ size: 'small' }}
      />
    ) : (
      <FormEndDatePicker
        endText="End"
        value={dateRange}
        onChange={(value) => onChange(value)}
        additionalTextProps={{ size: 'small' }}
      />
    );

  return (
    <div className={clsx(classes.root, className)}>
      {editMode ? (
        <LocalizationProvider dateAdapter={AdapterLuxon}>{FieldComponent}</LocalizationProvider>
      ) : (
        dateToUse?.toISODate()
      )}

      <Spacer />

      {/* Edit Button */}
      <Tooltip title={!editMode ? tooltipText ?? 'Edit' : 'Close editor'} placement="right">
        <IconButton onClick={() => setEditMode(!editMode)}>{editMode ? <CloseIcon /> : <EditIcon />}</IconButton>
      </Tooltip>
    </div>
  );
});

export default ConfirmDateEdit;
