import { createTheme } from '@mui/material/styles';

export const ArmflowTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontWeightSemiBold: 600,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
      // contrastText: '#000000',
    },
    success: {
      main: '#7FB581',
      light: '#87CEFA',
    },
    warning: {
      main: '#E08231',
    },
    error: {
      main: '#BC2D2D',
    },
    noinput: {
      main: '#D2D2D2',
    },
    running: {
      main: '#6C8EAD',
    },

    // Palette colors for timeline view
    runBorder: {
      main: '#FFFFFF',
    },
    transparent: {
      main: '#00000000',
    },
    pipelineInfo: {
      main: '#5aa0f7',
    },

    // some Data Status-specific colors
    qualityControlPassed: {
      main: '#10650B',
      light: '#1E90FF',
    },

    qualityControlFailed: {
      main: '#6F2626',
    },

    quicklooksProduced: {
      main: '#12FF00',
    },

    quicklooksMissing: {
      main: '#FF0000',
    },

    filesMissing: {
      main: '#808080',
      light: '#642222',
    },
  },

  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: '#C7C7C7',
          },
        },
        focused: {},
      },
    },
  },
});

export default ArmflowTheme;
