import React, { CSSProperties, useCallback, useState } from 'react';

import { observer } from 'mobx-react';

import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled, Tooltip } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { Spacer } from 'components/styles';
import { UseStyles } from 'styles/utilityTypes';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface CopyTextProps extends UseStyles<typeof useStyles> {
  className?: string;
  text: string;
  tooltipText?: string;
  showTooltip?: boolean;
}

const CopySpan = styled('span')`
  display: flex;
  align-items: center;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
  :active {
    filter: brightness(80%);
    cursor: pointer;
  }
`;

const CopyText = observer((props: CopyTextProps): React.ReactElement | null => {
  const { className, text, showTooltip, tooltipText } = props;
  const [showCheckmark, setShowCheckmark] = useState(false);

  const classes = useStyles(props);
  const theme = useTheme();

  const handleCopy = useCallback(() => {
    if (!showCheckmark) {
      setTimeout(() => setShowCheckmark(false), 2000);
    }
    navigator.clipboard.writeText(text.trim());
    setShowCheckmark(true);
  }, [showCheckmark, text]);

  const iconStyle: CSSProperties = { fontSize: theme.typography.body2.fontSize };
  const icon = showCheckmark ? <CheckIcon style={iconStyle} /> : <ContentCopyIcon style={iconStyle} />;

  const title = showCheckmark ? 'Copied to clipboard!' : tooltipText ?? 'Click to Copy';
  return (
    <Tooltip title={title} open={showTooltip} arrow placement="top" onClick={handleCopy}>
      <CopySpan className={clsx(classes.root, className)}>
        {text} <Spacer /> {icon}
      </CopySpan>
    </Tooltip>
  );
});

export default CopyText;
