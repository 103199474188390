/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { css, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import ActionMenu from 'components/menus/ActionMenu';
import { transparentWhite } from 'components/styles';
import { useContextActionMenu } from 'hooks/useContextActionMenu';
import { ActionMenuItems, DISABLE_COLOR, ErrorHistoryType, RunMode } from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';
import { createLocationString } from 'utils/utils';

import { TimelineGridCellProps } from './gridCells/TimelineGridCell';
import ProcessStateButton from './processStateButton/ProcessStateButton';
import TimelineRunningButton from './stopProcess/TimelineRunningButton';
import TimelineErrorButton from './TimelineErrorButton';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  labelStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pipelineStateIcon: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  labelOne: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    color: DISABLE_COLOR,
  },
  labelTwo: {
    color: theme.palette.common.white,
  },
  readOnlyLabel: {
    color: DISABLE_COLOR,
  },
}));

export interface TimelineLabelFuncProps extends Pick<TimelineGridCellProps, 'hoveredRowIndex'> {
  rowIndex: number;
  gridCellKey?: string;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onContextClose?: () => void;

  style: React.CSSProperties;
  className?: string;
}

const TimelinesLabel = observer((props: TimelineLabelFuncProps): React.ReactElement | null => {
  const { className, gridCellKey, hoveredRowIndex, rowIndex, style, onMouseEnter, onContextClose } = props;
  // const [divTitle, setDivTitle] = useState<string | undefined>(undefined);
  const [labelPipelineId, setLabelPipelineId] = useState<PipelineIdentifier | null>(null);

  const { actionMenuOpen, anchorEl, handleContextMenu, closeContextMenu } = useContextActionMenu({ onContextClose });

  const classes = useStyles(props);
  const { processingService } = useServices();
  const { timelineData } = processingService;
  const pipelineIndex = rowIndex - 1;
  const pipelineData = timelineData.timelines[pipelineIndex];
  const endDate = timelineData.end_date.minus({ days: 1 }); // Last day of data represented

  useEffect(() => {
    if (timelineData.timelines.length) {
      const processData = timelineData.timelines[pipelineIndex];

      if (processData) {
        const { site, facility, process: process_name, type: processType } = processData;
        const pipelineId: PipelineIdentifier = { process_name, site, facility, processType };
        setLabelPipelineId(pipelineId);
      }
    }
  }, [pipelineIndex, timelineData.timelines]);

  let label_grid = null;
  if (timelineData.timelines.length) {
    // const processData = timelineData.timelines[pipelineIndex];

    if (pipelineData) {
      const {
        site,
        facility,
        type: pipelineProcessType,
        process: process_name,
        mode: runMode,
        has_conf,
        running,
      } = pipelineData;
      const location = `${site.toUpperCase()} ${facility}`;
      const isReadOnly = !has_conf;
      const runningProcessExists = !!running;
      const pipelineId: PipelineIdentifier = { process_name, site, facility, processType: pipelineProcessType };

      // // Set element's title attribute if not already set
      // const title = [location, pipelineProcessType, process_name].join(' - ');
      // if (title !== divTitle) {
      //   setDivTitle(title);
      // }

      // Set process status icon
      let pipelineStateIcon = null;
      if (runningProcessExists) {
        pipelineStateIcon = <TimelineRunningButton pipelineId={pipelineId} type={pipelineProcessType} />;
      } else if (pipelineData.error) {
        pipelineStateIcon = (
          <TimelineErrorButton
            disabled={runningProcessExists}
            pipelineId={pipelineId}
            type={pipelineProcessType}
            error_level={pipelineData.error_level}
          />
        );
      }

      // Grey out labels if pipeline's run mode is "Locked"
      const isLocked = pipelineData.mode === RunMode.LOCKED;
      const colorOverride = isLocked && { color: DISABLE_COLOR };

      label_grid = (
        <>
          {!isReadOnly ? (
            <ProcessStateButton
              pipelineId={pipelineId}
              processType={pipelineData.type}
              mode={runMode}
              disabled={isReadOnly}
            />
          ) : (
            <div />
          )}

          {/* Cell label */}
          {/* <ClickableLabel noWrap={true} disabled > */}
          <LocationLabel css={colorOverride}>{location}</LocationLabel>
          <ProcessTypeLabel css={colorOverride}>{pipelineProcessType}</ProcessTypeLabel>
          <ProcessNameLabel css={colorOverride}>{process_name}</ProcessNameLabel>
          <PipelineStateIconLabel>{pipelineStateIcon}</PipelineStateIconLabel>
          {/* </ClickableLabel> */}
        </>
      );
    }
  }

  return (
    <>
      <div
        // title={divTitle}
        className={clsx(classes.root, className)}
        css={[gridStyle, hoveredRowIndex === rowIndex && hoveredStyle, actionMenuOpen && contextMenuOpenStyle]}
        key={gridCellKey}
        style={style}
        onMouseEnter={onMouseEnter}
        onContextMenu={(e) => handleContextMenu(e, labelPipelineId)}
      >
        {label_grid}
      </div>

      {anchorEl && (
        <ActionMenu
          anchorEl={anchorEl}
          pipelineId={labelPipelineId ?? undefined}
          logDate={endDate}
          processType={pipelineData.type}
          errorHistoryType={ErrorHistoryType.Pipeline}
          onClose={closeContextMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          hideActions={{
            [ActionMenuItems.ADD_COMMENT]: true,
            [ActionMenuItems.INFO]: true,
            [ActionMenuItems.REFRESH]: true,
          }}
          runProcessAutofillData={{
            processName: labelPipelineId && labelPipelineId.process_name,
            processType: pipelineData.type,
            locationName:
              labelPipelineId && createLocationString(labelPipelineId.site, labelPipelineId.facility).toUpperCase(),
          }}
          releaseDataAutofillData={{
            processName: labelPipelineId && labelPipelineId.process_name,
            processType: pipelineData.type,
            locationName:
              labelPipelineId && createLocationString(labelPipelineId.site, labelPipelineId.facility).toUpperCase(),
          }}
          pruneDataAutofillData={{
            processName: labelPipelineId && labelPipelineId.process_name,
            processType: pipelineData.type,
            locationName:
              labelPipelineId && createLocationString(labelPipelineId.site, labelPipelineId.facility).toUpperCase(),
          }}
          runBundlerAutofillData={{
            processName: labelPipelineId && labelPipelineId.process_name,
            processType: pipelineData.type,
            locationName:
              labelPipelineId && createLocationString(labelPipelineId.site, labelPipelineId.facility).toUpperCase(),
          }}
        />
      )}
    </>
  );
});

export default TimelinesLabel;

// const ClickableLabel = styled(Typography)(
//   ({ disabled }: { disabled?: boolean }) => css`
//     // NOTE: effectively creating a button; could probably just use and style a button for this
//     padding: 0.3rem 0;

//     ${!disabled &&
//     css`
//       :hover {
//         cursor: pointer;
//         background-color: ${grey[800]};
//         border-radius: 4px;
//         user-select: none;
//       }
//       :active {
//         cursor: pointer;
//         filter: brightness(80%);
//       }
//     `}
//   `
// );

const gridStyle = css`
  display: grid;
  align-items: center;
  grid-template-columns: 40px auto auto 1fr auto;
  column-gap: 0.5rem;
  text-align: left;
`;

const hoveredStyle = css`
  background-color: ${transparentWhite(0.05)};
`;

const contextMenuOpenStyle = css`
  background-color: ${transparentWhite(0.1)};
`;

const LocationLabel = styled('span')(
  () => css`
    color: ${DISABLE_COLOR};
    min-width: 61px;
  `
);

const ProcessTypeLabel = styled('span')(
  () => css`
    text-align: center;
    min-width: 51px;
  `
);

const ProcessNameLabel = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    text-overflow: ellipsis;
    overflow: hidden;
  `
);

const PipelineStateIconLabel = styled('span')(
  () => css`
    justify-self: end;
    padding-right: 0.25rem;
  `
);
